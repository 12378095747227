import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';


// Sample blog data
const blogData = [
  {
    id: 1,
    title: "The Importance of Cybersecurity in Modern Software Development",
    excerpt: "In an era of increasing cyber threats, this blog explores the critical role of cybersecurity in software development...",
    image: "images/b1.jpg",
    link: "/The_Importance_Of_Cybersecurity_In_Modern_Software_Development"
  },
  {
    id: 2,
    title: "Embracing Agile: Accelerating Software Development and Delivery",
    excerpt: "Discover how adopting Agile methodologies can revolutionize your software development process...",
    image: "images/b1.webp",
    link: "/Embracing_Agile_Accelerating_Software_Development_And_Delivery"
  },
  {
    id: 3,
    title: "The Power of AI and Machine Learning in Software Applications",
    excerpt: "Artificial Intelligence (AI) and Machine Learning (ML) are reshaping the software landscape...",
    image: "images/b8.jpg",
    link: "/The_Power_Of_AI_And_Machine_Learning_in_Software_Applications"
  },
  {
    id: 4,
    title: "Essential Steps for a Successful Website Audit",
    excerpt: "Conducting a comprehensive website audit is crucial for ensuring your site’s performance, user experience, and search engine ranking...",
    image: "images/Website_Audit.webp",
    link: "/Essential_Steps_for_Successful_Website_Audit"
  },
  {
    id: 5,
    title: "Harnessing the Power of AI for Business Intelligence",
    excerpt: "Artificial Intelligence (AI) is revolutionizing the field of Business Intelligence (BI), providing unprecedented insights and efficiencies...",
    image: "images/Business-Intelligence.webp",
    link: "/Harnessing_the_Power_of_AI_for_Business_Intelligence"
  },
  {
    id: 6,
    title: "The Role of Artificial Intelligence in Healthcare",
    excerpt: "AI is reshaping healthcare by improving diagnostics, personalized treatment, and operational efficiencies for better patient outcomes.",
    image: "images/AI_in_helth.png",
    link: "/Role_of_AI_in_Healthcare"
  },
  {
    id: 7,
    title: "Leveraging Artificial Intelligence in IT Services",
    excerpt: "Harnessing Artificial Intelligence enhances IT services by automating tasks, improving cybersecurity, and optimizing operations for greater efficiency and innovation.",
    image: "images/IT-Services.webp",
    link: "/Leveraging_AI_in_IT_Services"
  },
  {
    id: 8,
    title: "Importance of Effective Website Development",
    excerpt: "Effective website development is crucial for establishing online presence, engaging users, and achieving business objectives efficiently.",
    image: "images/Effective-Website.webp",
    link: "/Importance_of_Effective_Website_Development"
  },
  {
    id: 9,
    title: "Understanding Website Development",
    excerpt: "Understanding Website Development: Essential insights into the process, technologies, and strategies driving modern web design and functionality.",
    image: "images/Understanding.webp",
    link: "/Understanding_Website_Development"
  },
  {
    id: 10,
    title: "The Evolution of Web Design Trends in 2024",
    excerpt: "The Evolution of Web Design Trends in 2024 showcases dynamic shifts towards minimalism, interactivity, inclusivity, and immersive user experiences.",
    image: "images/DesignTrends.webp",
    link: "/The_Evolution_of_Web_Design_Trends_in_2024"
  },
  {
    id: 11,
    title: "Leveraging Social Media Marketing for Business Growth",
    excerpt: "Optimizing social media for business growth: strategies to boost visibility and engagement effectively.",
    image: "images/marketing.webp",
    link: "/Social_Media_Marketing_for_Business_Growth"
  },
  {
    id: 12,
    title: "Understanding the Benefits of E-commerce for Businesses",
    excerpt: "Understanding the Benefits of E-commerce for Businesses: increased reach, convenience, cost-efficiency, and customer insights.",
    image: "images/E-commerce.webp",
    link: "/Benefits_of_Ecommerce_for_Businesses"
  },
  {
    id: 13,
    title: "The Benefits of Customized Software for Businesses",
    excerpt: "Customized software enhances business efficiency, scalability, and competitive advantage by addressing unique operational needs and challenges.",
    image: "images/Customized1.webp",
    link: "/The_Benefits_of_Customized_Software_for_Businesses"
  },
  {
    id: 14,
    title: "Quantum Computing Revolutionizing Technology in 2024",
    excerpt: "Quantum Computing: Revolutionizing Technology in 2024 unveils groundbreaking advancements reshaping computation, security, and scientific discovery.",
    image: "images/quantum-computing.webp",
    link: "/Quantum_Computing_Revolutionizing_Technology_in_2024"
  },
  {
    id: 15,
    title: "The Future of IT Jobs: Innovations, Challenges, and Career Opportunities Ahead",
    excerpt: "The Future of IT Jobs explores emerging roles, skills, and trends shaping the tech industry's workforce dynamics.",
    image: "images/IT-Jobs.webp",
    link: "/Future_of_IT_Jobs"
  },
  {
    id: 16,
    title: "The Impact of Blockchain Technology on Financial Services",
    excerpt: "The Impact of Blockchain Technology on Financial Services highlights its transformative potential in security, transparency, and operational efficiency.",
    image: "images/Blockchain-Technology.webp",
    link: "/The_Impact_of_Blockchain_Technology_on_Financial_Services"
  },
  {
    id: 17,
    title: "Emerging Geospatial Trends 2024: Shaping India's Digital Landscape",
    excerpt: "Emerging Geospatial Trends 2024: Shaping India's Digital Landscape explores innovations driving spatial data applications and infrastructure development.",
    image: "images/geospatial-trends-2024.webp",
    link: "/Emerging_Geospatial_Trends_2024"
  },
  {
    id: 18,
    title: " The Future of Mobile App Development: Predictions and Insights",
    excerpt: "The Future of Mobile App Development: Predictions and Insights anticipates trends shaping innovation, user experience, and industry evolution.",
    image: "images/future-mobile-apps.webp",
    link: "/Future_of_Mobile_App_Development"
  },
  {
    id: 19,
    title: " Top 10 Benefits of Hiring a Full Stack Developer",
    excerpt: "Top 10 Benefits of Hiring a Full Stack Developer: Expertise in both frontend and backend technologies, ensuring comprehensive and efficient project development.",
    image: "images/full-stack-developer.webp",
    link: "/Benefits_of_Hiring_a_Full_Stack_Developer"
  },
  {
    id: 20,
    title: "The Power of SEO: Driving Online Success",
    excerpt: "The Power of SEO: Driving Online Success through enhanced visibility, targeted traffic, and sustainable growth strategies.",
    image: "images/seoblog.webp",
    link: "/The_Power_of_SEO_Driving_Online_Success"
  }
];


const Blog = () => {
  const blogsPerPage = 9; 

  // State variables
  const [currentPage, setCurrentPage] = useState(1);

  // Calculate total pages based on blogsPerPage
  const totalPages = Math.ceil(blogData.length / blogsPerPage);

  // Function to handle pagination
  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // Calculate current blogs based on pagination
  const indexOfLastBlog = currentPage * blogsPerPage;
  const indexOfFirstBlog = indexOfLastBlog - blogsPerPage;
  const currentBlogs = blogData.slice(indexOfFirstBlog, indexOfLastBlog);

  return (
    <div>
 

 <Helmet>
  <title>Blog | Insights and Updates from Guru Software</title>
  <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
  <meta name="author" content="Guru Software" />
  <meta name="subject" content="IT Insights and Updates" />
  <meta name="owner" content="Guru Software" />
  <meta name="coverage" content="Global" />
  <meta name="Geography" content="Guru Software, Ahmedabad, Gujarat, India" />
  <meta name="Language" content="English" />
  <meta name="distribution" content="Global" />
  <meta name="audience" content="All" />
  <meta name="revisit-after" content="3 days" />
  <meta name="Robots" content="INDEX,FOLLOW" />
  <meta name="country" content="Global" />
  <meta name="description" content="Read Guru Software's blog for the latest insights, trends, and updates on IT services, software development, digital marketing, and more. Stay informed with our expert articles." />
  <meta property="og:title" content="Blog | Insights and Updates from Guru Software" />
  <meta property="og:description" content="Read Guru Software's blog for the latest insights, trends, and updates on IT services, software development, digital marketing, and more. Stay informed with our expert articles." />
  <meta property="og:type" content="website" />
  <meta property="og:url" content="https://www.gurusoftware.in/Blog" />
  <meta property="og:image" content="https://www.gurusoftware.in/images/og.png" />
  <meta name="twitter:card" content="summary_large_image" />
  <meta name="twitter:description" content="Read Guru Software's blog for the latest insights, trends, and updates on IT services, software development, digital marketing, and more. Stay informed with our expert articles." />
  <meta name="twitter:title" content="Blog | Insights and Updates from Guru Software" />
</Helmet>

      <div className="page-header" style={{ padding: '50px 0' }}>
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="page-header-box-my page-header-box">
                <h1 className="text-anime-style-3">Checkout Our Latest Blogs</h1>
                <nav className="wow fadeInUp" data-wow-delay="0.25s">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to="/">home</Link></li>
                    <li className="breadcrumb-item active" aria-current="page">Blogs</li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="latest-news our-blog">
        <div className="container">
          <div className="row section-row align-items-center">
            <div className="col-lg-12 col-md-12">
              <div className="section-title">
                <h3 className="wow fadeInUp" style={{ visibility: 'visible', animationName: 'fadeInUp' }}>Blogs</h3>
                <h2 className="text-anime-style-3" style={{ perspective: '400px' }}>
                  Read Our Blog To Get All Recent Tech News
                </h2>
              </div>
            </div>
          </div>
          <div className="row">
            {currentBlogs.map(blog => (
              <div key={blog.id} className="col-lg-4 col-md-6">
                <div className="blog-item wow fadeInUp" data-wow-delay="0.25s">
                  <Link to={blog.link}>
                    <div className="post-featured-image">
                      <figure className="image-anime">
                        <img src={blog.image} style={{ width: "383px", height: "254px" }} alt={blog.title} />
                      </figure>
                    </div>
                    <div className="post-item-body">
                      <h2>
                        <Link to={blog.link}>{blog.title}</Link>
                      </h2>
                      <br />
                      <p>{blog.excerpt}</p>
                    </div>
                  </Link>
                </div>
              </div>
            ))}
          </div>

          {/* <p>Current Page: {currentPage}</p> */}

          {/* Pagination */}
          <div className="row">
            <div className="col-md-12">
              <div className="post-pagination wow fadeInUp" data-wow-delay="1.50s">
                <ul className="pagination">
                  {/* Previous button */}
                  <li className={currentPage === 1 ? "disabled" : ""}>
                    <a
                      href="#"
                      onClick={(e) => {
                        e.preventDefault();
                        if (currentPage > 1) paginate(currentPage - 1);
                      }}
                    >
                      <i className="fa-solid fa-arrow-left-long" />
                    </a>
                  </li>
                  
                  {/* Page numbers */}
                  {[...Array(totalPages).keys()].map((number) => (
                    <li
                      key={number}
                      className={number + 1 === currentPage ? "active" : ""}
                    >
                      <a
                        href="#"
                        onClick={(e) => {
                          e.preventDefault();
                          paginate(number + 1);
                        }}
                      >
                        {number + 1}
                      </a>
                    </li>
                  ))}

                  {/* Next button */}
                  <li className={currentPage === totalPages ? "disabled" : ""}>
                    <a
                      href="#"
                      onClick={(e) => {
                        e.preventDefault();
                        if (currentPage < totalPages) paginate(currentPage + 1);
                      }}
                    >
                      <i className="fa-solid fa-arrow-right-long" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Blog;

