import React, { useEffect, useRef, useState } from 'react';
import './my.css';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';



const Home = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [isClient, setIsClient] = useState(false); 
  const [activeTab, setActiveTab] = useState('ui-tab-pane'); 

  useEffect(() => {
    setIsClient(true); 
  }, []);

  const totalImages = 3;

  const handleDotClick = (index) => {
    setActiveIndex(index);
  };

  const handlePrev = () => {
    const newIndex = (activeIndex - 1 + totalImages) % totalImages;
    setActiveIndex(newIndex);
  };

  const handleNext = () => {
    const newIndex = (activeIndex + 1) % totalImages;
    setActiveIndex(newIndex);
  };

  const countersRef = useRef([]);

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 0.5, 
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const target = +entry.target.dataset.target;
          const increment = target / 200; 
          let count = 0;

          const updateCounter = () => {
            count += increment;
            if (count < target) {
              entry.target.innerText = Math.ceil(count) + '+'; // Update displayed count with "+"
              requestAnimationFrame(updateCounter); // Use requestAnimationFrame for smoother animation
            } else {
              entry.target.innerText = `${target}+`;
            }
          };

          updateCounter();
          observer.unobserve(entry.target); // Stop observing once animation is triggered
        }
      });
    }, options);

    // Observe each counter element
    countersRef.current.forEach((counter) => {
      observer.observe(counter);
    });

    // Clean up observer on component unmount
    return () => observer.disconnect();
  }, []); // Dependencies array is empty to run once on component mount

  // Function to handle tab clicks
  const handleTabClick = (tabId) => {
    setActiveTab(tabId);
  };

  return (
    <div>

<Helmet>
  <title>Guru Software | Leading IT Solutions and Services Globally</title>
  <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
  <meta name="author" content="Guru Software" />
  <meta name="subject" content="IT Solutions" />
  <meta name="owner" content="Guru Software" />
  <meta name="coverage" content="Global" />
  <meta name="Geography" content="Guru Software, Ahmedabad, Gujarat, India" />
  <meta name="Language" content="English" />
  <meta name="distribution" content="Global" />
  <meta name="audience" content="All" />
  <meta name="revisit-after" content="3 days" />
  <meta name="Robots" content="INDEX,FOLLOW" />
  <meta name="country" content="Global" />
  <meta name="description" content="Guru Software offers top-tier IT solutions and services worldwide. From software development to digital marketing, explore our comprehensive range of offerings designed to elevate your business." />
  <meta property="og:title" content="Guru Software | Leading IT Solutions and Services Globally" />
  <meta property="og:description" content="Guru Software offers top-tier IT solutions and services worldwide. From software development to digital marketing, explore our comprehensive range of offerings designed to elevate your business." />
  <meta property="og:type" content="website" />
  <meta property="og:url" content="https://gurusoftware.in/" />
  <meta property="og:image" content="https://gurusoftware.in/images/og.png" />
  <meta name="twitter:card" content="summary_large_image" />
  <meta name="twitter:description" content="Guru Software offers top-tier IT solutions and services worldwide. From software development to digital marketing, explore our comprehensive range of offerings designed to elevate your business." />
  <meta name="twitter:title" content="Guru Software | Leading IT Solutions and Services Globally" />
</Helmet>



   <div className="hero" style={{ padding: "140px 0 !important" }}>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-8">
              <div className="hero-content">
                <div className="section-title">
                  <h5 className="wow fadeInUp">
                    Leading Global Technology Provider
                  </h5>
                  <h1 className="text-anime-style-3">
                    Empowering Businesses Through Innovative 
                    <span> IT Solutions.</span>
                  </h1>
                </div>

                <div className="hero-body">
                  <p className="wow fadeInUp" data-wow-delay="0.5s">
                    Global leader in transformative technology solutions, pioneering innovation and driving digital revolution worldwide
                  </p>
                </div>

                <div className="hero-footer">
                  <Link
                    to="/About_Us"
                    className="btn-default wow fadeInUp"
                    data-wow-delay="0.75s"
                  >
                    About Us
                  </Link>
                </div>
              </div>
            </div>

            <div className="col-lg-4">
              <div className="hero-video-image">
                <div className="hero-slider">
                  <div className="slider-image-container" style={{ transform: `translateX(-${activeIndex * (100 + 10)}%)` }}>
                    <img src="images/hero-img.webp" alt="Slide 1" className="slider-image" />
                    <img src="images/hero-img-2.webp" alt="Slide 2" className="slider-image" />
                    <img src="images/page-about-1.webp" alt="Slide 3" className="slider-image" />
                  </div>
                </div>
                <div className="slider-dots">
                  {[0, 1, 2].map((_, index) => (
                    <span
                      key={index}
                      className={`slider-dot ${activeIndex === index ? "active" : ""}`}
                      onClick={() => handleDotClick(index)}
                    />
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="about-us">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-6">
                <div className="page-about-image">
                <div className="about-img-1">
                <img src="images/myab-1.png" alt="about img" style={{ width: '507px', height: 'auto' }} />
                </div>

                  <div className="about-img-2">
                    {/* <figure className="image-anime reveal"> */}
                      <img src="images/myab-2.png" alt="" />
                    {/* </figure> */}
                  </div>
                  <div className="work-experience">
                    <div className="work-experience-icon">
                      <img src="images/icon-work-experience.webp" alt="" />
                    </div>
                    <div className="work-experience-content">
                      <h3><span className="counter">30</span>+</h3>
                      <p>Work Experience</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                {/* About Us Content Start */}
                <h2 className="text-anime-style-3">Transform your vision into reality with our expertise</h2>
                <br />
                <div className="about-content">
                  <p className="wow fadeInUp" data-wow-delay="0.25s">For over a decade, we have been delivering outstanding services and solutions, consistently achieving complete customer satisfaction. Our extensive clientele base speaks volumes about our commitment to excellence. Once you've experienced working with us, it becomes clear that finding such quality and professionalism elsewhere is nearly impossible.</p>
                  <p className="wow fadeInUp" data-wow-delay="0.5s">Our unwavering dedication and expertise have established us as a highly reputable entity in the web development sector. Our guiding principle is inclusive development paired with unwavering customer satisfaction. Our ultimate goal is to achieve all-encompassing growth, becoming a paramount service development entity capable of catering to every customer's diverse needs.</p>
                  <ul className="wow fadeInUp" data-wow-delay="1s">
                    <li>Website Development </li>
                    <li>Mobile App Development</li>
                    <li>Customized Software</li>
                    <li>Digital Marketing </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      <div className="why-choose-us">
  <div className="container">
    <div className="row section-row align-items-center">
      <div className="col-lg-8 col-md-12">
        {/* Section Title Start */}
        <div className="section-title">
        <h3 className="wow fadeInUp">Our Services
                </h3>
                <h2 className="text-anime-style-3">Transforming Ideas Into Reality
                </h2>
        </div>
        {/* Section Title End */}
      </div>
    </div>
    <div className="row">
      <div className="col-lg-4 col-md-6">
      <Link to="/Services">
        <div
          className="why-choose-us-item wow fadeInUp"
          data-wow-delay="0.25s"
          style={{
            visibility: "visible",
            animationDelay: "0.25s",
            animationName: "fadeInUp"
          }}
        >
          <div className="icon-box">
            <img src="images/laptop.webp"style={{width: 60, height: 60}}   alt="" />
          </div>
          <h3>
          Website Development</h3>
          <p>
          Empowering businesses with secure and scalable web solutions.

          </p>
        </div>
</Link>
      </div>
      <div className="col-lg-4 col-md-6">
      <Link to="/Services">
        <div
          className="why-choose-us-item wow fadeInUp"
          data-wow-delay="0.5s"
          style={{
            visibility: 'visible',
            animationDelay: '0.5s',
            animationName: 'fadeInUp'
          }}
        >
          <div className="icon-box">
            <img src="images/mobile.webp" style={{ width: 60, height: 60 }} alt="Mobile App Development" />
          </div>
          <h3>Mobile App Development</h3>
          <p>Creating intuitive and powerful mobile apps for your business.</p>
        </div>
      </Link>
    </div>
      <div className="col-lg-4">
      <Link to="/Services">
        <div
          className="why-choose-us-item wow fadeInUp"
          data-wow-delay="0.75s"
          style={{
            visibility: "visible",
            animationDelay: "0.75s",
            animationName: "fadeInUp"
          }}
        >
          <div className="icon-box">
            <img src="images/custom.webp"style={{width: 60, height: 60}}   alt="" />
          </div>
          <h3>
          Customized Software</h3>
          <p>
          Personalized software development to drive your business forward.
          </p>
        </div>
</Link> 
     </div>
      <div className="col-md-12">
      <Link to="/Services">
        <div className="why-us-explore-item">
        <div className="row">
      <div className="col-lg-6 col-6 ">
        <div className="section-title">
          <h3 className="text-anime-style-3 Digitaltext" style={{ perspective: '400px' }}>
            Digital Marketing
          </h3>
        </div>
      </div>
      <div className="col-lg-6 col-6 ">
        <div className="explore-item-icon">
          <img src="images/digital-marketing.webp" alt="Digital Marketing" style={{ width: '70px', height: '70px' }} />
        </div>
      </div>
    </div>
          <div className="row align-items-center">
            <div className="col-lg-8">
              {/* Explore Item Content Start */}
              <div
                className="explore-item-content wow fadeInUp"
                data-wow-delay="0.25s"
                style={{
                  visibility: "visible",
                  animationDelay: "0.25s",
                  animationName: "fadeInUp"
                }}
              >
                <p>
                Boost your business with our digital marketing services, including SEO, PPC management, and social media marketing. Enhance your brand with our brand consulting, logo design, and brochure marketing. Improve user experience with our UI/UX design and create compelling content with our SEO copywriting. Let us help you achieve online success.
</p>
              </div>
              {/* Explore Item Content End */}
            </div>
            <div className="col-lg-4">
              {/* Explore Item Content Start */}
              <div
                className="explore-item-tags wow fadeInUp"
                data-wow-delay="0.25s"
                style={{
                  visibility: "visible",
                  animationDelay: "0.25s",
                  animationName: "fadeInUp"
                }}
              >
                <ul>
                  <li>
                   <Link  to="/Services" className="btn-default">
                    SEO SERVICES
                   </Link>
                  </li>
                  <li>
                   <Link  to="/Services" className="btn-default">
                   SOCIAL MEDIA MARKETING
                   </Link>
                  </li>
                  <li>
                   <Link  to="/Services" className="btn-default">
                    LOGO DESIGN

                   </Link>
                  </li>
                 
                </ul>
              </div>
              {/* Explore Item Content End */}
            </div>
          </div>
        </div>
</Link>      </div>
    </div>
  </div>
</div>

    <div className="overview-company" style={{ backgroundColor: 'rgb(255, 255, 255)' }}>
      <div className="container">
        <div className="row section-row align-items-center">
          <div className="col-lg-12">
            <div className="section-title">
              <h3 className="wow fadeInUp" style={{ visibility: 'visible', animationName: 'fadeInUp' }}>
                Ourview Company
              </h3>
              <h2 className="text-anime-style-3" style={{ perspective: 400 }}>
                Proven Excellence Over Three Decades
              </h2>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-3 col-md-6">
            <div className="counter-box" style={{ backgroundColor: 'rgb(247, 248, 253)' }}>
              <h3 ref={(el) => (countersRef.current[0] = el)} data-target="180">
                0+
              </h3>
              <p>Satisfied Clients</p>
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <div className="counter-box" style={{ backgroundColor: 'rgb(247, 248, 253)' }}>
              <h3 ref={(el) => (countersRef.current[1] = el)} data-target="300">
                0+
              </h3>
              <p>Projects Created</p>
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <div className="counter-box" style={{ backgroundColor: 'rgb(247, 248, 253)' }}>
              <h3 ref={(el) => (countersRef.current[2] = el)} data-target="30">
                0+
              </h3>
              <p>Years of Experience</p>
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <div className="counter-box" style={{ backgroundColor: 'rgb(247, 248, 253)' }}>
              <h3 ref={(el) => (countersRef.current[3] = el)} data-target="45">
                0+
              </h3>
              <p>Office Staff</p>
            </div>
          </div>
        </div>
      </div>
    </div>


        <div className="why-choose-us">
        <div className="container">
          <div className="row section-row align-items-center">
            <div className="col-lg-8 col-md-12">
              {/* Section Title Start */}
              <div className="section-title">
                <h3 className="wow fadeInUp">why choose?</h3>
                <h2 className="text-anime-style-3">Why We Better Than Other</h2>
              </div>
              {/* Section Title End */}
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4 col-md-6">
              {/* Why Choose Item Start */}
              <div className="why-choose-us-item wow fadeInUp" >
                <div className="icon-box">
                  <img src="images/Innovation.webp"  style={{ width: 74, height: 75 }}  alt="" />
                </div>
                <h3>innovation</h3>
                <p>Innovative solutions transforming challenges into opportunities for growth.</p>
              </div>
              {/* Why Choose Item End */}
            </div>
            <div className="col-lg-4 col-md-6">
              {/* Why Choose Item Start */}
              <div className="why-choose-us-item wow fadeInUp" data-wow-delay="0.5s">
                <div className="icon-box">
                  <img src="images/Quality-Focused.webp"  style={{ width: 74, height: 75 }}  alt="" />
                </div>
                <h3>quality-focused</h3>
                <p>Focused on quality, delivering excellence in every aspect of our service.</p>
              </div>
              {/* Why Choose Item End */}
            </div>
            <div className="col-lg-4">
              {/* Why Choose Item Start */}
              <div className="why-choose-us-item wow fadeInUp" data-wow-delay="0.75s">
                <div className="icon-box">
                  <img src="images/value.webp"  style={{ width: 74, height: 75 }}  alt="" />
                </div>
                <h3>value for money</h3>
                <p>Quality-focused innovation delivering exceptional value for money.</p>
              </div>
            </div>
            <div className="col-lg-4">
              {/* Why Choose Item Start */}
              <div className="why-choose-us-item wow fadeInUp" data-wow-delay="1.5s">
                <div className="icon-box">
                  <img src="images/reliability.webp"  style={{ width: 74, height: 75 }}  alt="" />
                </div>
                <h3>reliability</h3>
                <p>Ensuring consistent trust and dependable performance every time.</p>
                </div>
              {/* Why Choose Item End */}
            </div>
            <div className="col-lg-4">
              {/* Why Choose Item Start */}
              <div className="why-choose-us-item wow fadeInUp" data-wow-delay="1s">
                <div className="icon-box">
                  <img src="images/Customer Satisfaction.webp"  style={{ width: 74, height: 75 }}  alt="" />
                </div>
                <h3>customer satisfaction</h3>
                <p>Customer satisfaction is our top priority, ensuring delight with every interaction.</p>
              </div>
              {/* Why Choose Item End */}
            </div>
            <div className="col-lg-4">
              {/* Why Choose Item Start */}
              <div className="why-choose-us-item wow fadeInUp" data-wow-delay="1.25s">
                <div className="icon-box">
                  <img src="images/experience.webp"  style={{ width: 74, height: 75 }}  alt="" />
                </div>
                <h3>expertise</h3>
                <p>Our expertise ensures reliable solutions and exceptional outcomes consistently.</p>
              </div>
              {/* Why Choose Item End */}
            </div>
          </div>
        </div>
      </div>
    </div>
    
  );
};

export default Home;
