

import React from 'react'
import { Link } from 'react-router-dom'; 

import './my.css';


const Footer = () => {
  return (
    <>
    <style
      dangerouslySetInnerHTML={{
        __html: "\n    .section-title h2 {\n        font-size: 50px;\n    }\n"
      }}
    />
    <div className="why-choose-us" style={{ backgroundColor: "#fff" }}>
      <div className="container">
        <div className="row section-row align-items-center">
          <div className="col-lg-12 col-md-12">
            {/* Section Title Start */}
            <div className="section-title">
              <h3
                className="wow fadeInUp"
                style={{ visibility: "visible", animationName: "fadeInUp" }}
              >
                Industries
              </h3>
              <h2
                className="text-anime-style-3"
                style={{ perspective: 400, width: "100%" }}
              >
                Creating Innovative Solutions For A Digital World
              </h2>
            </div>
            {/* Section Title End */}
          </div>
        </div>
        <div className="row">
          <div className="col-lg-3 col-md-6 col-6 text-center">
            <div
              className="why-choose-us-item why-choose-us-item-my wow fadeInUp"
              data-wow-delay="0.25s"
              style={{
                visibility: "visible",
                animationDelay: "0.25s",
                animationName: "fadeInUp"
              }}
            >
              <div className="icon-box">
                <img
                  src="images/industry/MANUFACTURING.webp"
                  style={{ width: 74, height: 81, display: "inline-block" }}
                  alt=""
                />
              </div>
              <h3>MANUFACTURING</h3>
            </div>
         </div>
          <div className="col-lg-3 col-md-6 col-6 text-center">
            <div
              className="why-choose-us-item why-choose-us-item-my wow fadeInUp"
              data-wow-delay="0.5s"
              style={{
                visibility: "visible",
                animationDelay: "0.5s",
                animationName: "fadeInUp"
              }}
            >
              <div className="icon-box">
                <img
                  src="images/industry/travel.webp"
                  style={{ width: 74, height: 81, display: "inline-block" }}
                  alt=""
                />
              </div>
              <h3>TRAVEL</h3>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 col-6 text-center">
            <div
              className="why-choose-us-item why-choose-us-item-my wow fadeInUp"
              data-wow-delay="0.75s"
              style={{
                visibility: "visible",
                animationDelay: "0.75s",
                animationName: "fadeInUp"
              }}
            >
              <div className="icon-box">
                <img
                  src="images/industry/EDUCATION.webp"
                  style={{ width: 74, height: 81, display: "inline-block" }}
                  alt=""
                />
              </div>
              <h3>EDUCATION</h3>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 col-6 text-center">
            <div
              className="why-choose-us-item why-choose-us-item-my wow fadeInUp"
              data-wow-delay="0.75s"
              style={{
                visibility: "visible",
                animationDelay: "0.75s",
                animationName: "fadeInUp"
              }}
            >
              <div className="icon-box">
                <img
                  src="images/industry/hospital.webp"
                  style={{ width: 74, height: 81, display: "inline-block" }}
                  alt=""
                />
              </div>
              <h3>HOSPITAL</h3>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-3 col-md-6 col-6 text-center">
            <div
              className="why-choose-us-item why-choose-us-item-my wow fadeInUp"
              data-wow-delay="0.25s"
              style={{
                visibility: "visible",
                animationDelay: "0.25s",
                animationName: "fadeInUp"
              }}
            >
              <div className="icon-box">
                <img
                  src="images/industry/ai.webp"
                  style={{ width: 74, height: 81, display: "inline-block" }}
                  alt=""
                />
              </div>
              <h3>TECHNOLOGY</h3>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 col-6 text-center">
            <div
              className="why-choose-us-item why-choose-us-item-my wow fadeInUp"
              data-wow-delay="0.5s"
              style={{
                visibility: "visible",
                animationDelay: "0.5s",
                animationName: "fadeInUp"
              }}
            >
              <div className="icon-box">
                <img
                  src="images/industry/logistics.webp"
                  style={{ width: 74, height: 81, display: "inline-block" }}
                  alt=""
                />
              </div>
              <h3>LOGISTIC</h3>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 col-6 text-center">
            <div
              className="why-choose-us-item why-choose-us-item-my wow fadeInUp"
              data-wow-delay="0.75s"
              style={{
                visibility: "visible",
                animationDelay: "0.75s",
                animationName: "fadeInUp"
              }}
            >
              <div className="icon-box">
                <img
                  src="images/industry/online-shopping.webp"
                  style={{ width: 74, height: 81, display: "inline-block" }}
                  alt=""
                />
              </div>
              <h3>E-COMMERCE</h3>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 col-6 text-center">
            <div
              className="why-choose-us-item why-choose-us-item-my wow fadeInUp"
              data-wow-delay="0.75s"
              style={{
                visibility: "visible",
                animationDelay: "0.75s",
                animationName: "fadeInUp"
              }}
            >
              <div className="icon-box">
                <img
                  src="images/industry/mobile-banking.webp"
                  style={{ width: 74, height: 81, display: "inline-block" }}
                  alt=""
                />
              </div>
              <h3>BANKING</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
    <style
      dangerouslySetInnerHTML={{
        __html:
          "\n    .partners-logo {\n        background-color: var(--white-color);\n    }\n"
      }}
    />
    <div className="why-choose-us">
      <div className="container">
        <div className="row section-row align-items-center">
          <div className="col-lg-12">
            {/* Section Title Start */}
            <div className="section-title">
              <h3 className="wow fadeInUp">executive partners</h3>
              <h2 className="text-anime-style-3">
                100+ Partners &amp; supporters
              </h2>
            </div>
            {/* Section Title End */}
          </div>
        </div>
        <div className="row">
        <div className="col-lg-2 col-6">
            <div className="partners-logo wow fadeInUp" data-wow-delay="1.6s">
              <img src="images/client/nisha.webp" alt="" />
            </div>
          </div>
          <div className="col-lg-2 col-6">
            <div className="partners-logo wow fadeInUp" data-wow-delay="1.6s">
              <img src="images/client/snkb.webp" alt="" />
            </div>
          </div>
          <div className="col-lg-2 col-6">
            
            <div className="partners-logo wow fadeInUp" data-wow-delay="1.4s">
              <img src="images/client/Bhakti.webp" alt="" />
            </div>
          </div>

        <div className="col-lg-2 col-6">
            <div className="partners-logo wow fadeInUp" data-wow-delay="1.6s">
              <img src="images/client/ibs.webp" alt="" />
            </div>
          </div>
          <div className="col-lg-2 col-6">
            <div className="partners-logo wow fadeInUp" data-wow-delay="0.6s">
              <img src="images/client/apar.webp" alt="" />
            </div>
          </div>
          <div className="col-lg-2 col-6">
            
            <div className="partners-logo wow fadeInUp" data-wow-delay="0.8s">
              <img src="images/client/gizmo.webp " alt="" />
            </div>
          </div>
          <div className="col-lg-2 col-6">
            <div className="partners-logo wow fadeInUp" data-wow-delay="1.6s">
              <img src="images/client/spintech.webp" alt="" />
            </div>
          </div>
          <div className="col-lg-2 col-6">
            
            <div className="partners-logo wow fadeInUp" data-wow-delay="1.2s">
              <img src="images/client/bannerstudio.webp " alt="" />
            </div>
          </div>
         
          <div className="col-lg-2 col-6">
            <div className="partners-logo wow fadeInUp" data-wow-delay="1.6s">
              <img src="images/client/bm.webp" alt="" />
            </div>
          </div>
          <div className="col-lg-2 col-6">
            <div className="partners-logo wow fadeInUp" data-wow-delay="1.6s">
              <img src="images/client/gold-king.webp" alt="" />
            </div>
          </div>
          <div className="col-lg-2 col-6">
            <div className="partners-logo wow fadeInUp" data-wow-delay="1.6s">
              <img src="images/client/hind.webp" alt="" />
            </div>
          </div>
          <div className="col-lg-2 col-6">
            <div className="partners-logo wow fadeInUp" data-wow-delay="1.6s">
              <img src="images/client/manglik-gold.webp" alt="" />
            </div>
          </div>
          <div className="col-lg-2 col-6">
            <div className="partners-logo wow fadeInUp" data-wow-delay="1.6s">
              <img src="images/client/balaji.webp" alt="" />
            </div>
          </div>
          <div className="col-lg-2 col-6">
            
            <div className="partners-logo wow fadeInUp" data-wow-delay="0.2s">
              <img src="images/client/weighmall.webp" alt="" />
            </div>
          </div>
          <div className="col-lg-2 col-6">
            <div className="partners-logo wow fadeInUp" data-wow-delay="1.6s">
              <img src="images/client/svft.webp" alt="" />
            </div>
          </div>
          <div className="col-lg-2 col-6">
            
            <div className="partners-logo wow fadeInUp" data-wow-delay="0.4s">
              <img src="images/client/bluemaxie.webp" alt="" />
            </div>
          </div>
         
          <div className="col-lg-2 col-6">
            
            <div className="partners-logo wow fadeInUp" data-wow-delay="1s">
              <img src="images/client/khodiyar.webp" alt="" />
            </div>
          </div>
         
          <div className="col-lg-2 col-6">
            <div className="partners-logo wow fadeInUp" data-wow-delay="1.6s">
              <img src="images/client/greenimapq.webp" alt="" />
            </div>
          </div>
          <div className="col-lg-2 col-6">
            <div className="partners-logo wow fadeInUp" data-wow-delay="1.6s">
              <img src="images/client/niyabuzz.webp" alt="" />
            </div>
          </div>
          <div className="col-lg-2 col-6">
            <div className="partners-logo wow fadeInUp" data-wow-delay="1.6s">
              <img src="images/client/gold-king.webp" alt="" />
            </div>
          </div>
          <div className="col-lg-2 col-6">
            <div className="partners-logo wow fadeInUp" data-wow-delay="1.6s">
              <img src="images/client/supersteel.webp" alt="" />
            </div>
          </div>
          <div className="col-lg-2 col-6">
            <div className="partners-logo wow fadeInUp" data-wow-delay="1.2s">
              <img src="images/client/namsto.webp " alt="" />
            </div>
          </div>
          <div className="col-lg-2 col-6">
            <div className="partners-logo wow fadeInUp" data-wow-delay="1.6s">
              <img src="images/client/ansh.webp" alt="" />
            </div>
          </div>
          <div className="col-lg-2 col-6">
            <div className="partners-logo wow fadeInUp" data-wow-delay="1.6s">
              <img src="images/client/real.webp" alt="" />
            </div>
          </div>
          <div className="col-lg-2 col-6">
            <div className="partners-logo wow fadeInUp" data-wow-delay="1.6s">
              <img src="images/client/unique.webp" alt="" />
            </div>
          </div>
          <div className="col-lg-2 col-6">
            <div className="partners-logo wow fadeInUp" data-wow-delay="1.6s">
              <img src="images/client/SPAN_A.webp" alt="" />
            </div>
          </div>
          <div className="col-lg-2 col-6">
            <div className="partners-logo wow fadeInUp" data-wow-delay="1.6s">
              <img src="images/client/AAMA_A.webp" alt="" />
            </div>
          </div>
          <div className="col-lg-2 col-6">
            <div className="partners-logo wow fadeInUp" data-wow-delay="1.6s">
              <img src="images/client/NRM.webp" alt="" />
            </div>
          </div>
          <div className="col-lg-2 col-6">
            <div className="partners-logo wow fadeInUp" data-wow-delay="1.6s">
              <img src="images/client/ADC.webp" alt="" />
            </div>
          </div>
          <div className="col-lg-2 col-6">
            <div className="partners-logo wow fadeInUp" data-wow-delay="1.6s">
              <img src="images/client/APOLLO_A.webp" alt="" />
            </div>
          </div>
          <div className="col-lg-2 col-6">
            <div className="partners-logo wow fadeInUp" data-wow-delay="1.6s">
              <img src="images/client/NP.webp" alt="" />
            </div>
          </div>
          <div className="col-lg-2 col-6">
            <div className="partners-logo wow fadeInUp" data-wow-delay="1.6s">
              <img src="images/client/VED_A.webp" alt="" />
            </div>
          </div>
          <div className="col-lg-2 col-6">
            <div className="partners-logo wow fadeInUp" data-wow-delay="1.6s">
              <img src="images/client/TA.webp" alt="" />
            </div>
          </div>
          <div className="col-lg-2 col-6">
            <div className="partners-logo wow fadeInUp" data-wow-delay="1.6s">
              <img src="images/client/INDOWIND_A.webp" alt="" />
            </div>
          </div>
          <div className="col-lg-2 col-6">
            <div className="partners-logo wow fadeInUp" data-wow-delay="1.6s">
              <img src="images/client/ABM.webp" alt="" />
            </div>
          </div>
          <div className="col-lg-2 col-6">
            <div className="partners-logo wow fadeInUp" data-wow-delay="1.6s">
              <img src="images/client/RAJMANDIR.webp" alt="" />
            </div>
          </div>
          <div className="col-lg-2 col-6">
            <div className="partners-logo wow fadeInUp" data-wow-delay="1.6s">
              <img src="images/client//RK_A.webp" alt="" />
            </div>
          </div>
          <div className="col-lg-2 col-6">
            <div className="partners-logo wow fadeInUp" data-wow-delay="1.6s">
              <img src="images/client/RI.webp" alt="" />
            </div>
          </div>
          <div className="col-lg-2 col-6">
            <div className="partners-logo wow fadeInUp" data-wow-delay="1.6s">
              <img src="images/client/borq.webp" alt="" />
            </div>
          </div>
          <div className="col-lg-2 col-6">
            <div className="partners-logo wow fadeInUp" data-wow-delay="1.6s">
              <img src="images/client/APOLLOMULTI_A.webp" alt="" />
            </div>
          </div>
          <div className="col-lg-2 col-6">
            <div className="partners-logo wow fadeInUp" data-wow-delay="1.6s">
              <img src="images/client/ant.webp" alt="" />
            </div>
          </div>
          {/* <div className="col-lg-2 col-6">
            <div className="partners-logo wow fadeInUp" data-wow-delay="1.6s">
              <img src="images/client/BHAVI_A.webp" alt="" />
            </div>
          </div> */}
             <div className="col-lg-2 col-6">
            <div className="partners-logo wow fadeInUp" data-wow-delay="1.6s">
              <img src="images/client/BS.webp" alt="" />
            </div>
          </div>

          
        </div>
      </div>
    </div>
    {/* Overview Comapny Section Start */}
    <div className="why-choose-us" style={{ backgroundColor: "#fff" }}>
      <div className="container">
        <div className="row section-row align-items-center">
          <div className="col-lg-12 col-md-12">
            {/* Section Title Start */}
            <div className="section-title">
              <h3
                className="wow fadeInUp"
                style={{ visibility: "visible", animationName: "fadeInUp" }}
              >
                Our services
              </h3>
              <h2
                className="text-anime-style-3"
                style={{ perspective: 400, width: "100%" }}
              >
                Seamless IT Solutions For A Connected Future
              </h2>
            </div>
            {/* Section Title End */}
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            {/* Why Us Explore Item Start */}
            <div className="why-us-explore-item">
              <div className="row align-items-center">
                <div className="col-lg-12 ">
                  {/* Explore Item Content Start */}
                  <div
                    className="explore-item-tags wow fadeInUp"
                    data-wow-delay="0.25s"
                    style={{
                      visibility: "visible",
                      animationDelay: "0.25s",
                      animationName: "fadeInUp",
                      textAlign: "center"
                    }}
                  >
                    <ul>
                      <li>
                        <Link  to={"/Search_Engine_Optimization "} className="btn-default-my">
                          SEO Service
                        </Link>
                      </li>
                      <li>
                        <Link 
                          to={"/Search_Engine_Marketing "}
                          className="btn-default-my"
                        >
                          Search Engine Marketing
                        </Link>
                      </li>
                      <li>
                        <Link  to={"/Logo_Design"} className="btn-default-my">
                          Logo Design
                        </Link>
                      </li>
                      <li>
                        <Link 
                          to={"/Social_Media_Marketing "}
                          className="btn-default-my"
                        >
                          Social Media Marketing
                        </Link>
                      </li>
                      <li>
                        <Link  to={"/Seo_Copywrite "} className="btn-default-my">
                          SEO Copywriting
                        </Link>
                      </li>
                      <li>
                        <Link  to={"/Broucher_Design"} className="btn-default-my">
                          Broucher Design
                        </Link>
                      </li>
                      <li>
                        <Link 
                          to={"/Pay_Per_Click_Management "}
                          className="btn-default-my"
                        >
                          Pay Per Click Management
                        </Link>
                      </li>
                      <li>
                        <Link  to={"/Digital_Marketing"} className="btn-default-my">
                          Digital Marketing
                        </Link>
                      </li>
                      <li>
                        <Link  to={"/Brand_Consulting"} className="btn-default-my">
                          Brand Consulting 
                        </Link>
                      </li>
                      <li>
                        <Link  to={"/Jewelery_Software"} className="btn-default-my">
                          Jewellery Software
                        </Link>
                      </li>
                      <li>
                        <Link  to={"/CRM_Software"} className="btn-default-my">
                          CRM Software
                        </Link>
                      </li>
                      <li>
                        <Link 
                          to={"/Hr_And_Pay_Roll_Management_Software"}
                          className="btn-default-my"
                        >
                          HR and Payroll Management Software
                        </Link>
                      </li>
                      <li>
                        <Link 
                          to={"/Real_Estate_Portal_Development"}
                          className="btn-default-my"
                        >
                          Real Estate Software
                        </Link>
                      </li>
                      <li>
                        <Link 
                          to={"/Inventory_Management_Software "}
                          className="btn-default-my"
                        >
                          Inventory Management System
                        </Link>
                      </li>
                      <li>
                        <Link  to={"/Website_Design "} className="btn-default-my">
                          Website Design
                        </Link>
                      </li>
                      <li>
                        <Link 
                          to={"/Hospital_Management_Software"}
                          className="btn-default-my"
                        >
                          Hospital Management Software 
                        </Link>
                      </li>
                      <li>
                        <Link 
                          to={"/School_Management_Software"}
                          className="btn-default-my"
                        >
                          School Management System
                        </Link>
                      </li>
                      <li>
                        <Link  to={"/Website_Development "} className="btn-default-my">
                          Website Development
                        </Link>
                      </li>
                      <li>
                        <Link  to={"/CRM_Development"} className="btn-default-my">
                          CRM Software Development
                        </Link>
                      </li>
                      <li>
                        <Link 
                          to={"/Software_Development"}
                          className="btn-default-my"
                        >
                          Software Development
                        </Link>
                      </li>
                      <li>
                        <Link 
                          to={"/Education_Web_Portal_Development"}
                          className="btn-default-my"
                        >
                          Educational Web Portal Development
                        </Link>
                      </li>
                      <li>
                        <Link 
                          to={"/Restaurant_Web_Portal_Development"}
                          className="btn-default-my"
                        >
                          Restaurant Web Portal Development
                        </Link>
                      </li>
                      <li>
                        <Link 
                          to={"/Health_Care_Portal_Development"}
                          className="btn-default-my"
                        >
                          Healthcare Portal Development
                        </Link>
                      </li>
                      <li>
                        <Link 
                          to={"/Travel_Portal_Development"}
                          className="btn-default-my"
                        >
                          Travel Portal Development
                        </Link>
                      </li>
                      <li>
                        <Link 
                          to={"/Real_Estate_Portal_Development"}
                          className="btn-default-my"
                        >
                          Real Estate Web Portal Development
                        </Link>
                      </li>
                      <li>
                        <Link 
                          to={"/Ecommerce_Portal_Development"}
                          className="btn-default-my"
                        >
                          E-commerce Web Development
                        </Link>
                      </li>
                      <li>
                        <Link 
                          to={"/Mobile_App_Development"}
                          className="btn-default-my"
                        >
                          Mobile App Development
                        </Link>
                      </li>
                      <li>
                        <Link 
                          to={"/Hybrid_App_Development"}
                          className="btn-default-my"
                        >
                          Hybrid App Development
                        </Link>
                      </li>
                      <li>
                        <Link 
                          to={"/Native_App_Development"}
                          className="btn-default-my"
                        >
                          Native App Development
                        </Link>
                      </li>
                      <li>
                        <Link  to={"/Ui_Ux_Design"} className="btn-default-my">
                          UX/UI Design
                        </Link>
                      </li>
                    </ul>
                  </div>
                  {/* Explore Item Content End */}
                </div>
              </div>
            </div>
            {/* Why Us Explore Item End */}
          </div>
        </div>
      </div>
    </div>
    {/* Footer Start */}
    <footer className="main-footer">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            {/* Mega Footer Start */}
            <div className="mega-footer">
              <div className="row">
                <div className="col-lg-3 col-md-12">
                  {/* Footer About Start */}
                  <div className="footer-about">
                  <figure>
  <Link to="/">
    <img
      src="images/logo.webp"
      style={{ width: "auto", height: 50 }}
      alt="Logo"
    />
  </Link>
</figure>
<p style={{ textAlign: "start", marginBottom: "-0.10em" }}>
                      <strong style={{ color: "#333" }}> Ahmedabad</strong>
                      <img
                        src="images/india.webp"
                        style={{
                          width: 40,
                          height: 40,
                          margin: 0,
                          marginLeft: 7
                        }}
                        alt=""
                      />
                    </p>
                    <p style={{ marginBottom: "0.5em" }}>
                      510, Kasturi Pride, SP Ring Road Nikol, Ahmedabad
                    </p>
                    <p style={{ marginBottom: "0.5em"  }}>
                      <Link  to="tel:+918000055915" style={{ color: "#9C9FA6" }}>
                      <div className="contact-info">
  <i className="fas fa-phone" style={{ marginRight: '10px' }}></i>
  +91 80000 55915
</div>

                      </Link>
                    </p>
                    <div>
  <p style={{ marginBottom: "0.5em", display: "flex", alignItems: "center" }}>
    <i className="far fa-envelope" style={{ marginRight: "0.5em" }} />
    <Link 
      to="mailto:contact@gurusoftware.in"
      style={{ color: "#9C9FA6", textDecoration: "none" }}
    >
      contact@gurusoftware.in
    </Link>
  </p>
  <p style={{ marginBottom: "0.5em", display: "flex", alignItems: "center" }}>
    <i className="fas fa-map-marker-alt" style={{ marginRight: "0.5em" }} />
    <Link 
      to="https://maps.app.goo.gl/xoPwoxM8YHvRoa1aA"
      target="_blank"
      rel="noopener noreferrer"
      style={{ color: "#9C9FA6", textDecoration: "none" }}
    >
      View Map
    </Link>
  </p>
</div>

                    <p style={{ textAlign: "start", marginBottom: "-0.10em" }}>
                      <strong style={{ color: "#333" }}>New Joursey</strong>
                      <img
                        src="images/united-states.webp"
                        style={{
                          width: 40,
                          height: 40,
                          margin: 0,
                          marginLeft: 7
                        }}
                        alt=""
                      />
                    </p>
                    
                    <p style={{ marginBottom: "0.5em" }}>
                    1 Dayton Drive Apt 7X, Edison, 
                      <br />
                      NJ 08820, USA
                    </p>
                    <div>
  <p style={{ marginBottom: "0.5em", display: "flex", alignItems: "center" }}>
    <Link to="tel:+13096126337" style={{ color: "#9C9FA6", textDecoration: "none", display: "flex", alignItems: "center" }}>
      <i className="fas fa-phone" style={{ marginRight: "0.5em" }} /> 
      +1 (309) 612-6337
    </Link>
  </p>
  <p style={{ marginBottom: "0.5em", display: "flex", alignItems: "center" }}>
    <i className="far fa-envelope" style={{ marginRight: "0.5em" }} />
    <Link 
      to="mailto:contact@gurusoftware.in"
      style={{ color: "#9C9FA6", textDecoration: "none", display: "flex", alignItems: "center" }}
    >
      contact@gurusoftware.in
    </Link>
  </p>
  <p style={{ marginBottom: "0.5em", display: "flex", alignItems: "center" }}>
    <i className="fas fa-map-marker-alt" style={{ marginRight: "0.5em" }} />
    <Link 
      to="https://maps.app.goo.gl/8NRP5MUYsBXY7MGr9"
      target="_blank"
      rel="noopener noreferrer"
      style={{ color: "#9C9FA6", textDecoration: "none", display: "flex", alignItems: "center" }}
    >
      View Map
    </Link>
  </p>
</div>
                   
                  
                  </div>
                  {/* Footer About End */}
                </div>
                <div className="col-lg-3 col-md-4">
                  {/* Footer Links Start */}
                  <div className="footer-links">
                    <h2>pages</h2>
                    <ul>
                      <li>
                        <Link  to="/">Home</Link>
                      </li>
                      <li>
                        <Link  to="/About_Us">About Us</Link>
                      </li>
                     
                      <li>
                        <Link  to="/Portfolio">Portfolio</Link>
                      </li>
                      <li>
                        <Link  to="/Blog">Blogs</Link>
                      </li>
                      <li>
                        <Link  to="/Contact_Us">Contact Us</Link>
                      </li>
                    </ul>
                  </div>
                  {/* Footer Links End */}
                </div>
                <div className="col-lg-3 col-md-4">
                  {/* Footer Links Start */}
                  <div className="footer-links">
                    <h2>services</h2>
                    <ul>
                      <li>
                        <Link  to="/Services">Website Development</Link>
                      </li>
                      <li>
                        <Link  to="/Services">Mobile App Development</Link>
                      </li>
                      <li>
                        <Link  to="/Services">Customized Software</Link>
                      </li>
                      <li>
                        <Link  to="/Services">Digital Marketing</Link>
                      </li>
                    </ul>
                  </div>
                  {/* Footer Links End */}
                </div>
                <div className="col-lg-3 col-md-4">
                  <figure>
                    <img src="images/iso2.webp" alt="" />
                  </figure>
                  <br />
                  <ul className="list-unstyled all-footer--links line-footer-links">
                    <li>
                      &nbsp;
                      <Link  to="/">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width={50}
                          height={50}
                          viewBox="0 0 39.998 40"
                          style={{ marginRight: 5 }}
                        >
                          <g id="visa" transform="translate(-.01)">
                            <circle
                              id="Ellipse_85"
                              cx="19.999"
                              cy="19.999"
                              r="19.999"
                              fill="#f3f2f2"
                              data-name="Ellipse 85"
                              transform="translate(.01 .002)"
                            />
                            <g
                              id="Group_1435"
                              data-name="Group 1435"
                              transform="translate(6.654 14.655)"
                            >
                              <path
                                id="Path_964"
                                d="M175.483 184.41l1.53-9.037h2.478l-1.53 9.037z"
                                fill="#293688"
                                data-name="Path 964"
                                transform="translate(-167.318 -175.227)"
                              />
                              <path
                                id="Path_965"
                                d="M228.677 174.026a6.435 6.435 0 0 0-2.221-.379c-2.449 0-4.173 1.226-4.188 2.982-.014 1.3 1.231 2.022 2.172 2.455.964.442 1.289.726 1.284 1.121-.006.606-.771.883-1.483.883a5.229 5.229 0 0 1-2.332-.474l-.319-.144-.348 2.023a7.88 7.88 0 0 0 2.761.482c2.6 0 4.3-1.211 4.315-3.087.009-1.028-.651-1.81-2.08-2.455-.866-.417-1.4-.7-1.391-1.12 0-.375.449-.777 1.419-.777a4.594 4.594 0 0 1 1.855.346l.222.1.337-1.959z"
                                fill="#293688"
                                data-name="Path 965"
                                transform="translate(-209.214 -173.647)"
                              />
                              <path
                                id="Path_966"
                                d="M305.644 175.373a1.231 1.231 0 0 0-1.283.77l-3.672 8.267h2.623l.51-1.385h3.134l.291 1.385h2.332l-2.04-9.037h-1.9zm-1.126 5.844c.2-.52.987-2.526.987-2.526-.014.024.2-.523.329-.862l.168.779.573 2.609h-2.057z"
                                fill="#293688"
                                data-name="Path 966"
                                transform="translate(-281.957 -175.227)"
                              />
                              <path
                                id="Path_967"
                                d="M82.385 181.567l-.218-1.239a7.059 7.059 0 0 0-3.428-3.789l2.189 7.87h2.624l3.935-9.037h-2.623z"
                                fill="#293688"
                                data-name="Path 967"
                                transform="translate(-78.739 -175.227)"
                              />
                            </g>
                            <g
                              id="Group_1436"
                              data-name="Group 1436"
                              transform="translate(2.262 14.801)"
                            >
                              <path
                                id="Path_968"
                                d="M46.823 175.373v.146a8.46 8.46 0 0 1 6.122 4.81l-.9-4.184a1.065 1.065 0 0 0-1.142-.771z"
                                fill="#f7981d"
                                data-name="Path 968"
                                transform="translate(-45.124 -175.373)"
                              />
                              <path
                                id="Path_969"
                                d="M44.442 357.012a19.992 19.992 0 0 0 17.746-10.779H26.7a19.992 19.992 0 0 0 17.742 10.779z"
                                fill="#f7981d"
                                data-name="Path 969"
                                transform="translate(-26.696 -331.813)"
                              />
                            </g>
                            <path
                              id="Path_970"
                              d="M46.137 0a20 20 0 0 0-17.589 10.474h35.179A20 20 0 0 0 46.137 0z"
                              fill="#293688"
                              data-name="Path 970"
                              transform="translate(-26.13)"
                            />
                          </g>
                        </svg>
                      </Link>
                      <Link  to="/">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          id="mastercard"
                          width={50}
                          height={50}
                          viewBox="0 0 40 40"
                          style={{ marginRight: 5 }}
                        >
                          <circle
                            id="Ellipse_86"
                            cx={20}
                            cy={20}
                            r={20}
                            fill="#3c80ad"
                            data-name="Ellipse 86"
                          />
                          <path
                            id="Path_971"
                            d="M59.55 121.955a8.333 8.333 0 1 0 5.648 14.5 8.368 8.368 0 0 0 .845-.893h-1.7a8.064 8.064 0 0 1-.62-.859h2.938a8.33 8.33 0 0 0 .485-.892h-3.91a8.118 8.118 0 0 1-.348-.875h4.6a8.316 8.316 0 0 0 .428-2.643 8.407 8.407 0 0 0-.191-1.785h-5.081a8.484 8.484 0 0 1 .237-.875h4.6a8.355 8.355 0 0 0-.359-.892h-3.886a8.041 8.041 0 0 1 .477-.875h2.936a8.281 8.281 0 0 0-.645-.892h-1.638a7.619 7.619 0 0 1 .834-.841 8.27 8.27 0 0 0-5.6-2.172z"
                            fill="#931b1d"
                            data-name="Path 971"
                            transform="translate(-47.372 -110.825)"
                          />
                          <path
                            id="Path_972"
                            d="M214.009 130.291a8.315 8.315 0 1 1-16.631 0 8.315 8.315 0 1 1 16.631 0z"
                            fill="#f16622"
                            data-name="Path 972"
                            transform="translate(-178.495 -110.824)"
                          />
                          <path
                            id="Path_973"
                            d="M214.009 131.5a8.315 8.315 0 1 1-16.631 0 8.315 8.315 0 1 1 16.631 0z"
                            fill="#f7981d"
                            data-name="Path 973"
                            transform="translate(-178.495 -111.926)"
                          />
                          <path
                            id="Path_974"
                            d="M59.55 123.193a8.334 8.334 0 1 0 5.648 14.5 8.237 8.237 0 0 0 .845-.892h-1.7a8 8 0 0 1-.62-.858h2.938a8.4 8.4 0 0 0 .485-.893h-3.91a8.18 8.18 0 0 1-.348-.875h4.6a8.393 8.393 0 0 0 .237-4.428h-5.081a8.484 8.484 0 0 1 .237-.875h4.6a8.365 8.365 0 0 0-.359-.893h-3.886a8.117 8.117 0 0 1 .477-.875h2.936a8.432 8.432 0 0 0-.645-.892h-1.638a7.719 7.719 0 0 1 .834-.841 8.271 8.271 0 0 0-5.6-2.172z"
                            fill="#cb2026"
                            data-name="Path 974"
                            transform="translate(-47.372 -111.949)"
                          />
                          <g
                            id="Group_1437"
                            data-name="Group 1437"
                            transform="translate(4.705 17.457)"
                          >
                            <path
                              id="Path_975"
                              d="M182.8 212.878l.111-.754c-.061 0-.15.026-.228.026-.308 0-.342-.164-.322-.285l.249-1.539h.468l.113-.835h-.441l.09-.518h-.886c-.019.019-.523 2.918-.523 3.27a.658.658 0 0 0 .705.752 2.327 2.327 0 0 0 .664-.117z"
                              fill="#fff"
                              data-name="Path 975"
                              transform="translate(-170.852 -208.604)"
                            />
                            <path
                              id="Path_976"
                              d="M203.035 216.523a1.391 1.391 0 0 0 1.529 1.552 2.24 2.24 0 0 0 .935-.146l.156-.856a2.738 2.738 0 0 1-.94.219c-.951 0-.784-.711-.784-.711h1.8a6.432 6.432 0 0 0 .116-.81 1.189 1.189 0 0 0-1.263-1.3c-.89.004-1.549.965-1.549 2.052zm1.553-1.253c.5 0 .407.563.407.608h-.983c0-.059.088-.609.576-.609z"
                              fill="#fff"
                              data-name="Path 976"
                              transform="translate(-190.475 -213.687)"
                            />
                            <path
                              id="Path_977"
                              d="M268.15 208.423l.159-.968a2.119 2.119 0 0 1-.732.219.878.878 0 0 1-.88-1c0-1.048.54-1.625 1.142-1.625a1.574 1.574 0 0 1 .813.254l.144-.939a3.166 3.166 0 0 0-1-.218c-1.022 0-2.017.888-2.017 2.558 0 1.106.537 1.837 1.594 1.837a4.072 4.072 0 0 0 .777-.118z"
                              fill="#fff"
                              data-name="Path 977"
                              transform="translate(-247.628 -204.149)"
                            />
                            <path
                              id="Path_978"
                              d="M115.683 214.654a3.059 3.059 0 0 0-1.073.2l-.128.765a2.8 2.8 0 0 1 .965-.156c.33 0 .571.037.571.306a.571.571 0 0 1-.029.223s-.26-.022-.38-.022c-.765 0-1.57.327-1.57 1.315a.841.841 0 0 0 .855.956 1.15 1.15 0 0 0 .908-.408l-.029.339h.779l.348-2.444a1.047 1.047 0 0 0-1.217-1.074zm.19 1.989c.017.149-.094.849-.627.849a.323.323 0 0 1-.347-.335c0-.243.132-.534.781-.534a.577.577 0 0 1 .193.02z"
                              fill="#fff"
                              data-name="Path 978"
                              transform="translate(-109.381 -213.852)"
                            />
                            <path
                              id="Path_979"
                              d="M150.345 218.286c.2 0 1.339.051 1.339-1.128 0-1.1-1.055-.885-1.055-1.328 0-.22.172-.29.486-.29.125 0 .605.04.605.04l.112-.784a4.142 4.142 0 0 0-.817-.069c-.656 0-1.32.261-1.32 1.158 0 1.017 1.109.914 1.109 1.342 0 .286-.31.309-.549.309a2.877 2.877 0 0 1-.786-.135l-.118.775a2.768 2.768 0 0 0 .994.11z"
                              fill="#fff"
                              data-name="Path 979"
                              transform="translate(-141.575 -213.92)"
                            />
                            <path
                              id="Path_980"
                              d="M362.2 205.422l-.191 1.187a1.17 1.17 0 0 0-.855-.461c-.812 0-1.488.98-1.488 2.106 0 .727.36 1.439 1.1 1.439a1.1 1.1 0 0 0 .824-.371l-.039.316h.861l.677-4.219zm-.411 2.316c0 .468-.232 1.094-.712 1.094-.319 0-.468-.268-.468-.689 0-.687.308-1.142.7-1.142.315-.001.479.22.479.737z"
                              fill="#fff"
                              data-name="Path 980"
                              transform="translate(-333.096 -205.323)"
                            />
                            <path
                              id="Path_981"
                              d="M62.472 209.632l.54-3.186.079 3.186h.61l1.138-3.186-.5 3.186h.906l.7-4.224h-1.4L63.664 208l-.045-2.591h-1.292l-.708 4.224z"
                              fill="#fff"
                              data-name="Path 981"
                              transform="translate(-61.619 -205.312)"
                            />
                            <path
                              id="Path_982"
                              d="M240.456 218.666c.258-1.468.305-2.661.921-2.444a3.439 3.439 0 0 1 .329-1.028.919.919 0 0 0-.171-.012c-.4 0-.69.543-.69.543l.078-.5h-.823l-.552 3.439z"
                              fill="#fff"
                              data-name="Path 982"
                              transform="translate(-223.805 -214.34)"
                            />
                            <path
                              id="Path_983"
                              d="M303.646 214.654a3.056 3.056 0 0 0-1.072.2l-.128.765a2.792 2.792 0 0 1 .964-.156c.329 0 .571.037.571.306a.565.565 0 0 1-.03.223s-.26-.022-.381-.022c-.766 0-1.569.327-1.569 1.315a.841.841 0 0 0 .855.956 1.148 1.148 0 0 0 .908-.408l-.029.339h.78l.348-2.444a1.047 1.047 0 0 0-1.217-1.074zm.19 1.989c.017.149-.093.849-.627.849a.323.323 0 0 1-.346-.335c0-.243.132-.534.78-.534a.579.579 0 0 1 .193.02z"
                              fill="#fff"
                              data-name="Path 983"
                              transform="translate(-280.646 -213.852)"
                            />
                            <path
                              id="Path_984"
                              d="M337.8 218.666c.258-1.468.306-2.661.921-2.444a3.417 3.417 0 0 1 .329-1.028.933.933 0 0 0-.171-.012c-.4 0-.69.543-.69.543l.079-.5h-.825l-.552 3.439h.909z"
                              fill="#fff"
                              data-name="Path 984"
                              transform="translate(-312.063 -214.34)"
                            />
                          </g>
                        </svg>
                      </Link>
                      <Link  to="/">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          id="maestro"
                          width={50}
                          height={50}
                          viewBox="0 0 40 40"
                          style={{ marginRight: 5 }}
                        >
                          <circle
                            id="Ellipse_84"
                            cx={20}
                            cy={20}
                            r={20}
                            fill="#003473"
                            data-name="Ellipse 84"
                          />
                          <g
                            id="Group_1433"
                            data-name="Group 1433"
                            transform="translate(5.075 11.009)"
                          >
                            <path
                              id="Path_953"
                              d="M74.532 145.451a8.618 8.618 0 1 0-5.774 2.228 8.58 8.58 0 0 0 5.774-2.228z"
                              fill="#009ddd"
                              data-name="Path 953"
                              transform="translate(-60.13 -130.442)"
                            />
                            <path
                              id="Path_954"
                              d="M230.767 169.629a8.615 8.615 0 0 0 0-12.781v12.781z"
                              fill="#009ddd"
                              data-name="Path 954"
                              transform="translate(-216.365 -154.619)"
                            />
                          </g>
                          <path
                            id="Path_955"
                            d="M207.993 130.446a8.619 8.619 0 0 0-5.792 2.229 11.071 11.071 0 0 0-.861.912h1.739a11.068 11.068 0 0 1 .659.911h-3.056q-.278.456-.507.912h4.052c.136.3.27.609.371.912h-4.777a8.247 8.247 0 0 0-.254.928h5.284a9.177 9.177 0 0 1 .186 1.823 8.674 8.674 0 0 1-.44 2.735h-4.777q.151.455.353.912h4.052a9.371 9.371 0 0 1-.49.912h-3.056a9.155 9.155 0 0 0 .659.912h1.739a8.871 8.871 0 0 1-.878.912 8.619 8.619 0 1 0 5.792-15.01z"
                            fill="#ed1c2e"
                            data-name="Path 955"
                            transform="translate(-182.725 -119.436)"
                          />
                          <g
                            id="Group_1434"
                            data-name="Group 1434"
                            transform="translate(7.945 17.409)"
                          >
                            <path
                              id="Path_956"
                              d="M366.194 251.741a.282.282 0 0 1 .287-.27.266.266 0 0 1 .27.27.281.281 0 0 1-.27.287.3.3 0 0 1-.287-.287zm.287.22a.21.21 0 0 0 .2-.22.2.2 0 0 0-.2-.2.209.209 0 0 0-.219.2.223.223 0 0 0 .219.22zm-.034-.1h-.067v-.237h.1c.033 0 .051 0 .068.017s.033.033.033.051c0 .034-.017.068-.051.068l.051.1h-.068l-.033-.085h-.034v.085zm0-.135h.067v-.051h-.067v.051z"
                              fill="#fff"
                              data-name="Path 956"
                              transform="translate(-343.233 -247.656)"
                            />
                            <path
                              id="Path_957"
                              d="M98.817 210.639h-1.08l.641-3.36-1.468 3.36h-.98l-.168-3.343-.641 3.343h-.98l.828-4.373h1.688l.084 2.7 1.183-2.7h1.736z"
                              fill="#fff"
                              data-name="Path 957"
                              transform="translate(-94.14 -206.266)"
                            />
                            <path
                              id="Path_958"
                              d="M273.993 210.592a2.742 2.742 0 0 1-.777.117c-.54 0-.845-.27-.845-.776a2.39 2.39 0 0 1 .034-.321l.067-.355.052-.27.489-2.718h1.063l-.151.81h.556l-.152.862h-.557l-.27 1.485a.82.82 0 0 0-.034.152c0 .186.118.27.372.27a1.226 1.226 0 0 0 .287-.034l-.135.777z"
                              fill="#fff"
                              data-name="Path 958"
                              transform="translate(-257.328 -206.27)"
                            />
                            <path
                              id="Path_959"
                              d="M298.364 215.535a.288.288 0 0 0-.118-.034c-.051-.017-.085-.017-.085-.034h-.118a1.11 1.11 0 0 0-.929.573l.1-.54h-.98l-.658 3.563h1.081c.388-2.179.54-2.567 1.063-2.567.034 0 .085.017.135.017l.118.033.389-1.012z"
                              fill="#fff"
                              data-name="Path 959"
                              transform="translate(-278.577 -214.69)"
                            />
                            <path
                              id="Path_960"
                              d="M236.771 216.271c0 .439.254.76.828.98.439.185.507.236.507.405 0 .219-.2.321-.625.321a3.48 3.48 0 0 1-.98-.152l-.135.827h.051l.185.05c.067 0 .168.017.287.017a4.419 4.419 0 0 0 .608.033c1.131 0 1.672-.371 1.672-1.181 0-.49-.22-.777-.777-1-.456-.169-.507-.22-.507-.388 0-.185.186-.287.54-.287a7.071 7.071 0 0 1 .776.05l.152-.81a6.481 6.481 0 0 0-.946-.067c-1.215 0-1.638.539-1.638 1.2z"
                              fill="#fff"
                              data-name="Path 960"
                              transform="translate(-224.362 -214.331)"
                            />
                            <path
                              id="Path_961"
                              d="M158.528 218.7h-.878l.017-.372a1.464 1.464 0 0 1-1.131.422.926.926 0 0 1-.979-.962c0-.862.691-1.368 1.891-1.368a4.164 4.164 0 0 1 .439.017.676.676 0 0 0 .034-.22c0-.236-.186-.321-.693-.321a4.536 4.536 0 0 0-.878.085l-.152.051-.1.016.152-.793a4.86 4.86 0 0 1 1.284-.186c.928 0 1.4.354 1.4 1.029a3.777 3.777 0 0 1-.084.71l-.22 1.266-.034.219-.034.186-.017.117-.017.1zm-.777-1.588a1.4 1.4 0 0 0-.236-.017c-.608 0-.912.186-.912.541a.345.345 0 0 0 .371.371.828.828 0 0 0 .777-.9z"
                              fill="#fff"
                              data-name="Path 961"
                              transform="translate(-150.373 -214.324)"
                            />
                            <path
                              id="Path_962"
                              d="M200.88 218.42a4.087 4.087 0 0 1-1.1.152c-1.2 0-1.823-.557-1.823-1.6a1.944 1.944 0 0 1 1.875-2.11 1.278 1.278 0 0 1 1.436 1.283 3.628 3.628 0 0 1-.118.878h-2.145v.118c0 .405.3.624.929.624a2.852 2.852 0 0 0 1.114-.219l-.169.878zm-.573-2.11v-.186c0-.287-.186-.456-.507-.456-.338 0-.574.236-.676.642z"
                              fill="#fff"
                              data-name="Path 962"
                              transform="translate(-189.197 -214.132)"
                            />
                            <path
                              id="Path_963"
                              d="M327.176 216.695a1.9 1.9 0 0 1-2.094 1.7 1.513 1.513 0 0 1-1.687-1.637 1.994 1.994 0 0 1 2.11-2.111 1.553 1.553 0 0 1 1.705 1.6 1.819 1.819 0 0 1-.034.44zm-1.114-.456c0-.371-.152-.708-.59-.708-.542 0-.879.641-.879 1.215 0 .473.237.794.608.794a1.047 1.047 0 0 0 .827-.861 1.864 1.864 0 0 0 .034-.44z"
                              fill="#fff"
                              data-name="Path 963"
                              transform="translate(-304.046 -213.943)"
                            />
                          </g>
                        </svg>
                      </Link>
                    </li>
                    &nbsp; &nbsp;
                    <li>
                      &nbsp;
                      <Link  to="/">
                        <svg
                          height={50}
                          viewBox="0 0 2212.7 2065.2"
                          width={50}
                          xmlns="http://www.w3.org/2000/svg"
                          style={{ marginRight: 5 }}
                        >
                          <circle
                            cx="1032.4"
                            cy="1032.4"
                            fill="#fff"
                            r="1032.4"
                          />
                          <path
                            d="m1032.4 2064.8c-191.9.3-380-53.1-543.1-154.2l691.6-1900c122.4 17.6 240.7 57.2 349 116.9 499.8 274.2 682.8 901.6 408.6 1401.5-181.4 330.8-528.8 536.2-906.1 535.8z"
                            fill="#6e7bf2"
                          />
                          <path
                            d="m682.2 1027.9c.3-17.4-1.5-34.8-5.3-51.7h-237.6v93.9h139.5c-5.3 33.3-24 62.9-51.7 82.1l-.5 3.1 75.1 58.2 5.2.5c47.7-44.1 75.3-109 75.3-186.1"
                            fill="#4285f4"
                          />
                          <path
                            d="m439.3 1275.3c68.3 0 125.7-22.5 167.6-61.3l-79.8-61.9c-25.9 17.4-56.6 26.2-87.7 25.3-65.6-.4-123.6-42.8-144-105.2l-3 .3-78.1 60.4-1 2.8c42.8 85.7 130.3 139.7 226 139.6"
                            fill="#34a853"
                          />
                          <path
                            d="m295.3 1072.3c-5.5-16.1-8.4-33-8.4-50.1.1-17 2.9-33.9 8.2-50.1l-.1-3.3-79.1-61.4-2.6 1.2c-36 71.5-36 155.7 0 227.2z"
                            fill="#fbbc05"
                          />
                          <path
                            d="m439.3 867.1c36.3-.6 71.3 12.9 97.8 37.7l71.4-69.7c-45.8-43-106.5-66.5-169.3-65.8-95.7 0-183.2 53.9-226.1 139.5l81.8 63.5c20.6-62.4 78.7-104.8 144.4-105.2"
                            fill="#eb4335"
                          />
                          <path
                            d="m1080.5 1050.4v183.9h-58.8v-454.3h155.9c37.5-.8 73.9 13.3 101 39.3 53.1 48.9 56.5 131.7 7.5 184.8-2.4 2.6-4.9 5.1-7.5 7.5-27.2 25.8-63.5 39.7-101 38.7zm0-214.5v158.6h98.5c21.8.7 42.9-7.8 58.1-23.5 30.7-29.5 31.6-78.2 2.1-108.9l-.6-.6c-.5-.5-1-1.1-1.5-1.5-15.1-16-36.2-24.7-58.1-24.1zm375.6 77.4c43.5 0 77.8 11.5 102.9 34.6 25.1 23 37.7 54.6 37.7 94.8v191.6h-56.2v-43.2h-2.5c-24.3 35.5-56.7 53.3-97.2 53.3-31.6 1-62.5-9.8-86.5-30.5a97.058 97.058 0 0 1 -34.8-76.1c-1-30 12.7-58.7 36.7-76.7 24.5-19 57.2-28.5 98.1-28.5 29.9-1.1 59.6 5.4 86.2 19v-13.4c.1-20-8.8-39-24.3-51.6-15.6-13.9-35.8-21.5-56.7-21.2-31.4-.7-60.9 14.9-77.9 41.2l-51.7-32.4c28.2-40.6 70.3-60.9 126.2-60.9zm-76.1 225.8c-.1 15.1 7.2 29.3 19.4 38.1 13 10.2 29.2 15.5 45.7 15.2 24.8 0 48.5-9.8 66.1-27.3 18.5-16.1 29.2-39.5 29.2-64.1-18.4-14.5-43.9-21.8-76.7-21.8-21.2-.8-42.2 5.2-59.7 17.1-14.7 9.4-23.7 25.4-24 42.8zm539.4-215.6-196.2 447.8h-60.7l72.8-156.7-129.1-291.2h63.9l93.3 223.3h1.3l90.7-223.3z"
                            fill="#fff"
                          />
                        </svg>
                      </Link>
                      <Link  to="/">
                        <svg
                          height={50}
                          width={50}
                          id="Layer_1"
                          data-name="Layer 1"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 122.88 122.88"
                          style={{ marginRight: 5 }}
                        >
                          <defs />
                          <title>phonepe</title>
                          <path
                            className="cls-1"
                            d="M75.56,1.66a61.42,61.42,0,1,0,45.66,73.9A61.42,61.42,0,0,0,75.56,1.66Z"
                            style={{ fill: "rgb(95, 37, 159)" }}
                          />
                          <path
                            className="cls-2"
                            d="M89.32,45.41A4.54,4.54,0,0,0,84.86,41H76.62L57.75,19.33a7,7,0,0,0-7.21-2.06L44,19.33a1.52,1.52,0,0,0-.69,2.4L63.92,41.29H32.7A1.63,1.63,0,0,0,31,43v3.43a4.54,4.54,0,0,0,4.46,4.46h4.81V67.37c0,12.35,6.52,19.56,17.5,19.56a23.66,23.66,0,0,0,9.61-1.72v11a5.42,5.42,0,0,0,5.49,5.49h4.8a2.22,2.22,0,0,0,2.06-2.06V50.56H87.6a1.63,1.63,0,0,0,1.72-1.72V45.41Zm-22,29.51a16.38,16.38,0,0,1-6.87,1.37c-5.49,0-8.23-2.74-8.23-8.92V50.9h15.1v24Z"
                            style={{ fill: "rgb(255, 255, 255)" }}
                          />
                        </svg>
                      </Link>
                      <Link  to="/">
                        
                        <img height={50} width={50} srcSet="images/paytm.webp" />
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            {/* Mega Footer End */}
            {/* Copyright Footer Start */}
            <div className="footer-copyright">
              <div className="row align-items-center">
                <div className="col-lg-4">
                  {/* Footer Copyright Content Start */}
                  <div className="footer-copyright-text">
                    <p>© Guru Software ,2024</p>
                  </div>
                  {/* Footer Copyright Content End */}
                </div>
                <div className="col-lg-4 text-center mb-3">
      <figure>
        <Link to="https://www.dmca.com/Protection/Status.aspx?ID=9077c2bc-21d9-41c2-b03f-e666035ba777&amp;refurl=https://gurusoftware.in/" target="_blank" rel="noopener noreferrer">
          <img src="images/dmca-badge.webp" alt="DMCA Badge" style={{ width: 'auto', height: '35px' }} />
        </Link>
      </figure>
    </div>
                <div className="col-lg-4">
                  {/* Footer Policy Links Start */}
                  <div className="footer-policy-links">
                    <ul>
                      <li>
                        <Link 
                          to="/ https://www.facebook.com/gurusoftware.in/"
                          target="_blank"
                        >
                          <img
                            src="images/facebook.webp"
                            alt="facebook"
                            style={{ height: 35, width: 35, marginRight: 8 }}
                          />
                        </Link>
                      </li>
                      <li>
                        <Link 
                          to="https://in.linkedin.com/company/gurusoftware.in"
                          target="_blank"
                        >
                          <img
                            src="images/linkedin-1.webp"
                            alt="linkedin"
                            style={{ height: 35, width: 35, marginRight: 8 }}
                          />
                        </Link>
                      </li>
                      <li>
                        <Link 
                          to="https://www.instagram.com/gurusoftware.in/"
                          target="_blank"
                        >
                          <img
                            src="images/instagram.webp"
                            alt="instagram"
                            style={{ height: 35, width: 35, marginRight: 8 }}
                          />
                        </Link>
                      </li>
                      <li>
                        <Link 
                          to="https://www.youtube.com/channel/UCyjyKVHPLQBNLKGRwzXTSgA"
                          target="_blank"
                        >
                          <img
                            src="images/youtube.webp"
                            alt="youtube"
                            style={{ height: 35, width: 35, marginRight: 8 }}
                          />
                        </Link>
                      </li>
                      {/* <li class="highlighted"><Link  to="#top">go to top</Link></li> */}
                    </ul>
                  </div>
                  {/* Footer Policy Links End */}
                </div>
              </div>
            </div>
            {/* Copyright Footer End */}
          </div>
        </div>
      </div>
    </footer>
    {/* Footer End */}
 
  </>
  
  )
}

export default Footer
