import React from "react";
import "./my.css";
import { Link } from 'react-router-dom'; 

import { Helmet } from 'react-helmet-async';

const Portfolio = () => {

  return (
    <div>
       <style
    dangerouslySetInnerHTML={{
      __html:
        "\n.serviceitem {\n    position: relative;\n    padding-bottom: 20px;\n}\n\n.serviceitem .android-btn,\n.serviceitem .web-btn {\n    display: none; \n    position: absolute; \n    top: 100px; \n  \n}\n\n.serviceitem .web-btn {\n    right: 35px; \n}\n\n.serviceitem .android-btn {\n    left: 35px;\n}\n\n.serviceitem:hover .android-btn,\n.serviceitem:hover .web-btn {\n    display: inline-block; \n}\n\n"
    }}
  />
      <div>
      <Helmet>
  <title>Portfolio | Showcase of Our IT Projects - Guru Software</title>
  <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
  <meta name="author" content="Guru Software" />
  <meta name="subject" content="IT Projects Portfolio" />
  <meta name="owner" content="Guru Software" />
  <meta name="coverage" content="Global" />
  <meta name="Geography" content="Guru Software, Ahmedabad, Gujarat, India" />
  <meta name="Language" content="English" />
  <meta name="distribution" content="Global" />
  <meta name="audience" content="All" />
  <meta name="revisit-after" content="3 days" />
  <meta name="Robots" content="INDEX,FOLLOW" />
  <meta name="country" content="Global" />
  <meta name="description" content="Explore Guru Software's portfolio to see our innovative IT projects and solutions. Discover how we have successfully delivered impactful results for diverse clients worldwide." />
  <meta property="og:title" content="Portfolio | Showcase of Our IT Projects - Guru Software" />
  <meta property="og:description" content="Explore Guru Software's portfolio to see our innovative IT projects and solutions. Discover how we have successfully delivered impactful results for diverse clients worldwide." />
  <meta property="og:type" content="website" />
  <meta property="og:url" content="https://www.gurusoftware.in/Portfolio" />
  <meta property="og:image" content="https://www.gurusoftware.in/images/og.png" />
  <meta name="twitter:card" content="summary_large_image" />
  <meta name="twitter:description" content="Explore Guru Software's portfolio to see our innovative IT projects and solutions. Discover how we have successfully delivered impactful results for diverse clients worldwide." />
  <meta name="twitter:title" content="Portfolio | Showcase of Our IT Projects - Guru Software" />
</Helmet>


      <div className="page-header page-header-my  " style={{padding: '50px 0 '}}>
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                {/* Page Header Box Start */}
                <div className="page-header-box-my page-header-box">
                  <h1 className="text-anime-style-3">Portfolio</h1>
                  <nav className="wow fadeInUp" data-wow-delay="0.25s">
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item"><Link to="/">home</Link></li>
                      <li className="breadcrumb-item active" aria-current="page">Portfolio</li>
                    </ol>
                  </nav>
                </div>
                {/* Page Header Box End */}
              </div>
            </div>
          </div>
        </div>
        
    <div
  className="case-study-outer pt-100 pb-100 sm-pt-100 bg-combo-2 pos-r"
  id="case-study-start"
>

  <div className="container">
  <div className="row section-row align-items-center">
          <div className="col-lg-12 col-md-12">
            {/* Section Title Start */}
            <div className="section-title" style={{paddingTop: "80px"}} > 
              <h3
                className="wow fadeInUp"
                style={{ visibility: "visible", animationName: "fadeInUp" }}
              >
Our Project
              </h3>
              <h2
                className="text-anime-style-3"
                style={{ perspective: 400, width: "100%" }}
              >
               Discover Our Project Showcase</h2>
            </div>
            {/* Section Title End */}
          </div>
        </div>
    <div className="row">
      <div className="col-md-6 col-lg-4 mb-40">
        <div 
          className="case-box"
          target="_blank"
        >
          <img
            src="images/manglik_port.webp"
            className="img-fluid"
          />
          <div className="case-txt">
            <h6>
            Manglik Gold 


            </h6>
          </div>
        </div>
      </div>
      <div className="col-md-6 col-lg-4 mb-40">
       <div
          className="case-box"
         
        >
          <img
            src="images/spintech_port.webp"
            className="img-fluid"
          />
          <div className="case-txt">
            <h6>
           Spintech Bearing

            </h6>
          </div>
        </div>
      </div>


      <div className="col-md-6 col-lg-4 mb-40">
       <div
          className="case-box"
         >
          <img
            src="images/goldking_port.webp"
            className="img-fluid"
          />
          <div className="case-txt">
            <h6>
            Gold King Scale 

            </h6>
          </div>
        </div>
      </div>
    

   
    </div>
    
    <div className="row">
      <div className="col-md-6 col-lg-4 mb-40">
       <div
          className="case-box"
        >
          <img
            src="images/svft_port.webp"
            className="img-fluid"
          />
          <div className="case-txt">
            <h6>
            Shree Vishwakarma Fab Tech 
            </h6>
          </div>
        </div>
      </div>








      <div className="col-md-6 col-lg-4 mb-30">
       <div
          className="case-box"
        
        >
          <img
            src="images/bm_port.webp"
            className="img-fluid"
          />
          <div className="case-txt">
            <h6>
            BM Graphics
</h6>
          </div>
        </div>
      </div>
  
      <div className="col-md-6 col-lg-4 mb-30">
       <div
          className="case-box"
        >
          <img
            src="images/ibs_prot.webp"
            className="img-fluid"
          />
          <div className="case-txt">
            <h6>
            IBS Bearings 
</h6>
          </div>
        </div>
      </div>
   
    </div>
    
    <div className="row">
      <div className="col-md-6 col-lg-4 mb-40">
       <div
          className="case-box"
        >
          <img
            src="images/bluemaxie_pro.webp"
            className="img-fluid"
          />
          <div className="case-txt">
            <h6>
            Bluemaxie
            </h6>
          </div>
        </div>
      </div>
      <div className="col-md-6 col-lg-4 mb-30">
       <div
          className="case-box"
        
        >
          <img
            src="images/khodiyarfabricationworkspro.webp"
            className="img-fluid"
          />
          <div className="case-txt">
            <h6>
            Khodiyar Fabrication</h6>
          </div>
        </div>
      </div>
  
      <div className="col-md-6 col-lg-4 mb-30">
       <div
          className="case-box"
        >
          <img
            src="images/weighmall-pro.webp"
            className="img-fluid"
          />
          <div className="case-txt">
            <h6>
            Weighmall
</h6>
          </div>
        </div>
      </div>
   
    </div>
    
    <div className="row">
      <div className="col-md-6 col-lg-4 mb-40">
       <div
          className="case-box"
        >
          <img
            src="images/supersteelind_pro.webp"
            className="img-fluid"
          />
          <div className="case-txt">
            <h6>
            Super Steelind
            </h6>
          </div>
        </div>
      </div>
      <div className="col-md-6 col-lg-4 mb-30">
       <div
          className="case-box"
        
        >
          <img
            src="images/bhaktidesign_pro.webp"
            className="img-fluid"
          />
          <div className="case-txt">
            <h6>
            Bhakti Design</h6>
          </div>
        </div>
      </div>
  
      <div className="col-md-6 col-lg-4 mb-30">
       <div
          className="case-box"
        >
          <img
            src="images/anshhelth.png"
            className="img-fluid"
          />
          <div className="case-txt">
            <h6>
            Ansh Healthcare
</h6>
          </div>
        </div>
      </div>
      <div className="col-md-6 col-lg-4 mb-30">
       <div
          className="case-box"
        >
          <img
            src="images/indowindengineers.webp"
            className="img-fluid"
          />
          <div className="case-txt">
            <h6>
            Indowindengineers
</h6>
          </div>
        </div>
      </div>
      <div className="col-md-6 col-lg-4 mb-30">
       <div
          className="case-box"
        >
          <img
            src="images/gizmoind.webp"
            className="img-fluid"
          />
          <div className="case-txt">
            <h6>
            Gizmoind
</h6>
          </div>
        </div>
      </div>
      <div className="col-md-6 col-lg-4 mb-30">
       <div
          className="case-box"
        >
          <img
            src="images/pushpakpolymers.webp"
            className="img-fluid"
          />
          <div className="case-txt">
            <h6>
            Pushpak Polymers
</h6>
          </div>
        </div>
      </div>
      <div className="col-md-6 col-lg-4 mb-30">
       <div
          className="case-box"
        >
          <img
            src="images/rktexlab.webp"
            className="img-fluid"
          />
          <div className="case-txt">
            <h6>
            R.K. Tex-Lab 
</h6>
          </div>
        </div>
      </div>



      <div className="col-md-6 col-lg-4 mb-30">
       <div
          className="case-box"
        >
          <img
            src="images/PRO1.webp"
            className="img-fluid"
          />
          <div className="case-txt">
            <h6>
            Balaji Bearing</h6>
          </div>
        </div>
      </div>
      <div className="col-md-6 col-lg-4 mb-30">
       <div
          className="case-box"
        >
          <img
            src="images/tr.webp"
            className="img-fluid"
          />
          <div className="case-txt">
            <h6>
            TradeOX
</h6>
          </div>
        </div>
      </div>
      <div className="col-md-6 col-lg-4 mb-30">
       <div
          className="case-box"
        >
          <img
            src="images/PRO3.webp"
            className="img-fluid"
          />
          <div className="case-txt">
            <h6>
            llmecenterprise
</h6>
          </div>
        </div>
      </div>

      <div className="col-md-6 col-lg-4 mb-30">
       <div
          className="case-box"
        >
          <img
            src="images/proo.png"
            className="img-fluid"
          />
          <div className="case-txt">
            <h6>
            Nested Pixels
</h6>
          </div>
        </div>
      </div>

      <div className="col-md-6 col-lg-4 mb-30">
       <div
          className="case-box"
        >
          <img
            src="images/uniquebearing.webp"
            className="img-fluid"
          />
          <div className="case-txt">
            <h6>
            Unique bearing
</h6>
          </div>
        </div>
      </div>
      <div className="col-md-6 col-lg-4 mb-30">
       <div
          className="case-box"
        >
          <img
            src="images/businesssaarthi.webp"
            className="img-fluid"
          />
          <div className="case-txt">
            <h6>
            Businesssaarthi</h6>
          </div>
        </div>
      </div>
      <div className="col-md-6 col-lg-4 mb-30">
       <div
          className="case-box"
        >
          <img
            src="images/nisha.webp"
            className="img-fluid"
          />
          <div className="case-txt">
            <h6>
            Nisha Artistry </h6>
          </div>
        </div>
      </div>
      <div className="col-md-6 col-lg-4 mb-30">
       <div
          className="case-box"
        >
          <img
            src="images/niaps.webp"
            className="img-fluid"
          />
          <div className="case-txt">
            <h6>
            Nidhi Interior & Paint Solution</h6>
          </div>
        </div>
      </div>
      <div className="col-md-6 col-lg-4 mb-30">
       <div
          className="case-box"
        >
          <img
            src="images/realweight.webp"
            className="img-fluid"
          />
          <div className="case-txt">
            <h6>
            Realweight </h6>
          </div>
        </div>
      </div>
      <div className="col-md-6 col-lg-4 mb-30">
       <div
          className="case-box"
        >
          <img
            src="images/bharhma.webp"
            className="img-fluid"
          />
          <div className="case-txt">
            <h6>
            Brahma Samajh Seva Trust </h6>
          </div>
        </div>
      </div>
      <div className="col-md-6 col-lg-4 mb-30">
       <div
          className="case-box"
        >
          <img
            src="images/hind.webp"
            className="img-fluid"
          />
          <div className="case-txt">
            <h6>
            Hind Bearing </h6>
          </div>
        </div>
      </div>
      <div className="col-md-6 col-lg-4 mb-30">
       <div
          className="case-box"
        >
          <img
            src="images/vedgraphics.webp"
            className="img-fluid"
          />
          <div className="case-txt">
            <h6>
            Ved Graphics </h6>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

      </div>
    </div>
  );
};

export default Portfolio;
