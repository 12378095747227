import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import "./my.css";

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const closeMenu = () => {
    setIsOpen(false);
  };

  return (
    <>
      {/* <div className="preloader" style={{ backgroundColor: "#f7f8fd" }}>
        <div className="loading-container">
          <div className="loading" style={{ color: "#214653" }} />
          <div id="loading-icon">
            <img src="images/favicon1.webp" alt="" />
          </div>
        </div>
      </div> */}
      {/* <div id="magic-cursor">
        <div id="ball" />
      </div> */}

      <header className="main-header" style={{ height: "90px" }}>
        <div className="header-sticky">
          <nav className="navbar navbar-expand-lg">
            <div className="container">
              {/* Logo Start */}
              <Link className="navbar-brand" to="/" onClick={closeMenu}>
                <img
                  src="images/logo.webp"
                  style={{ width: "auto", height: "50px" }}
                  alt="Logo"
                />
              </Link>
              {/* Logo End */}
              {/* Main Menu Start */}
              <div className="collapse navbar-collapse main-menu">
                <div className="nav-menu-wrapper">
                  <ul className="navbar-nav mr-auto" id="menu">
                    <li className="nav-item ">
                      <Link className="nav-link" to="/" onClick={closeMenu}>
                        HOME
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link" to="/About_Us" onClick={closeMenu}>
                        ABOUT US
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link" to="/Services" onClick={closeMenu}>
                        OUR SERVICES
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link" to="/Portfolio" onClick={closeMenu}>
                        PORTFOLIO
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link" to="/Blog" onClick={closeMenu}>
                        BLOG
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link" to="/Contact_Us" onClick={closeMenu}>
                        CONTACT US
                      </Link>
                    </li>
                  </ul>
                </div>
                {/* Let’s Start Button Start */}
                <div className="header-btn d-inline-flex">
                  <Link to="/Requirement" className="btn-default-test" onClick={closeMenu}>
                    Get Quote
                  </Link>
                </div>
                {/* Let’s Start Button End */}
              </div>
              {/* Main Menu End */}
              <div className='navbar-toggle '>
                <Link
                  to="#"
                  aria-haspopup="true"
                  role="button"
                  tabIndex={0}
                  className={isOpen ? "slicknav_btn slicknav_open" : "navbar-toggle slicknav_btn slicknav_collapsed"}
                  style={{ outline: "none" }}
                  onClick={handleToggle}
                >
                  <span className="slicknav_menutxt" />
                  <span className="slicknav_icon slicknav_no-text">
                    <span className="slicknav_icon-bar" />
                    <span className="slicknav_icon-bar" />
                    <span className="slicknav_icon-bar" />
                  </span>
                </Link>
              </div>
            </div>
          </nav>
          <div className="responsive-menu">
            <div className="slicknav_menu">
              <ul className={isOpen ? "slicknav_nav" : "slicknav_nav slicknav_hidden"} aria-hidden={!isOpen} role="menu" style={{ display: isOpen ? "" : "none" }}>
                <li className="nav-item ">
                  <Link
                    className="nav-link"
                    to="/"
                    role="menuitem"
                    tabIndex={-1}
                    onClick={closeMenu}
                  >
                    HOME
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className="nav-link"
                    to="/About_Us"
                    role="menuitem"
                    tabIndex={-1}
                    onClick={closeMenu}
                  >
                    ABOUT US
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className="nav-link"
                    to="/Services"
                    role="menuitem"
                    tabIndex={-1}
                    onClick={closeMenu}
                  >
                    OUR SERVICES
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className="nav-link"
                    to="/Portfolio"
                    role="menuitem"
                    tabIndex={-1}
                    onClick={closeMenu}
                  >
                    PORTFOLIO
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/Blog" role="menuitem" tabIndex={-1} onClick={closeMenu}>
                    BLOG
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className="nav-link"
                    to="/Contact_Us"
                    role="menuitem"
                    tabIndex={-1}
                    onClick={closeMenu}
                  >
                    CONTACT US
                  </Link>
                </li>
                <div className="header-btn d-inline-flex"  >
                  <Link to="/Requirement"style={{border: '2px solid #f7f8fd'}} className="btn-default-test" onClick={closeMenu}>
                    Get Quote
                  </Link>
                </div>
              </ul>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;
