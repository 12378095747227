import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';


const About = () => {
  return (
    <div>
        <div>
   
      <Helmet>
      <title>About Us - Guru Software</title>

  <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
  <meta name="author" content="Guru Software" />
  <meta name="subject" content="IT Company" />
  <meta name="owner" content="Guru Software" />
  <meta name="coverage" content="India" />
  <meta name="Geography" content="Guru Software, Ahmedabad, Gujarat, India" />
  <meta name="Language" content="English" />
  <meta name="distribution" content="Global" />
  <meta name="audience" content="All" />
  <meta name="revisit-after" content="3 days" />
  <meta name="Robots" content="INDEX,FOLLOW" />
  <meta name="country" content="India" />
  <meta name="description" content="Learn more about Guru Software, a leading IT solutions provider in Ahmedabad, Gujarat, India. Discover our mission, vision, and the team behind our success." />
  <meta property="og:title" content="About Us - Guru Software" />
  <meta property="og:description" content="Discover Guru Software's mission, vision, and team. Learn about our journey and achievements in the IT industry." />
  <meta property="og:type" content="website" />
  <meta property="og:url" content="https://www.gurusoftware.in/about.html" />
  <meta property="og:image" content="https://www.gurusoftware.in/images/og.png" />
  <meta name="twitter:card" content="summary_large_image" />
  <meta name="twitter:description" content="Discover Guru Software's mission, vision, and team. Learn about our journey and achievements in the IT industry." />
  <meta name="twitter:title" content="About Us - Guru Software" />
</Helmet>

    
        <div className="page-header" style={{padding: '50px 0 '}}>
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                {/* Page Header Box Start */}
                <div className="page-header-box-my page-header-box">
                  <h1 className="text-anime-style-3">About Us</h1>
                  <nav className="wow fadeInUp" data-wow-delay="0.25s">
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item"><Link  to="/">home</Link></li>
                      <li className="breadcrumb-item active" aria-current="page">about us</li>
                    </ol>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
       
        <div className="about-us">
          <div className="container">
            <div className="row align-items-center">
            <div className="col-lg-6">
                <div className="page-about-image">
                <div className="about-img-1">
    <img src="images/myab-1.png" alt="" style={{ width: '507px', height: 'auto' }} />
</div>

                  <div className="about-img-2">
                    {/* <figure className="image-anime reveal"> */}
                      <img src="images/myab-2.png" alt="" />
                    {/* </figure> */}
                  </div>
                  <div className="work-experience">
                    <div className="work-experience-icon">
                      <img src="images/icon-work-experience.webp" alt="" />
                    </div>
                    <div className="work-experience-content">
                      <h3><span className="counter">30</span>+</h3>
                      <p>Work Experience</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                {/* About Us Content Start */}
                <h2 className="text-anime-style-3">Transform your vision into reality with our expertise</h2>
                <br />
                <div className="about-content">
                  <p className="wow fadeInUp" data-wow-delay="0.25s">For over a decade, we have been delivering outstanding services and solutions, consistently achieving complete customer satisfaction. Our extensive clientele base speaks volumes about our commitment to excellence. Once you've experienced working with us, it becomes clear that finding such quality and professionalism elsewhere is nearly impossible.</p>
                  <p className="wow fadeInUp" data-wow-delay="0.5s">Our unwavering dedication and expertise have established us as a highly reputable entity in the web development sector. Our guiding principle is inclusive development paired with unwavering customer satisfaction. Our ultimate goal is to achieve all-encompassing growth, becoming a paramount service development entity capable of catering to every customer's diverse needs.</p>
                  <ul className="wow fadeInUp" data-wow-delay="1s">
                    <li>Website Development </li>
                    <li>Mobile App Development</li>
                    <li>Customized Software</li>
                    <li>Digital Marketing </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* About Section End */}
        <div className="why-choose-us">
          <div className="container">
            <div className="row section-row align-items-center">
              <div className="col-lg-8 col-md-12">
                {/* Section Title Start */}
                <div className="section-title">
                  <h3 className="wow fadeInUp" style={{visibility: 'visible', animationName: 'fadeInUp'}}>About Us
                  </h3>
                  <h2 className="text-anime-style-3" style={{perspective: '400px'}}>
                    Our Project Planning Approach
                  </h2>
                </div>
                {/* Section Title End */}
              </div>
            </div>
            <div className="row">
              <div className="col-lg-4 col-md-6">
                {/* Why Choose Item Start */}
                <div className="why-choose-us-item wow fadeInUp" data-wow-delay="0.25s" style={{visibility: 'visible', animationDelay: '0.25s', animationName: 'fadeInUp'}}>
                  <div className="icon-box">
                    <img src="images/implementation.webp" style={{width: 'auto', height: '81px'}} alt="" />
                  </div>
                  <h3>Implementing non-disclosure agreements (NDAs)</h3>
                  <p>Ensuring confidentiality with robust non-disclosure agreements (NDAs) safeguards sensitive information for our clients' peace of mind.</p>
                </div>
                {/* Why Choose Item End */}
              </div>
              <div className="col-lg-4 col-md-6">
                {/* Why Choose Item Start */}
                <div className="why-choose-us-item wow fadeInUp" data-wow-delay="0.5s" style={{visibility: 'visible', animationDelay: '0.5s', animationName: 'fadeInUp'}}>
                  <div className="icon-box">
                    <img src="images/technical-scope.webp" style={{width: 'auto', height: '81px'}} alt="" />
                  </div>
                  <h3>Defining the technical scope
                  </h3>
                  <p>Clear technical scope delineates project objectives, guiding precise implementation for optimal results and client satisfaction</p>
                </div>
                {/* Why Choose Item End */}
              </div>
              <div className="col-lg-4">
                {/* Why Choose Item Start */}
                <div className="why-choose-us-item wow fadeInUp" data-wow-delay="0.75s" style={{visibility: 'visible', animationDelay: '0.75s', animationName: 'fadeInUp'}}>
                  <div className="icon-box">
                    <img src="images/process.webp" style={{width: 'auto', height: '81px'}} alt="" />
                  </div>
                  <h3>Establishing processes and documentation
                  </h3>
                  <p>Methodical process establishment with thorough documentation ensures efficiency, accountability, and seamless project execution for client satisfaction</p>
                </div>
              </div>
              <div className="col-lg-4">
                {/* Why Choose Item Start */}
                <div className="why-choose-us-item wow fadeInUp" data-wow-delay="1.5s" style={{visibility: 'visible', animationDelay: '1.5s', animationName: 'fadeInUp'}}>
                  <div className="icon-box">
                    <img src="images/responsibility.webp" style={{width: 'auto', height: '81px'}} alt="" />
                  </div>
                  <h3>Allocating Responsibilities
                  </h3>
                  <p>Assigning clear responsibilities streamlines workflow, fostering accountability and teamwork for successful project delivery and client satisfaction</p>
                </div>
                {/* Why Choose Item End */}
              </div>
              <div className="col-lg-4">
                {/* Why Choose Item Start */}
                <div className="why-choose-us-item wow fadeInUp" data-wow-delay="1s" style={{visibility: 'visible', animationDelay: '1s', animationName: 'fadeInUp'}}>
                  <div className="icon-box">
                    <img src="images/Schedule.webp" style={{width: 'auto', height: '81px'}} alt="" />
                  </div>
                  <h3>Creating a schedule </h3>
                  <p>Developing a comprehensive schedule ensures timely delivery, enabling efficient resource management and meeting client expectations effectively</p>
                </div>
                {/* Why Choose Item End */}
              </div>
              <div className="col-lg-4">
                {/* Why Choose Item Start */}
                <div className="why-choose-us-item wow fadeInUp" data-wow-delay="1.25s" style={{visibility: 'visible', animationDelay: '1.25s', animationName: 'fadeInUp'}}>
                  <div className="icon-box">
                    <img src="images/report.webp" style={{width: 'auto', height: '81px'}} alt="" />
                  </div>
                  <h3>Providing Regular Reporting
                  </h3>
                  <p>Regular reporting ensures transparency, tracking progress, addressing concerns promptly, and fostering trust with clients for project success</p>
                </div>
                {/* Why Choose Item End */}
              </div>
            </div>
          </div>
        </div>
        <div className="our-work">
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                {/* Works Item Start */}
                <div className="works-item wow fadeInUp" data-wow-delay="0.25s" style={{visibility: 'visible', animationDelay: '0.25s', animationName: 'fadeInUp'}}>
                  <div className="works-content">
                    <div className="icon-box">
                      <img src="images/vision-1.webp" style={{width: 'auto', height: '81px'}} alt="" />
                    </div>
                    <h3 style={{paddingTop: "10px", paddingBottom: "10px" }} >Our Vision
                    </h3>
                    <p>Our vision is to lead the IT industry and give the present generation a better and technorich future by providing exceptional services and effective web and software solutions.</p>                      </div>
                </div>
                {/* Works Item End */}
              </div>
              <div className="col-md-6">
                {/* Works Item Start */}
                <div className="works-item wow fadeInUp" data-wow-delay="0.5s" style={{visibility: 'visible', animationDelay: '0.5s', animationName: 'fadeInUp'}}>
                  <div className="works-content">
                    <div className="icon-box">
                      <img src="images/mission-1.webp" style={{width: 'auto', height: '81px'}} alt="" />
                    </div>
                    <h3 style={{paddingTop: "10px", paddingBottom: "10px" }}>Our Mission</h3>
                    <p>Our mission is crystal clear: we aim to deliver cutting-edge software solutions that embody excellence and align with enterprise compliance standards.</p>                      </div>
                </div>
                {/* Works Item End */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default About
