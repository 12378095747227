import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import axios from 'axios';
import ReCAPTCHA from 'react-google-recaptcha';

const Contact = () => {
  const [formData, setFormData] = useState({
    fname: '',
    lname: '',
    phone: '',
    email: '',
    subject: '',
    msg: '',
  });

  const [recaptchaToken, setRecaptchaToken] = useState('');
  const [message, setMessage] = useState('');
  const [messageType, setMessageType] = useState(''); // 'success' or 'error'

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleRecaptcha = (token) => {
    // console.log('reCAPTCHA Token:', token);
    setRecaptchaToken(token);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const { fname, lname, phone, email, subject, msg } = formData;

    const formDataToSend = {
      fname,
      lname,
      phone,
      email,
      subject,
      msg,
      recaptchaToken
    };

    try {
      const response = await fetch('https://www.hum.ujn.mybluehostin.me/gurusoftwareapi/Contactdata.php', { 
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formDataToSend),
      });

      const result = await response.json();
      if (result.status === 'success') {
        setMessage('Message sent successfully!');
        setMessageType('success'); // Set message type to success
        setFormData({
          fname: '',
          lname: '',
          phone: '',
          email: '',
          subject: '',
          msg: '',
        });
        setRecaptchaToken('');

        // Clear message after 5 seconds
        setTimeout(() => {
          setMessage('');
        }, 5000);
      } else {
        setMessage('Failed to send message: ' + result.message);
        setMessageType('error'); // Set message type to error
      }
    } catch (error) {
      console.error('Error:', error);
      setMessage('Failed to send message: ' + error.message);
      setMessageType('error'); // Set message type to error
    }
  };

  return (
    <div>
      <Helmet>
        <title>Contact Us | Get in Touch with Guru Software</title>
        <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
        <meta name="author" content="Guru Software" />
        <meta name="description" content="Contact Guru Software to inquire about our IT solutions or discuss your project needs. We are here to assist you with any questions or collaborations." />
        <meta property="og:title" content="Contact Us | Get in Touch with Guru Software" />
        <meta property="og:description" content="Contact Guru Software to inquire about our IT solutions or discuss your project needs. We are here to assist you with any questions or collaborations." />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.gurusoftware.in/Contact_Us" />
        <meta property="og:image" content="https://www.gurusoftware.in/images/og.png" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:description" content="Contact Guru Software to inquire about our IT solutions or discuss your project needs. We are here to assist you with any questions or collaborations." />
        <meta name="twitter:title" content="Contact Us | Get in Touch with Guru Software" />
        <script src="https://www.google.com/recaptcha/api.js" async defer></script>
      </Helmet>

      <div className="page-header page-header-my" style={{padding: '50px 0'}}>
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="page-header-box-my page-header-box">
                <h1 className="text-anime-style-3">Contact Us</h1>
                <nav className="wow fadeInUp" data-wow-delay="0.25s">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item"><a href="/">Home</a></li>
                    <li className="breadcrumb-item active" aria-current="page">Contact Us</li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="contact-information">
        <div className="container">
          <div className="row">
            <div className="col-md-4">
              <div className="contact-item wow fadeInUp" data-wow-delay="0.25s">
                <div className="contact-content">
                  <div className="contact-content-title">
                    <h2>Address</h2>
                    <a href="#"><img src="images/icon-location.webp" alt="Location" /></a>
                  </div>
                  <p>510, Kasturi Pride, OPP. Torrent Power, SP Ring Road, Nikol, Ahmedabad, Gujarat 382350.</p>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="contact-item wow fadeInUp" data-wow-delay="0.5s">
                <div className="contact-content">
                  <div className="contact-content-title">
                    <h2>Call Now</h2>
                    <a href="tel:+919409354811"><img src="images/icon-phone.webp" alt="Phone" /></a>
                  </div>
                  <p><a href="tel:+919409354811" style={{color: '#9c9fa6'}}>+91 9409 354 811</a></p>
                  <p><a href="tel:+918000055915" style={{color: '#9c9fa6'}}>+91 8000 055 915</a></p>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="contact-item wow fadeInUp" data-wow-delay="0.75s">
                <div className="contact-content">
                  <div className="contact-content-title">
                    <h2>Email Us</h2>
                    <a href="mailto:contact@gurusoftware.in"><img src="images/icon-mail.webp" alt="Email" /></a>
                  </div>
                  <p><a href="mailto:contact@gurusoftware.in" style={{color: '#9c9fa6'}}>contact@gurusoftware.in</a></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="contact-us">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <div className="contact-details">
                <div className="section-title">
                  <h3 className="wow fadeInUp">Contact Us</h3>
                  <h2 className="text-anime-style-3">Have Any Question?</h2>
                </div>
                <div className="contact-detail-body">
                  <p className="wow fadeInUp" data-wow-delay="0.25s">Let's Connect and Transform Your IT Needs into Solutions</p>
                  <h3 className="wow fadeInUp" data-wow-delay="0.5s">Follow Us:</h3>
                  <ul className="wow fadeInUp" data-wow-delay="0.75s">
                    <li><a href="#"><i className="fa-brands fa-facebook-f" /></a></li>
                    <li><a href="https://www.instagram.com/gurusoftware.in/"><i className="fa-brands fa-instagram" /></a></li>
                    <li><a href="https://in.linkedin.com/company/gurusoftware.in?_l=en_US"><i className="fa-brands fa-linkedin-in" /></a></li>
                    <li><a href="https://www.youtube.com/channel/UCyjyKVHPLQBNLKGRwzXTSgA"><i className="fa-brands fa-twitter" /></a></li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="contact-form-box wow fadeInUp" data-wow-delay="0.5s">
                <div className="contact-form">
                  <form onSubmit={handleSubmit}>
                    <div className="row">
                      <div className="form-group col-md-6 mb-4">
                        <input type="text" name="fname" className="form-control" id="fname" placeholder="First Name" value={formData.fname} onChange={handleChange} required />
                      </div>
                      <div className="form-group col-md-6 mb-4">
                        <input type="text" name="lname" className="form-control" id="lname" placeholder="Last Name" value={formData.lname} onChange={handleChange} required />
                      </div>
                      <div className="form-group col-md-6 mb-4">
  <input
    type="tel"
    name="phone"
    className="form-control"
    id="phone"
    placeholder="Phone Number"
    value={formData.phone}
    onChange={handleChange}
    required
    maxLength="10"
  />
</div>

                      <div className="form-group col-md-6 mb-4">
                        <input type="email" name="email" className="form-control" id="email" placeholder="Email Address" value={formData.email} onChange={handleChange} required />
                      </div>
                      <div className="form-group col-md-12 mb-4">
                        <input type="text" name="subject" className="form-control" id="subject" placeholder="Subject" value={formData.subject} onChange={handleChange} required />
                      </div>
                      <div className="form-group col-md-12 mb-4">
                        <textarea name="msg" className="form-control" id="msg" placeholder="Your Message" rows="5" value={formData.msg} onChange={handleChange} required></textarea>
                      </div>
                      <div className="form-group col-md-12 mb-4">
                        <ReCAPTCHA
                          sitekey="6LfqGJ8gAAAAAOGYrU5mnv6cZxePqeJ_u85A3t5L"
                          onChange={handleRecaptcha}
                        />
                      </div>
                      {message && (
                        <p
                          className="form-message"
                          style={{ color: messageType === 'success' ? 'green' : 'red' }}
                        >
                          {message}
                        </p>
                      )}
                      <div className=" col-md-12 ">
                        <button type="submit" className="btn-default">Send Message</button>
                      </div>
                     
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="google-map">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3718.9899439359515!2d72.59484871560925!3d23.05426028471505!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x395e847b56efab17%3A0x12dd84c072407a5!2s510%2C%20Kasturi%20Pride%2C%20OPP.%20Torrent%20Power%2C%20SP%20Ring%20Road%2C%20Nikol%2C%20Ahmedabad%2C%20Gujarat%20382350!5e0!3m2!1sen!2sin!4v1679025671980!5m2!1sen!2sin"
          width="100%"
          height="450"
          style={{ border: 0 }}
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
          title="Google Maps Location"
        />
      </div>
    </div>
  );
};

export default Contact;
