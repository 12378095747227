import React, { useState } from "react";
import axios from "axios";
import ReCAPTCHA from "react-google-recaptcha";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

const countryCodeMapping = {
  UK: "+44",
  USA: "+1",
  Algeria: "+213",
  Andorra: "+376",
  Angola: "+244",
  Anguilla: "+1-264",
  "Antigua & Barbuda": "+1-268",
  Argentina: "+54",
  Armenia: "+374",
  Aruba: "+297",
  Australia: "+61",
  Austria: "+43",
  Azerbaijan: "+994",
  Bahamas: "+1-242",
  Bahrain: "+973",
  Bangladesh: "+880",
  Barbados: "+1-246",
  Belarus: "+375",
  Belgium: "+32",
  Belize: "+501",
  Benin: "+229",
  Bermuda: "+1-441",
  Bhutan: "+975",
  Bolivia: "+591",
  "Bosnia Herzegovina": "+387",
  Botswana: "+267",
  Brazil: "+55",
  Brunei: "+673",
  Bulgaria: "+359",
  "Burkina Faso": "+226",
  Burundi: "+257",
  Cambodia: "+855",
  Cameroon: "+237",
  Canada: "+1",
  "Cape Verde Islands": "+238",
  "Cayman Islands": "+1-345",
  "Central African Republic": "+236",
  Chile: "+56",
  China: "+86",
  Colombia: "+57",
  Comoros: "+269",
  Congo: "+242",
  "Cook Islands": "+682",
  "Costa Rica": "+506",
  Croatia: "+385",
  Cuba: "+53",
  "Cyprus North": "+90",
  "Cyprus South": "+357",
  "Czech Republic": "+420",
  Denmark: "+45",
  Djibouti: "+253",
  Dominica: "+1-767",
  "Dominican Republic": "+1-809",
  Ecuador: "+593",
  Egypt: "+20",
  "El Salvador": "+503",
  "Equatorial Guinea": "+240",
  Eritrea: "+291",
  Estonia: "+372",
  Ethiopia: "+251",
  "Falkland Islands": "+500",
  "Faroe Islands": "+298",
  Fiji: "+679",
  Finland: "+358",
  France: "+33",
  "French Guiana": "+594",
  "French Polynesia": "+689",
  Gabon: "+241",
  Gambia: "+220",
  Georgia: "+995",
  Germany: "+49",
  Ghana: "+233",
  Gibraltar: "+350",
  Greece: "+30",
  Greenland: "+299",
  Grenada: "+1-473",
  Guadeloupe: "+590",
  Guam: "+1-671",
  Guatemala: "+502",
  Guinea: "+224",
  "Guinea-Bissau": "+245",
  Guyana: "+592",
  Haiti: "+509",
  Honduras: "+504",
  "Hong Kong": "+852",
  Hungary: "+36",
  Iceland: "+354",
  India: "+91",
  Indonesia: "+62",
  Iran: "+98",
  Iraq: "+964",
  Ireland: "+353",
  Israel: "+972",
  Italy: "+39",
  Jamaica: "+1-876",
  Japan: "+81",
  Jordan: "+962",
  Kazakhstan: "+7",
  Kenya: "+254",
  Kiribati: "+686",
  "Korea North": "+850",
  "Korea South": "+82",
  Kuwait: "+965",
  Kyrgyzstan: "+996",
  Laos: "+856",
  Latvia: "+371",
  Lebanon: "+961",
  Lesotho: "+266",
  Liberia: "+231",
  Libya: "+218",
  Liechtenstein: "+423",
  Lithuania: "+370",
  Luxembourg: "+352",
  Macao: "+853",
  Macedonia: "+389",
  Madagascar: "+261",
  Malawi: "+265",
  Malaysia: "+60",
  Maldives: "+960",
  Mali: "+223",
  Malta: "+356",
  "Marshall Islands": "+692",
  Martinique: "+596",
  Mauritania: "+222",
  Mayotte: "+262",
  Mexico: "+52",
  Micronesia: "+691",
  Moldova: "+373",
  Monaco: "+377",
  Mongolia: "+976",
  Montserrat: "+1664",
  Morocco: "+212",
  Mozambique: "+258",
  Myanmar: "+95",
  Namibia: "+264",
  Nauru: "+674",
  Nepal: "+977",
  Netherlands: "+31",
  "New Caledonia": "+687",
  "New Zealand": "+64",
  Nicaragua: "+505",
  Niger: "+227",
  Nigeria: "+234",
  Niue: "+683",
  "Norfolk Islands": "+672",
  "Northern Marianas": "+1670",
  Norway: "+47",
  Oman: "+968",
  Palau: "+680",
  Panama: "+507",
  "Papua New Guinea": "+675",
  Paraguay: "+595",
  Peru: "+51",
  Philippines: "+63",
  Poland: "+48",
  Portugal: "+351",
  "Puerto Rico": "+1",
  Qatar: "+974",
  Reunion: "+262",
  Romania: "+40",
  Russia: "+7",
  Rwanda: "+250",
  "San Marino": "+378",
  "Sao Tome & Principe": "+239",
  "Saudi Arabia": "+966",
  Senegal: "+221",
  Serbia: "+381",
  Seychelles: "+248",
  "Sierra Leone": "+232",
  Singapore: "+65",
  "Slovak Republic": "+421",
  Slovenia: "+386",
  "Solomon Islands": "+677",
  Somalia: "+252",
  "South Africa": "+27",
  Spain: "+34",
  "Sri Lanka": "+94",
  "St. Helena": "+290",
  "St. Kitts": "+1",
  "St. Lucia": "+1",
  Sudan: "+249",
  Suriname: "+597",
  Swaziland: "+268",
  Sweden: "+46",
  Switzerland: "+41",
  Syria: "+963",
  Taiwan: "+886",
  Tajikstan: "+992",
  Thailand: "+66",
  Togo: "+228",
  Tonga: "+676",
  "Trinidad & Tobago": "+1",
  Tunisia: "+216",
  Turkey: "+90",
  Turkmenistan: "+993",
  "Turks & Caicos Islands": "+1",
  Tuvalu: "+688",
  Uganda: "+256",
  Ukraine: "+380",
  "United Arab Emirates": "+971",
  Uruguay: "+598",
  Uzbekistan: "+998",
  Vanuatu: "+678",
  "Vatican City": "+379",
  Venezuela: "+58",
  Vietnam: "+84",
  "Virgin Islands - British": "+1",
  "Virgin Islands - US": "+1",
  "Wallis & Futuna": "+681",
  Yemen: "+967",
  Zambia: "+260",
  Zimbabwe: "+263",
};

const Requirement = () => {
  const [firstName, setFirstName] = useState("");
  const [email, setEmail] = useState("");
  const [country, setCountry] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const [contactNumber, setContactNumber] = useState("");
  const [business, setBusiness] = useState("");
  const [checkedItems, setCheckedItems] = useState({
    website: [],
    mobile: [],
    customized: [],
    digital: [],
  });
  const [activeTab, setActiveTab] = useState("website");
  const [projectName, setProjectName] = useState("");
  const [budget, setBudget] = useState("");
  const [date, setDate] = useState("");
  const [audience, setAudience] = useState("");
  const [file, setFile] = useState(null);
  const [projectScope, setProjectScope] = useState("");
  const [targetCountry, setTargetCountry] = useState("");
  const [recaptchaToken, setRecaptchaToken] = useState("");
  const [message, setMessage] = useState("");
  const [messageType, setMessageType] = useState("");
  const [errors, setErrors] = useState({});

  const handleCountryChange = (event) => {
    const selectedCountry = event.target.value;
    setCountry(selectedCountry);
    setCountryCode(countryCodeMapping[selectedCountry] || "");
  };

  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
  };

  const handleCheckboxChange = (category, value) => {
    setCheckedItems((prevState) => ({
      ...prevState,
      [category]: prevState[category].includes(value)
        ? prevState[category].filter((item) => item !== value)
        : [...prevState[category], value],
    }));
  };

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    let formErrors = {};

    if (!firstName.trim()) {
      formErrors.firstName = "Name is required";
    }
    if (!email.trim()) {
      formErrors.email = "Email is required";
    }
    if (!country.trim()) {
      formErrors.country = "Country is required";
    }
    if (!contactNumber.trim()) {
      formErrors.contactNumber = "Contact Number is required";
    }
    if (!business.trim()) {
      formErrors.business = "Type of Business is required";
    }

    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors);
      setMessage("");
      setMessageType("");
      return;
    }

    const formData = new FormData();
    formData.append("firstName", firstName);
    formData.append("email", email);
    formData.append("country", country);
    formData.append("countryCode", countryCode);
    formData.append("contactNumber", contactNumber);
    formData.append("business", business);
    formData.append("checkedItems", JSON.stringify(checkedItems));
    formData.append("projectName", projectName);
    formData.append("budget", budget);
    formData.append("date", date);
    formData.append("audience", audience);
    formData.append("targetCountry", targetCountry);
    formData.append("projectScope", projectScope);
    formData.append("g-recaptcha-response", recaptchaToken);

    if (file) {
      formData.append("file", file);
    }

    try {
      const response = await axios.post(
        "https://www.hum.ujn.mybluehostin.me/gurusoftwareapi/upload.php",
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );

      if (response.data && response.data.success) {
        setMessage(response.data.message || "Form submission successful");
        setMessageType("success");

        setTimeout(() => {
          setMessage("");
          setMessageType("");
        }, 5000);

        setFirstName("");
        setEmail("");
        setCountry("");
        setCountryCode("");
        setContactNumber("");
        setBusiness("");
        setCheckedItems({
          website: [],
          mobile: [],
          customized: [],
          digital: [],
        });
        setProjectName("");
        setBudget("");
        setDate("");
        setAudience("");
        setTargetCountry("");
        setProjectScope("");
        setFile(null);
        setRecaptchaToken("");
        setErrors({});

        document.querySelector('input[type="file"]').value = ""; // Clear file input
      } else {
        setMessage(response.data.message || "Submission failed");
        setMessageType("error");
      }
    } catch (error) {
      setMessage(
        error.response ? error.response.data.message : "An error occurred"
      );
      setMessageType("error");
    }
  };

  const handleRecaptchaChange = (token) => {
    setRecaptchaToken(token);
  };

  return (
    <div>
      <Helmet>
        <title>
          Requirement Form - Guru Software | Your IT Services Partner
        </title>

        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, shrink-to-fit=no"
        />
        <meta name="author" content="Guru Software" />
        <meta name="subject" content="IT Company" />
        <meta name="owner" content="Guru Software" />
        <meta name="coverage" content="India" />
        <meta
          name="Geography"
          content="Guru Software, Ahmedabad, Gujarat, India"
        />
        <meta name="Language" content="English" />
        <meta name="distribution" content="Global" />
        <meta name="audience" content="All" />
        <meta name="revisit-after" content="3 days" />
        <meta name="Robots" content="INDEX,FOLLOW" />
        <meta name="country" content="India" />
        <meta
          name="description"
          content="Submit your IT project requirements to Guru Software. Our team is ready to assist you with custom software development, digital marketing, and more."
        />
        <meta property="og:title" content="Requirement Form - Guru Software" />
        <meta
          property="og:description"
          content="Submit your IT project requirements to Guru Software. Our team is ready to assist you with custom software development, digital marketing, and more."
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:url"
          content="https://www.gurusoftware.in/Requirement"
        />
        <meta
          property="og:image"
          content="https://www.gurusoftware.in/images/og.png"
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:description"
          content="Submit your IT project requirements to Guru Software. Our team is ready to assist you with custom software development, digital marketing, and more."
        />
        <meta name="twitter:title" content="Requirement Form - Guru Software" />
      </Helmet>
      <div className="contact-us">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-12">
              <div
                className="contact-form-box wow fadeInUp"
                data-wow-delay="0.5s"
              >
                <h2
                  className="text-anime-style-3"
                  style={{
                    perspective: 400,
                    width: "100%",
                    textAlign: "center",
                  }}
                >
                  We Would Like To Hear From You Anytime
                </h2>
                <br /> <br />
                <div className="contact-form">
                  <form onSubmit={handleSubmit}>
                    <div className="row">
                      <div className="form-group col-md-6 mb-4">
                        <input
                          type="text"
                          name="fname"
                          className="form-control"
                          id="fname"
                          value={firstName}
                          onChange={(e) => setFirstName(e.target.value)}
                          placeholder="Name/Company Name"
                          required
                        />
                        {errors.firstName && (
                          <div className="help-block with-errors">
                            {errors.firstName}
                          </div>
                        )}
                      </div>
                      <div className="form-group col-md-6 mb-4">
                        <input
                          type="email"
                          name="Email"
                          className="form-control"
                          id="Email"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          placeholder="Your Email Address"
                          required
                        />
                        {errors.email && (
                          <div className="help-block with-errors">
                            {errors.email}
                          </div>
                        )}
                      </div>

                      <div className="form-group col-md-4 mb-4">
                        <select
                          name="country"
                          id="Choose"
                          className="form-control country"
                          value={country}
                          onChange={handleCountryChange}
                          required
                        >
                          <option value="">Select your Country</option>

                          <option data-countrycode="GB" value="UK">
                            UK
                          </option>
                          <option data-countrycode="US" value="USA">
                            USA
                          </option>
                          <option data-countrycode="DZ" value="Algeria">
                            Algeria
                          </option>
                          <option data-countrycode="AD" value="Andorra">
                            Andorra
                          </option>
                          <option data-countrycode="AO" value="Angola">
                            Angola
                          </option>
                          <option data-countrycode="AI" value="Anguilla">
                            Anguilla
                          </option>
                          <option
                            data-countrycode="AG"
                            value="Antigua & Barbuda"
                          >
                            Antigua &amp; Barbuda
                          </option>
                          <option data-countrycode="AR" value="Argentina">
                            Argentina
                          </option>
                          <option data-countrycode="AM" value="Armenia">
                            Armenia
                          </option>
                          <option data-countrycode="AW" value="Aruba">
                            Aruba
                          </option>
                          <option data-countrycode="AU" value="Australia">
                            Australia
                          </option>
                          <option data-countrycode="AT" value="Austria">
                            Austria
                          </option>
                          <option data-countrycode="AZ" value="Azerbaijan">
                            Azerbaijan
                          </option>
                          <option data-countrycode="BS" value="Bahamas">
                            Bahamas
                          </option>
                          <option data-countrycode="BH" value="Bahrain">
                            Bahrain
                          </option>
                          <option data-countrycode="BD" value="Bangladesh">
                            Bangladesh
                          </option>
                          <option data-countrycode="BB" value="Barbados">
                            Barbados
                          </option>
                          <option data-countrycode="BY" value="Belarus">
                            Belarus
                          </option>
                          <option data-countrycode="BE" value="Belgium">
                            Belgium
                          </option>
                          <option data-countrycode="BZ" value="Belize">
                            Belize
                          </option>
                          <option data-countrycode="BJ" value="Benin">
                            Benin
                          </option>
                          <option data-countrycode="BM" value="Bermuda">
                            Bermuda
                          </option>
                          <option data-countrycode="BT" value="Bhutan">
                            Bhutan
                          </option>
                          <option data-countrycode="BO" value="Bolivia">
                            Bolivia
                          </option>
                          <option
                            data-countrycode="BA"
                            value="Bosnia Herzegovina"
                          >
                            Bosnia Herzegovina
                          </option>
                          <option data-countrycode="BW" value="Botswana">
                            Botswana
                          </option>
                          <option data-countrycode="BR" value="Brazil">
                            Brazil
                          </option>
                          <option data-countrycode="BN" value="Brunei">
                            Brunei
                          </option>
                          <option data-countrycode="BG" value="Bulgaria">
                            Bulgaria
                          </option>
                          <option data-countrycode="BF" value="Burkina Faso">
                            Burkina Faso
                          </option>
                          <option data-countrycode="BI" value="Burundi">
                            Burundi
                          </option>
                          <option data-countrycode="KH" value="Cambodia">
                            Cambodia
                          </option>
                          <option data-countrycode="CM" value="Cameroon">
                            Cameroon
                          </option>
                          <option data-countrycode="CA" value="Canada">
                            Canada
                          </option>
                          <option
                            data-countrycode="CV"
                            value="Cape Verde Islands"
                          >
                            Cape Verde Islands
                          </option>
                          <option data-countrycode="KY" value="Cayman Islands">
                            Cayman Islands
                          </option>
                          <option
                            data-countrycode="CF"
                            value="Central African Republic"
                          >
                            Central African Republic
                          </option>
                          <option data-countrycode="CL" value="Chile">
                            Chile
                          </option>
                          <option data-countrycode="CN" value="China">
                            China
                          </option>
                          <option data-countrycode="CO" value="Colombia">
                            Colombia
                          </option>
                          <option data-countrycode="KM" value="Comoros">
                            Comoros
                          </option>
                          <option data-countrycode="CG" value="Congo">
                            Congo
                          </option>
                          <option data-countrycode="CK" value="Cook Islands">
                            Cook Islands
                          </option>
                          <option data-countrycode="CR" value="Costa Rica">
                            Costa Rica
                          </option>
                          <option data-countrycode="HR" value="Croatia">
                            Croatia
                          </option>
                          <option data-countrycode="CU" value="Cuba">
                            Cuba
                          </option>
                          <option data-countrycode="CY" value="Cyprus North">
                            Cyprus North
                          </option>

                          <option data-countrycode="CY" value="Cyprus South">
                            Cyprus South
                          </option>
                          <option data-countrycode="CZ" value="Czech Republic">
                            Czech Republic
                          </option>
                          <option data-countrycode="DK" value="Denmark">
                            Denmark
                          </option>
                          <option data-countrycode="DJ" value="Djibouti">
                            Djibouti
                          </option>
                          <option data-countrycode="DM" value="Dominica">
                            Dominica
                          </option>
                          <option
                            data-countrycode="DO"
                            value="Dominican Republic"
                          >
                            Dominican Republic
                          </option>
                          <option data-countrycode="EC" value="Ecuador">
                            Ecuador
                          </option>
                          <option data-countrycode="EG" value="Egypt">
                            Egypt
                          </option>
                          <option data-countrycode="SV" value="El Salvador">
                            El Salvador
                          </option>
                          <option
                            data-countrycode="GQ"
                            value="Equatorial Guinea"
                          >
                            Equatorial Guinea
                          </option>
                          <option data-countrycode="ER" value="Eritrea">
                            Eritrea
                          </option>
                          <option data-countrycode="EE" value="Estonia">
                            Estonia
                          </option>
                          <option data-countrycode="ET" value="Ethiopia">
                            Ethiopia
                          </option>
                          <option
                            data-countrycode="FK"
                            value="Falkland Islands"
                          >
                            Falkland Islands
                          </option>
                          <option data-countrycode="FO" value="Faroe Islands">
                            Faroe Islands
                          </option>
                          <option data-countrycode="FJ" value="Fiji">
                            Fiji
                          </option>
                          <option data-countrycode="FI" value="Finland">
                            Finland
                          </option>
                          <option data-countrycode="FR" value="France">
                            France
                          </option>
                          <option data-countrycode="GF" value="French Guiana">
                            French Guiana
                          </option>
                          <option
                            data-countrycode="PF"
                            value="French Polynesia"
                          >
                            French Polynesia
                          </option>
                          <option data-countrycode="GA" value="Gabon">
                            Gabon
                          </option>
                          <option data-countrycode="GM" value="Gambia">
                            Gambia
                          </option>
                          <option data-countrycode="GE" value="Georgia">
                            Georgia
                          </option>
                          <option data-countrycode="DE" value="Germany">
                            Germany
                          </option>
                          <option data-countrycode="GH" value="Ghana">
                            Ghana
                          </option>
                          <option data-countrycode="GI" value="Gibraltar">
                            Gibraltar
                          </option>
                          <option data-countrycode="GR" value="Greece">
                            Greece
                          </option>
                          <option data-countrycode="GL" value="Greenland">
                            Greenland
                          </option>
                          <option data-countrycode="GD" value="Grenada">
                            Grenada
                          </option>
                          <option data-countrycode="GP" value="Guadeloupe">
                            Guadeloupe
                          </option>
                          <option data-countrycode="GU" value="Guam">
                            Guam
                          </option>
                          <option data-countrycode="GT" value="Guatemala">
                            Guatemala
                          </option>
                          <option data-countrycode="GN" value="Guinea">
                            Guinea
                          </option>
                          <option data-countrycode="GW" value="Guinea-Bissau">
                            Guinea-Bissau
                          </option>
                          <option data-countrycode="GY" value="Guyana">
                            Guyana
                          </option>
                          <option data-countrycode="HT" value="Haiti">
                            Haiti
                          </option>
                          <option data-countrycode="HN" value="Honduras">
                            Honduras
                          </option>
                          <option data-countrycode="HK" value="Hong Kong">
                            Hong Kong
                          </option>
                          <option data-countrycode="HU" value="Hungary">
                            Hungary
                          </option>
                          <option data-countrycode="IS" value="Iceland">
                            Iceland
                          </option>
                          <option data-countrycode="IN" value="India">
                            India
                          </option>
                          <option data-countrycode="ID" value="Indonesia">
                            Indonesia
                          </option>
                          <option data-countrycode="IR" value="Iran">
                            Iran
                          </option>
                          <option data-countrycode="IQ" value="Iraq">
                            Iraq
                          </option>
                          <option data-countrycode="IE" value="Ireland">
                            Ireland
                          </option>
                          <option data-countrycode="IL" value="Israel">
                            Israel
                          </option>
                          <option data-countrycode="IT" value="Italy">
                            Italy
                          </option>
                          <option data-countrycode="JM" value="Jamaica">
                            Jamaica
                          </option>
                          <option data-countrycode="JP" value="Japan">
                            Japan
                          </option>
                          <option data-countrycode="JO" value="Jordan">
                            Jordan
                          </option>
                          <option data-countrycode="KZ" value="Kazakhstan">
                            Kazakhstan
                          </option>
                          <option data-countrycode="KE" value="Kenya">
                            Kenya
                          </option>
                          <option data-countrycode="KI" value="Kiribati">
                            Kiribati
                          </option>
                          <option data-countrycode="KP" value="Korea North">
                            Korea North
                          </option>
                          <option data-countrycode="KR" value="Korea South">
                            Korea South
                          </option>
                          <option data-countrycode="KW" value="Kuwait">
                            Kuwait
                          </option>
                          <option data-countrycode="KG" value="Kyrgyzstan">
                            Kyrgyzstan
                          </option>
                          <option data-countrycode="LA" value="Laos">
                            Laos
                          </option>
                          <option data-countrycode="LV" value="Latvia">
                            Latvia
                          </option>
                          <option data-countrycode="LB" value="Lebanon">
                            Lebanon
                          </option>
                          <option data-countrycode="LS" value="Lesotho">
                            Lesotho
                          </option>
                          <option data-countrycode="LR" value="Liberia">
                            Liberia
                          </option>
                          <option data-countrycode="LY" value="Libya">
                            Libya
                          </option>
                          <option data-countrycode="LI" value="Liechtenstein">
                            Liechtenstein
                          </option>
                          <option data-countrycode="LT" value="Lithuania">
                            Lithuania
                          </option>
                          <option data-countrycode="LU" value="Luxembourg">
                            Luxembourg
                          </option>
                          <option data-countrycode="MO" value="Macao">
                            Macao
                          </option>
                          <option data-countrycode="MK" value="Macedonia">
                            Macedonia
                          </option>
                          <option data-countrycode="MG" value="Madagascar">
                            Madagascar
                          </option>
                          <option data-countrycode="MW" value="Malawi">
                            Malawi
                          </option>
                          <option data-countrycode="MY" value="Malaysia">
                            Malaysia
                          </option>
                          <option data-countrycode="MV" value="Maldives">
                            Maldives
                          </option>
                          <option data-countrycode="ML" value="Mali">
                            Mali
                          </option>
                          <option data-countrycode="MT" value="Malta">
                            Malta
                          </option>
                          <option
                            data-countrycode="MH"
                            value="Marshall Islands"
                          >
                            Marshall Islands
                          </option>
                          <option data-countrycode="MQ" value="Martinique">
                            Martinique
                          </option>
                          <option data-countrycode="MR" value="Mauritania">
                            Mauritania
                          </option>
                          <option data-countrycode="YT" value="Mayotte">
                            Mayotte
                          </option>
                          <option data-countrycode="MX" value="Mexico">
                            Mexico
                          </option>
                          <option data-countrycode="FM" value="Micronesia">
                            Micronesia
                          </option>
                          <option data-countrycode="MD" value="Moldova">
                            Moldova
                          </option>
                          <option data-countrycode="MC" value="Monaco">
                            Monaco
                          </option>
                          <option data-countrycode="MN" value="Mongolia">
                            Mongolia
                          </option>
                          <option data-countrycode="MS" value="Montserrat">
                            Montserrat
                          </option>
                          <option data-countrycode="MA" value="Morocco">
                            Morocco
                          </option>
                          <option data-countrycode="MZ" value="Mozambique">
                            Mozambique
                          </option>
                          <option data-countrycode="MN" value="Myanmar">
                            Myanmar
                          </option>
                          <option data-countrycode="NA" value="Namibia">
                            Namibia
                          </option>
                          <option data-countrycode="NR" value="Nauru">
                            Nauru
                          </option>
                          <option data-countrycode="NP" value="Nepal">
                            Nepal
                          </option>
                          <option data-countrycode="NL" value="Netherlands">
                            Netherlands
                          </option>
                          <option data-countrycode="NC" value="New Caledonia">
                            New Caledonia
                          </option>
                          <option data-countrycode="NZ" value="New Zealand">
                            New Zealand
                          </option>
                          <option data-countrycode="NI" value="Nicaragua">
                            Nicaragua
                          </option>
                          <option data-countrycode="NE" value="Niger">
                            Niger
                          </option>
                          <option data-countrycode="NG" value="Nigeria">
                            Nigeria
                          </option>
                          <option data-countrycode="NU" value="Niue">
                            Niue
                          </option>
                          <option data-countrycode="NF" value="Norfolk Islands">
                            Norfolk Islands
                          </option>
                          <option
                            data-countrycode="NP"
                            value="Northern Marianas"
                          >
                            Northern Marianas
                          </option>
                          <option data-countrycode="NO" value="Norway">
                            Norway
                          </option>
                          <option data-countrycode="OM" value="Oman">
                            Oman
                          </option>
                          <option data-countrycode="PW" value="Palau">
                            Palau
                          </option>
                          <option data-countrycode="PA" value="Panama">
                            Panama
                          </option>
                          <option
                            data-countrycode="PG"
                            value="Papua New Guinea"
                          >
                            Papua New Guinea
                          </option>
                          <option data-countrycode="PY" value="Paraguay">
                            Paraguay
                          </option>
                          <option data-countrycode="PE" value="Peru">
                            Peru
                          </option>
                          <option data-countrycode="PH" value="Philippines">
                            Philippines
                          </option>
                          <option data-countrycode="PL" value="Poland">
                            Poland
                          </option>
                          <option data-countrycode="PT" value="Portugal">
                            Portugal
                          </option>
                          <option data-countrycode="PR" value="Puerto Rico">
                            Puerto Rico
                          </option>
                          <option data-countrycode="QA" value="Qatar">
                            Qatar
                          </option>
                          <option data-countrycode="RE" value="Reunion">
                            Reunion
                          </option>
                          <option data-countrycode="RO" value="Romania">
                            Romania
                          </option>
                          <option data-countrycode="RU" value="Russia">
                            Russia
                          </option>
                          <option data-countrycode="RW" value="Rwanda">
                            Rwanda
                          </option>
                          <option data-countrycode="SM" value="San Marino">
                            San Marino
                          </option>
                          <option
                            data-countrycode="ST"
                            value="Sao Tome &amp; Principe"
                          >
                            Sao Tome & Principe
                          </option>
                          <option data-countrycode="SA" value="Saudi Arabia">
                            Saudi Arabia
                          </option>
                          <option data-countrycode="SN" value="Senegal">
                            Senegal
                          </option>
                          <option data-countrycode="CS" value="Serbia">
                            Serbia
                          </option>
                          <option data-countrycode="SC" value="Seychelles">
                            Seychelles
                          </option>
                          <option data-countrycode="SL" value="Sierra Leone">
                            Sierra Leone
                          </option>
                          <option data-countrycode="SG" value="Singapore">
                            Singapore
                          </option>
                          <option data-countrycode="SK" value="Slovak Republic">
                            Slovak Republic
                          </option>
                          <option data-countrycode="SI" value="Slovenia">
                            Slovenia
                          </option>
                          <option data-countrycode="SB" value="Solomon Islands">
                            Solomon Islands
                          </option>
                          <option data-countrycode="SO" value="Somalia">
                            Somalia
                          </option>
                          <option data-countrycode="ZA" value="South Africa">
                            South Africa
                          </option>
                          <option data-countrycode="ES" value="Spain">
                            Spain
                          </option>
                          <option data-countrycode="LK" value="Sri Lanka">
                            Sri Lanka
                          </option>
                          <option data-countrycode="SH" value="St. Helena">
                            St. Helena
                          </option>
                          <option data-countrycode="KN" value="St. Kitts">
                            St. Kitts
                          </option>
                          <option data-countrycode="SC" value="St. Lucia">
                            St. Lucia
                          </option>
                          <option data-countrycode="SD" value="Sudan">
                            Sudan
                          </option>
                          <option data-countrycode="SR" value="Suriname">
                            Suriname
                          </option>
                          <option data-countrycode="SZ" value="Swaziland">
                            Swaziland
                          </option>
                          <option data-countrycode="SE" value="Sweden">
                            Sweden
                          </option>
                          <option data-countrycode="CH" value="Switzerland">
                            Switzerland
                          </option>
                          <option data-countrycode="SI" value="Syria">
                            Syria
                          </option>
                          <option data-countrycode="TW" value="Taiwan">
                            Taiwan
                          </option>
                          <option data-countrycode="TJ" value="Tajikstan">
                            Tajikstan
                          </option>
                          <option data-countrycode="TH" value="Thailand">
                            Thailand
                          </option>
                          <option data-countrycode="TG" value="Togo">
                            Togo
                          </option>
                          <option data-countrycode="TO" value="Tonga">
                            Tonga
                          </option>
                          <option
                            data-countrycode="TT"
                            value="Trinidad &amp; Tobago"
                          >
                            Trinidad & Tobago
                          </option>
                          <option data-countrycode="TN" value="Tunisia">
                            Tunisia
                          </option>
                          <option data-countrycode="TR" value="Turkey">
                            Turkey
                          </option>
                          <option data-countrycode="TM" value="Turkmenistan">
                            Turkmenistan
                          </option>
                          <option
                            data-countrycode="TC"
                            value="Turks &amp; Caicos Islands"
                          >
                            Turks & Caicos Islands
                          </option>
                          <option data-countrycode="TV" value="Tuvalu">
                            Tuvalu
                          </option>
                          <option data-countrycode="UG" value="Uganda">
                            Uganda
                          </option>
                          <option data-countrycode="UA" value="Ukraine">
                            Ukraine
                          </option>
                          <option
                            data-countrycode="AE"
                            value="United Arab Emirates"
                          >
                            United Arab Emirates
                          </option>
                          <option data-countrycode="UY" value="Uruguay">
                            Uruguay
                          </option>
                          <option data-countrycode="UZ" value="Uzbekistan">
                            Uzbekistan
                          </option>
                          <option data-countrycode="VU" value="Vanuatu">
                            Vanuatu
                          </option>
                          <option data-countrycode="VA" value="Vatican City">
                            Vatican City
                          </option>
                          <option data-countrycode="VE" value="Venezuela">
                            Venezuela
                          </option>
                          <option data-countrycode="VN" value="Vietnam">
                            Vietnam
                          </option>
                          <option
                            data-countrycode="VG"
                            value="Virgin Islands - British"
                          >
                            Virgin Islands - British
                          </option>
                          <option
                            data-countrycode="VI"
                            value="Virgin Islands - US"
                          >
                            Virgin Islands - US
                          </option>
                          <option
                            data-countrycode="WF"
                            value="Wallis &amp; Futuna"
                          >
                            Wallis & Futuna
                          </option>
                          <option data-countrycode="YE" value="Yemen (North)">
                            Yemen (North)
                          </option>
                          <option data-countrycode="YE" value="Yemen (South)">
                            Yemen (South)
                          </option>
                          <option data-countrycode="ZM" value="Zambia">
                            Zambia
                          </option>
                          <option data-countrycode="ZW" value="Zimbabwe">
                            Zimbabwe
                          </option>
                        </select>
                        <div className="help-block with-errors" />
                      </div>

                      <div className="form-group col-md-2 mb-4">
                        <input
                          type="text"
                          name="CountryCode"
                          className="form-control"
                          value={countryCode}
                          readOnly
                          placeholder="Country Code"
                          required
                        />
                        <div className="help-block with-errors" />
                      </div>

                      <div className="form-group col-md-6 mb-4">
                        <input
                          type="text"
                          name="ContactNumber"
                          className="form-control"
                          value={contactNumber}
                          onChange={(e) => setContactNumber(e.target.value)}
                          placeholder="Contact Number"
                          required
                          pattern="\d{10}"
                          minLength={10}
                          maxLength={10}
                          title="Please enter a valid 10-digit contact number"
                        />
                        {errors.contactNumber && (
                          <p style={{ color: "red" }}>{errors.contactNumber}</p>
                        )}
                      </div>

                      <div className="form-group col-md-12 mb-4">
                        <select
                          name="bType"
                          id=""
                          className="form-control myDropDown"
                          onChange={(e) => setBusiness(e.target.value)}
                        >
                          <option>Type Of Business (optional)</option>

                          <option value="Art & Entertainment">
                            Art & Entertainment
                          </option>
                          <option value="Autos & Vehicles">
                            Autos & Vehicles
                          </option>
                          <option value="Beauty & Fitness">
                            Beauty & Fitness
                          </option>
                          <option value="Books & Literature">
                            Books & Literature
                          </option>
                          <option value="Business & Industrial">
                            Business & Industrial
                          </option>
                          <option value="Computer & Electronics">
                            Computer & Electronics
                          </option>
                          <option value="Finance">Finance</option>
                          <option value="Food & Drink">Food & Drink</option>
                          <option value="Games">Games</option>
                          <option value="Health">Health</option>
                          <option value="Hobbies & Leisure">
                            Hobbies & Leisure
                          </option>
                          <option value="Home & Garden">Home & Garden</option>
                          <option value="Internet & Telecom">
                            Internet & Telecom
                          </option>
                          <option value="Jobs & Education">
                            Jobs & Education
                          </option>
                          <option value="Law & Government">
                            Law & Government
                          </option>
                          <option value="News">News</option>
                          <option value="Marketing & Advertisement">
                            Marketing & Advertisement
                          </option>
                          <option value="Online Communities">
                            Online Communities
                          </option>
                          <option value="People & Society">
                            People & Society
                          </option>
                          <option value="Pets & Animals">Pets & Animals</option>
                          <option value="Real Estate">Real Estate</option>
                          <option value="Science & Technology">
                            Science & Technology
                          </option>
                          <option value="Shopping">Shopping</option>
                          <option value="Sports">Sports</option>
                          <option value="Travels">Travels</option>
                          <option value="Other">Other</option>
                        </select>
                        <div className="help-block with-errors" />
                      </div>

                      <div className="col-lg-12 col-md-12">
                        <h5
                          className="text-anime-style-3"
                          style={{ paddingLeft: "10px" }}
                        >
                          Services Interested In
                        </h5>
                        <br />
                        <div
                          className="our-projects-nav wow fadeInUp serv servmy "
                          data-wow-delay="0.25s"
                        >
                          <ul className="list-unstyled">
                            <div className="">
                              <div className="col-lg-12 col-md-6 d-md-none">
                                <div className="row myrow">
                                  <div className="col-6 col-lg-12 col-md-6">
                                    <Link
                                      to="#"
                                      className={
                                        activeTab === "website"
                                          ? "active-btn"
                                          : ""
                                      }
                                      onClick={() => handleTabClick("website")}
                                      data-filter=".website"
                                    >
                                      <li
                                        className={
                                          activeTab === "website"
                                            ? "active"
                                            : ""
                                        }
                                        style={{ fontSize: "12px", height: "44px "}}
                                      >
                                        Website development
                                      </li>
                                    </Link>
                                  </div>
                                  <div className="col-6 col-lg-12 col-md-6">
                                    <Link
                                      to="#"
                                      className={
                                        activeTab === "mobile"
                                          ? "active-btn"
                                          : ""
                                      }
                                      onClick={() => handleTabClick("mobile")}
                                      data-filter=".mobile"
                                    >
                                      <li
                                        className={
                                          activeTab === "mobile" ? "active" : ""
                                        }
                                        style={{
                                          fontSize: "12px",
                                          height: "44px ",
                                        }}
                                      >
                                        Mobile app development
                                      </li>
                                    </Link>
                                  </div>
                                  <div className="col-6 col-lg-12 col-md-6">
                                    <Link
                                      to="#"
                                      className={
                                        activeTab === "customized"
                                          ? "active-btn"
                                          : ""
                                      }
                                      onClick={() =>
                                        handleTabClick("customized")
                                      }
                                      data-filter=".customized"
                                    >
                                      <li
style={{ fontSize: "12px", height: "44px "}}              
                          className={
                                          activeTab === "customized"
                                            ? "active"
                                            : ""
                                        }
                                      >
                                        Customized Software
                                      </li>
                                    </Link>
                                  </div>
                                  <div className="col-6 col-lg-12 col-md-6">
                                    <Link
                                      to="#"
                                      className={
                                        activeTab === "digital"
                                          ? "active-btn"
                                          : ""
                                      }
                                      onClick={() => handleTabClick("digital")}
                                      data-filter=".digital"
                                    >
                                      <li
                                        className={
                                          activeTab === "digital"
                                            ? "active"
                                            : ""
                                        }
                                        style={{ fontSize: "12px", height: "44px "}}                                      >
                                        Digital marketing
                                      </li>
                                    </Link>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-12 col-md-12 d-none d-md-block">
                                <Link
                                  to="#"
                                  className={
                                    activeTab === "website" ? "active-btn" : ""
                                  }
                                  onClick={() => handleTabClick("website")}
                                  data-filter=".website"
                                >
                                  <li
                                    className={
                                      activeTab === "website" ? "active" : ""
                                    }
                                  >
                                    Website development
                                  </li>
                                </Link>
                                <Link
                                  to="#"
                                  className={
                                    activeTab === "mobile" ? "active-btn" : ""
                                  }
                                  onClick={() => handleTabClick("mobile")}
                                  data-filter=".mobile"
                                >
                                  <li
                                    className={
                                      activeTab === "mobile" ? "active" : ""
                                    }
                                  >
                                    Mobile app development
                                  </li>
                                </Link>

                                <Link
                                  to="#"
                                  className={
                                    activeTab === "customized"
                                      ? "active-btn"
                                      : ""
                                  }
                                  onClick={() => handleTabClick("customized")}
                                  data-filter=".customized"
                                >
                                  <li
                                    className={
                                      activeTab === "customized" ? "active" : ""
                                    }
                                  >
                                    Customized Software
                                  </li>
                                </Link>
                                <Link
                                  to="#"
                                  className={
                                    activeTab === "digital" ? "active-btn" : ""
                                  }
                                  onClick={() => handleTabClick("digital")}
                                  data-filter=".digital"
                                >
                                  <li
                                    className={
                                      activeTab === "digital" ? "active" : ""
                                    }
                                  >
                                    Digital marketing
                                  </li>
                                </Link>
                              </div>
                            </div>
                          </ul>
                        </div>
                      </div>

                      <div className="col-lg-12 col-md-12">
                        <div className="row myboxxx" style={{ height: "auto" }}>
                          {activeTab === "website" && (
                            <div className="col-md-4 project-item-box website">
                              <div
                                className="works-item"
                                style={{ width: "auto", height: "auto" }}
                              >
                                <fieldset>
                                  <input
                                    type="checkbox"
                                    id="websiteDesign"
                                    value="Website Design"
                                    checked={checkedItems.website.includes(
                                      "Website Design"
                                    )}
                                    onChange={() =>
                                      handleCheckboxChange(
                                        "website",
                                        "Website Design"
                                      )
                                    }
                                  />
                                  <label htmlFor="websiteDesign">
                                    WEBSITE DESIGN
                                  </label>
                                  <br />
                                  <input
                                    type="checkbox"
                                    id="websiteDevelopment"
                                    value="Website Development"
                                    checked={checkedItems.website.includes(
                                      "Website Development"
                                    )}
                                    onChange={() =>
                                      handleCheckboxChange(
                                        "website",
                                        "Website Development"
                                      )
                                    }
                                  />
                                  <label htmlFor="websiteDevelopment">
                                    WEBSITE DEVELOPMENT
                                  </label>
                                  <br />
                                </fieldset>
                              </div>
                            </div>
                          )}

                          {activeTab === "mobile" && (
                            <div className="col-md-4 project-item-box mobile">
                              <div
                                className="works-item"
                                style={{ width: "auto", height: "auto" }}
                              >
                                <fieldset>
                                  <input
                                    type="checkbox"
                                    id="mobileAppDevelopment"
                                    value="Mobile App Development"
                                    checked={checkedItems.mobile.includes(
                                      "Mobile App Development"
                                    )}
                                    onChange={() =>
                                      handleCheckboxChange(
                                        "mobile",
                                        "Mobile App Development"
                                      )
                                    }
                                  />
                                  <label htmlFor="mobileAppDevelopment">
                                    MOBILE APP DEVELOPMENT
                                  </label>
                                  <br />
                                  <input
                                    type="checkbox"
                                    id="hybridAppDevelopment"
                                    value="Hybrid App Development"
                                    checked={checkedItems.mobile.includes(
                                      "Hybrid App Development"
                                    )}
                                    onChange={() =>
                                      handleCheckboxChange(
                                        "mobile",
                                        "Hybrid App Development"
                                      )
                                    }
                                  />
                                  <label htmlFor="hybridAppDevelopment">
                                    HYBRID APP DEVELOPMENT
                                  </label>
                                  <br />
                                  <input
                                    type="checkbox"
                                    id="nativeAppDevelopment"
                                    value="Native App Development"
                                    checked={checkedItems.mobile.includes(
                                      "Native App Development"
                                    )}
                                    onChange={() =>
                                      handleCheckboxChange(
                                        "mobile",
                                        "Native App Development"
                                      )
                                    }
                                  />
                                  <label htmlFor="nativeAppDevelopment">
                                    NATIVE APP DEVELOPMENT
                                  </label>
                                  <br />
                                </fieldset>
                              </div>
                            </div>
                          )}

                          {activeTab === "customized" && (
                            <div className="col-md-12 project-item-box customized">
                              <div
                                className="works-item"
                                style={{ width: "auto", height: "auto" }}
                              >
                                <fieldset>
                                  <div
                                    className="row myrow"
                                    style={{ paddingRight: "-18px" }}
                                  >
                                    {/* First Column */}
                                    <div className="col-md-4">
                                      <input
                                        type="checkbox"
                                        id="crmSoftwareDevelopment"
                                        value="CRM Software Development"
                                        checked={checkedItems.customized.includes(
                                          "CRM Software Development"
                                        )}
                                        onChange={() =>
                                          handleCheckboxChange(
                                            "customized",
                                            "CRM Software Development"
                                          )
                                        }
                                      />
                                      <label htmlFor="crmSoftwareDevelopment">
                                        CRM SOFTWARE DEVELOPMENT
                                      </label>
                                      <br />
                                      <input
                                        type="checkbox"
                                        id="digitalMarketingCustomized"
                                        value="Digital Marketing"
                                        checked={checkedItems.customized.includes(
                                          "Digital Marketing"
                                        )}
                                        onChange={() =>
                                          handleCheckboxChange(
                                            "customized",
                                            "Digital Marketing"
                                          )
                                        }
                                      />
                                      <label htmlFor="digitalMarketingCustomized">
                                        DIGITAL MARKETING
                                      </label>
                                      <br />
                                      <input
                                        type="checkbox"
                                        id="restaurantWebPortalDevelopment"
                                        value="Restaurant Web Portal Development"
                                        checked={checkedItems.customized.includes(
                                          "Restaurant Web Portal Development"
                                        )}
                                        onChange={() =>
                                          handleCheckboxChange(
                                            "customized",
                                            "Restaurant Web Portal Development"
                                          )
                                        }
                                      />
                                      <label htmlFor="restaurantWebPortalDevelopment">
                                        RESTAURANT WEB PORTAL DEVELOPMENT
                                      </label>
                                      <br />
                                    </div>
                                    {/* Second Column */}
                                    <div className="col-md-4">
                                      <input
                                        type="checkbox"
                                        id="softwareDevelopment"
                                        value="Software Development"
                                        checked={checkedItems.customized.includes(
                                          "Software Development"
                                        )}
                                        onChange={() =>
                                          handleCheckboxChange(
                                            "customized",
                                            "Software Development"
                                          )
                                        }
                                      />
                                      <label htmlFor="softwareDevelopment">
                                        SOFTWARE DEVELOPMENT
                                      </label>
                                      <br />
                                      <input
                                        type="checkbox"
                                        id="realEstatePortalDevelopment"
                                        value="Real Estate Portal Development"
                                        checked={checkedItems.customized.includes(
                                          "Real Estate Portal Development"
                                        )}
                                        onChange={() =>
                                          handleCheckboxChange(
                                            "customized",
                                            "Real Estate Portal Development"
                                          )
                                        }
                                      />
                                      <label htmlFor="realEstatePortalDevelopment">
                                        REAL ESTATE PORTAL DEVELOPMENT
                                      </label>
                                      <br />
                                      <input
                                        type="checkbox"
                                        id="ecommerceWebPortalDevelopment"
                                        value="Ecommerce Web Portal Development"
                                        checked={checkedItems.customized.includes(
                                          "Ecommerce Web Portal Development"
                                        )}
                                        onChange={() =>
                                          handleCheckboxChange(
                                            "customized",
                                            "Ecommerce Web Portal Development"
                                          )
                                        }
                                      />
                                      <label htmlFor="ecommerceWebPortalDevelopment">
                                        ECOMMERCE WEB PORTAL DEVELOPMENT
                                      </label>
                                      <br />
                                    </div>
                                    {/* Third Column */}
                                    <div className="col-md-4">
                                      <input
                                        type="checkbox"
                                        id="travelWebPortalDevelopment"
                                        value="Travel Web Portal Development"
                                        checked={checkedItems.customized.includes(
                                          "Travel Web Portal Development"
                                        )}
                                        onChange={() =>
                                          handleCheckboxChange(
                                            "customized",
                                            "Travel Web Portal Development"
                                          )
                                        }
                                      />
                                      <label htmlFor="travelWebPortalDevelopment">
                                        TRAVEL WEB PORTAL DEVELOPMENT
                                      </label>
                                      <br />
                                      <input
                                        type="checkbox"
                                        id="b2bWebPortalDevelopment"
                                        value="B2B Web Portal Development"
                                        checked={checkedItems.customized.includes(
                                          "B2B Web Portal Development"
                                        )}
                                        onChange={() =>
                                          handleCheckboxChange(
                                            "customized",
                                            "B2B Web Portal Development"
                                          )
                                        }
                                      />
                                      <label htmlFor="b2bWebPortalDevelopment">
                                        B2B WEB PORTAL DEVELOPMENT
                                      </label>
                                      <br />
                                      <input
                                        type="checkbox"
                                        id="b2cWebPortalDevelopment"
                                        value="B2C Web Portal Development"
                                        checked={checkedItems.customized.includes(
                                          "B2C Web Portal Development"
                                        )}
                                        onChange={() =>
                                          handleCheckboxChange(
                                            "customized",
                                            "B2C Web Portal Development"
                                          )
                                        }
                                      />
                                      <label htmlFor="b2cWebPortalDevelopment">
                                        B2C WEB PORTAL DEVELOPMENT
                                      </label>
                                      <br />
                                    </div>
                                  </div>
                                </fieldset>
                              </div>
                            </div>
                          )}

                          {activeTab === "digital" && (
                            <div className="col-md-4 project-item-box digital">
                              <div
                                className="works-item"
                                style={{ width: "auto", height: "auto" }}
                              >
                                <fieldset>
                                  <input
                                    type="checkbox"
                                    id="digitalMarketingStrategy"
                                    value="Digital Marketing Strategy"
                                    checked={checkedItems.digital.includes(
                                      "Digital Marketing Strategy"
                                    )}
                                    onChange={() =>
                                      handleCheckboxChange(
                                        "digital",
                                        "Digital Marketing Strategy"
                                      )
                                    }
                                  />
                                  <label htmlFor="digitalMarketingStrategy">
                                    DIGITAL MARKETING STRATEGY
                                  </label>
                                  <br />
                                  <input
                                    type="checkbox"
                                    id="searchEngineOptimization"
                                    value="Search Engine Optimization (SEO)"
                                    checked={checkedItems.digital.includes(
                                      "Search Engine Optimization (SEO)"
                                    )}
                                    onChange={() =>
                                      handleCheckboxChange(
                                        "digital",
                                        "Search Engine Optimization (SEO)"
                                      )
                                    }
                                  />
                                  <label htmlFor="searchEngineOptimization">
                                    SEARCH ENGINE OPTIMIZATION (SEO)
                                  </label>
                                  <br />
                                  <input
                                    type="checkbox"
                                    id="contentMarketing"
                                    value="Content Marketing"
                                    checked={checkedItems.digital.includes(
                                      "Content Marketing"
                                    )}
                                    onChange={() =>
                                      handleCheckboxChange(
                                        "digital",
                                        "Content Marketing"
                                      )
                                    }
                                  />
                                  <label htmlFor="contentMarketing">
                                    CONTENT MARKETING
                                  </label>
                                  <br />
                                  <input
                                    type="checkbox"
                                    id="socialMediaMarketing"
                                    value="Social Media Marketing"
                                    checked={checkedItems.digital.includes(
                                      "Social Media Marketing"
                                    )}
                                    onChange={() =>
                                      handleCheckboxChange(
                                        "digital",
                                        "Social Media Marketing"
                                      )
                                    }
                                  />
                                  <label htmlFor="socialMediaMarketing">
                                    SOCIAL MEDIA MARKETING
                                  </label>
                                  <br />
                                </fieldset>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>

                      <br />
                      <div className="form-group col-md-6 mb-4">
                        <input
                          type="text"
                          name="fname"
                          className="form-control"
                          id="fname"
                          value={projectName}
                          placeholder="Project Name (optional)"
                          required=""
                          onChange={(e) => setProjectName(e.target.value)}
                        />
                        <div className="help-block with-errors" />
                      </div>
                      <div className="form-group col-md-6 mb-4">
                        <input
                          type="text"
                          name="Budget"
                          className="form-control"
                          value={budget}
                          id="Budget"
                          onChange={(e) => setBudget(e.target.value)}
                          placeholder="Budget in $ (optional)"
                          required=""
                        />
                        <div className="help-block with-errors" />
                      </div>

                      <div className="form-group col-md-4 mb-4">
                        <input
                          type="date"
                          name="fname"
                          className="form-control"
                          value={date}
                          placeholder="Launch Date (optional)"
                          required=""
                          onChange={(e) => setDate(e.target.value)}
                        />
                        <div className="help-block with-errors" />
                      </div>
                      <div className="form-group col-md-4 mb-4">
                        <input
                          type="text"
                          name="Audience"
                          className="form-control"
                          id="Audience"
                          value={audience}
                          placeholder="Target Audience (optional)"
                          required=""
                          onChange={(e) => setAudience(e.target.value)}
                        />
                        <div className="help-block with-errors" />
                      </div>

                      <div className="form-group col-md-4 mb-4">
                        <select
                          name="tCountry"
                          onChange={(e) => setTargetCountry(e.target.value)}
                          className="form-control target"
                        >
                          <option>Target Country (optional)</option>

                          <option data-countrycode="GB" value="UK">
                            UK
                          </option>
                          <option data-countrycode="US" value="USA">
                            USA
                          </option>
                          <option data-countrycode="DZ" value="Algeria">
                            Algeria
                          </option>
                          <option data-countrycode="AD" value="Andorra">
                            Andorra
                          </option>
                          <option data-countrycode="AO" value="Angola">
                            Angola
                          </option>
                          <option data-countrycode="AI" value="Anguilla">
                            Anguilla
                          </option>
                          <option
                            data-countrycode="AG"
                            value="Antigua & Barbuda"
                          >
                            Antigua &amp; Barbuda
                          </option>
                          <option data-countrycode="AR" value="Argentina">
                            Argentina
                          </option>
                          <option data-countrycode="AM" value="Armenia">
                            Armenia
                          </option>
                          <option data-countrycode="AW" value="Aruba">
                            Aruba
                          </option>
                          <option data-countrycode="AU" value="Australia">
                            Australia
                          </option>
                          <option data-countrycode="AT" value="Austria">
                            Austria
                          </option>
                          <option data-countrycode="AZ" value="Azerbaijan">
                            Azerbaijan
                          </option>
                          <option data-countrycode="BS" value="Bahamas">
                            Bahamas
                          </option>
                          <option data-countrycode="BH" value="Bahrain">
                            Bahrain
                          </option>
                          <option data-countrycode="BD" value="Bangladesh">
                            Bangladesh
                          </option>
                          <option data-countrycode="BB" value="Barbados">
                            Barbados
                          </option>
                          <option data-countrycode="BY" value="Belarus">
                            Belarus
                          </option>
                          <option data-countrycode="BE" value="Belgium">
                            Belgium
                          </option>
                          <option data-countrycode="BZ" value="Belize">
                            Belize
                          </option>
                          <option data-countrycode="BJ" value="Benin">
                            Benin
                          </option>
                          <option data-countrycode="BM" value="Bermuda">
                            Bermuda
                          </option>
                          <option data-countrycode="BT" value="Bhutan">
                            Bhutan
                          </option>
                          <option data-countrycode="BO" value="Bolivia">
                            Bolivia
                          </option>
                          <option
                            data-countrycode="BA"
                            value="Bosnia Herzegovina"
                          >
                            Bosnia Herzegovina
                          </option>
                          <option data-countrycode="BW" value="Botswana">
                            Botswana
                          </option>
                          <option data-countrycode="BR" value="Brazil">
                            Brazil
                          </option>
                          <option data-countrycode="BN" value="Brunei">
                            Brunei
                          </option>
                          <option data-countrycode="BG" value="Bulgaria">
                            Bulgaria
                          </option>
                          <option data-countrycode="BF" value="Burkina Faso">
                            Burkina Faso
                          </option>
                          <option data-countrycode="BI" value="Burundi">
                            Burundi
                          </option>
                          <option data-countrycode="KH" value="Cambodia">
                            Cambodia
                          </option>
                          <option data-countrycode="CM" value="Cameroon">
                            Cameroon
                          </option>
                          <option data-countrycode="CA" value="Canada">
                            Canada
                          </option>
                          <option
                            data-countrycode="CV"
                            value="Cape Verde Islands"
                          >
                            Cape Verde Islands
                          </option>
                          <option data-countrycode="KY" value="Cayman Islands">
                            Cayman Islands
                          </option>
                          <option
                            data-countrycode="CF"
                            value="Central African Republic"
                          >
                            Central African Republic
                          </option>
                          <option data-countrycode="CL" value="Chile">
                            Chile
                          </option>
                          <option data-countrycode="CN" value="China">
                            China
                          </option>
                          <option data-countrycode="CO" value="Colombia">
                            Colombia
                          </option>
                          <option data-countrycode="KM" value="Comoros">
                            Comoros
                          </option>
                          <option data-countrycode="CG" value="Congo">
                            Congo
                          </option>
                          <option data-countrycode="CK" value="Cook Islands">
                            Cook Islands
                          </option>
                          <option data-countrycode="CR" value="Costa Rica">
                            Costa Rica
                          </option>
                          <option data-countrycode="HR" value="Croatia">
                            Croatia
                          </option>
                          <option data-countrycode="CU" value="Cuba">
                            Cuba
                          </option>
                          <option data-countrycode="CY" value="Cyprus North">
                            Cyprus North
                          </option>

                          <option data-countrycode="CY" value="Cyprus South">
                            Cyprus South
                          </option>
                          <option data-countrycode="CZ" value="Czech Republic">
                            Czech Republic
                          </option>
                          <option data-countrycode="DK" value="Denmark">
                            Denmark
                          </option>
                          <option data-countrycode="DJ" value="Djibouti">
                            Djibouti
                          </option>
                          <option data-countrycode="DM" value="Dominica">
                            Dominica
                          </option>
                          <option
                            data-countrycode="DO"
                            value="Dominican Republic"
                          >
                            Dominican Republic
                          </option>
                          <option data-countrycode="EC" value="Ecuador">
                            Ecuador
                          </option>
                          <option data-countrycode="EG" value="Egypt">
                            Egypt
                          </option>
                          <option data-countrycode="SV" value="El Salvador">
                            El Salvador
                          </option>
                          <option
                            data-countrycode="GQ"
                            value="Equatorial Guinea"
                          >
                            Equatorial Guinea
                          </option>
                          <option data-countrycode="ER" value="Eritrea">
                            Eritrea
                          </option>
                          <option data-countrycode="EE" value="Estonia">
                            Estonia
                          </option>
                          <option data-countrycode="ET" value="Ethiopia">
                            Ethiopia
                          </option>
                          <option
                            data-countrycode="FK"
                            value="Falkland Islands"
                          >
                            Falkland Islands
                          </option>
                          <option data-countrycode="FO" value="Faroe Islands">
                            Faroe Islands
                          </option>
                          <option data-countrycode="FJ" value="Fiji">
                            Fiji
                          </option>
                          <option data-countrycode="FI" value="Finland">
                            Finland
                          </option>
                          <option data-countrycode="FR" value="France">
                            France
                          </option>
                          <option data-countrycode="GF" value="French Guiana">
                            French Guiana
                          </option>
                          <option
                            data-countrycode="PF"
                            value="French Polynesia"
                          >
                            French Polynesia
                          </option>
                          <option data-countrycode="GA" value="Gabon">
                            Gabon
                          </option>
                          <option data-countrycode="GM" value="Gambia">
                            Gambia
                          </option>
                          <option data-countrycode="GE" value="Georgia">
                            Georgia
                          </option>
                          <option data-countrycode="DE" value="Germany">
                            Germany
                          </option>
                          <option data-countrycode="GH" value="Ghana">
                            Ghana
                          </option>
                          <option data-countrycode="GI" value="Gibraltar">
                            Gibraltar
                          </option>
                          <option data-countrycode="GR" value="Greece">
                            Greece
                          </option>
                          <option data-countrycode="GL" value="Greenland">
                            Greenland
                          </option>
                          <option data-countrycode="GD" value="Grenada">
                            Grenada
                          </option>
                          <option data-countrycode="GP" value="Guadeloupe">
                            Guadeloupe
                          </option>
                          <option data-countrycode="GU" value="Guam">
                            Guam
                          </option>
                          <option data-countrycode="GT" value="Guatemala">
                            Guatemala
                          </option>
                          <option data-countrycode="GN" value="Guinea">
                            Guinea
                          </option>
                          <option data-countrycode="GW" value="Guinea-Bissau">
                            Guinea-Bissau
                          </option>
                          <option data-countrycode="GY" value="Guyana">
                            Guyana
                          </option>
                          <option data-countrycode="HT" value="Haiti">
                            Haiti
                          </option>
                          <option data-countrycode="HN" value="Honduras">
                            Honduras
                          </option>
                          <option data-countrycode="HK" value="Hong Kong">
                            Hong Kong
                          </option>
                          <option data-countrycode="HU" value="Hungary">
                            Hungary
                          </option>
                          <option data-countrycode="IS" value="Iceland">
                            Iceland
                          </option>
                          <option data-countrycode="IN" value="India">
                            India
                          </option>
                          <option data-countrycode="ID" value="Indonesia">
                            Indonesia
                          </option>
                          <option data-countrycode="IR" value="Iran">
                            Iran
                          </option>
                          <option data-countrycode="IQ" value="Iraq">
                            Iraq
                          </option>
                          <option data-countrycode="IE" value="Ireland">
                            Ireland
                          </option>
                          <option data-countrycode="IL" value="Israel">
                            Israel
                          </option>
                          <option data-countrycode="IT" value="Italy">
                            Italy
                          </option>
                          <option data-countrycode="JM" value="Jamaica">
                            Jamaica
                          </option>
                          <option data-countrycode="JP" value="Japan">
                            Japan
                          </option>
                          <option data-countrycode="JO" value="Jordan">
                            Jordan
                          </option>
                          <option data-countrycode="KZ" value="Kazakhstan">
                            Kazakhstan
                          </option>
                          <option data-countrycode="KE" value="Kenya">
                            Kenya
                          </option>
                          <option data-countrycode="KI" value="Kiribati">
                            Kiribati
                          </option>
                          <option data-countrycode="KP" value="Korea North">
                            Korea North
                          </option>
                          <option data-countrycode="KR" value="Korea South">
                            Korea South
                          </option>
                          <option data-countrycode="KW" value="Kuwait">
                            Kuwait
                          </option>
                          <option data-countrycode="KG" value="Kyrgyzstan">
                            Kyrgyzstan
                          </option>
                          <option data-countrycode="LA" value="Laos">
                            Laos
                          </option>
                          <option data-countrycode="LV" value="Latvia">
                            Latvia
                          </option>
                          <option data-countrycode="LB" value="Lebanon">
                            Lebanon
                          </option>
                          <option data-countrycode="LS" value="Lesotho">
                            Lesotho
                          </option>
                          <option data-countrycode="LR" value="Liberia">
                            Liberia
                          </option>
                          <option data-countrycode="LY" value="Libya">
                            Libya
                          </option>
                          <option data-countrycode="LI" value="Liechtenstein">
                            Liechtenstein
                          </option>
                          <option data-countrycode="LT" value="Lithuania">
                            Lithuania
                          </option>
                          <option data-countrycode="LU" value="Luxembourg">
                            Luxembourg
                          </option>
                          <option data-countrycode="MO" value="Macao">
                            Macao
                          </option>
                          <option data-countrycode="MK" value="Macedonia">
                            Macedonia
                          </option>
                          <option data-countrycode="MG" value="Madagascar">
                            Madagascar
                          </option>
                          <option data-countrycode="MW" value="Malawi">
                            Malawi
                          </option>
                          <option data-countrycode="MY" value="Malaysia">
                            Malaysia
                          </option>
                          <option data-countrycode="MV" value="Maldives">
                            Maldives
                          </option>
                          <option data-countrycode="ML" value="Mali">
                            Mali
                          </option>
                          <option data-countrycode="MT" value="Malta">
                            Malta
                          </option>
                          <option
                            data-countrycode="MH"
                            value="Marshall Islands"
                          >
                            Marshall Islands
                          </option>
                          <option data-countrycode="MQ" value="Martinique">
                            Martinique
                          </option>
                          <option data-countrycode="MR" value="Mauritania">
                            Mauritania
                          </option>
                          <option data-countrycode="YT" value="Mayotte">
                            Mayotte
                          </option>
                          <option data-countrycode="MX" value="Mexico">
                            Mexico
                          </option>
                          <option data-countrycode="FM" value="Micronesia">
                            Micronesia
                          </option>
                          <option data-countrycode="MD" value="Moldova">
                            Moldova
                          </option>
                          <option data-countrycode="MC" value="Monaco">
                            Monaco
                          </option>
                          <option data-countrycode="MN" value="Mongolia">
                            Mongolia
                          </option>
                          <option data-countrycode="MS" value="Montserrat">
                            Montserrat
                          </option>
                          <option data-countrycode="MA" value="Morocco">
                            Morocco
                          </option>
                          <option data-countrycode="MZ" value="Mozambique">
                            Mozambique
                          </option>
                          <option data-countrycode="MN" value="Myanmar">
                            Myanmar
                          </option>
                          <option data-countrycode="NA" value="Namibia">
                            Namibia
                          </option>
                          <option data-countrycode="NR" value="Nauru">
                            Nauru
                          </option>
                          <option data-countrycode="NP" value="Nepal">
                            Nepal
                          </option>
                          <option data-countrycode="NL" value="Netherlands">
                            Netherlands
                          </option>
                          <option data-countrycode="NC" value="New Caledonia">
                            New Caledonia
                          </option>
                          <option data-countrycode="NZ" value="New Zealand">
                            New Zealand
                          </option>
                          <option data-countrycode="NI" value="Nicaragua">
                            Nicaragua
                          </option>
                          <option data-countrycode="NE" value="Niger">
                            Niger
                          </option>
                          <option data-countrycode="NG" value="Nigeria">
                            Nigeria
                          </option>
                          <option data-countrycode="NU" value="Niue">
                            Niue
                          </option>
                          <option data-countrycode="NF" value="Norfolk Islands">
                            Norfolk Islands
                          </option>
                          <option
                            data-countrycode="NP"
                            value="Northern Marianas"
                          >
                            Northern Marianas
                          </option>
                          <option data-countrycode="NO" value="Norway">
                            Norway
                          </option>
                          <option data-countrycode="OM" value="Oman">
                            Oman
                          </option>
                          <option data-countrycode="PW" value="Palau">
                            Palau
                          </option>
                          <option data-countrycode="PA" value="Panama">
                            Panama
                          </option>
                          <option
                            data-countrycode="PG"
                            value="Papua New Guinea"
                          >
                            Papua New Guinea
                          </option>
                          <option data-countrycode="PY" value="Paraguay">
                            Paraguay
                          </option>
                          <option data-countrycode="PE" value="Peru">
                            Peru
                          </option>
                          <option data-countrycode="PH" value="Philippines">
                            Philippines
                          </option>
                          <option data-countrycode="PL" value="Poland">
                            Poland
                          </option>
                          <option data-countrycode="PT" value="Portugal">
                            Portugal
                          </option>
                          <option data-countrycode="PR" value="Puerto Rico">
                            Puerto Rico
                          </option>
                          <option data-countrycode="QA" value="Qatar">
                            Qatar
                          </option>
                          <option data-countrycode="RE" value="Reunion">
                            Reunion
                          </option>
                          <option data-countrycode="RO" value="Romania">
                            Romania
                          </option>
                          <option data-countrycode="RU" value="Russia">
                            Russia
                          </option>
                          <option data-countrycode="RW" value="Rwanda">
                            Rwanda
                          </option>
                          <option data-countrycode="SM" value="San Marino">
                            San Marino
                          </option>
                          <option
                            data-countrycode="ST"
                            value="Sao Tome &amp; Principe"
                          >
                            Sao Tome & Principe
                          </option>
                          <option data-countrycode="SA" value="Saudi Arabia">
                            Saudi Arabia
                          </option>
                          <option data-countrycode="SN" value="Senegal">
                            Senegal
                          </option>
                          <option data-countrycode="CS" value="Serbia">
                            Serbia
                          </option>
                          <option data-countrycode="SC" value="Seychelles">
                            Seychelles
                          </option>
                          <option data-countrycode="SL" value="Sierra Leone">
                            Sierra Leone
                          </option>
                          <option data-countrycode="SG" value="Singapore">
                            Singapore
                          </option>
                          <option data-countrycode="SK" value="Slovak Republic">
                            Slovak Republic
                          </option>
                          <option data-countrycode="SI" value="Slovenia">
                            Slovenia
                          </option>
                          <option data-countrycode="SB" value="Solomon Islands">
                            Solomon Islands
                          </option>
                          <option data-countrycode="SO" value="Somalia">
                            Somalia
                          </option>
                          <option data-countrycode="ZA" value="South Africa">
                            South Africa
                          </option>
                          <option data-countrycode="ES" value="Spain">
                            Spain
                          </option>
                          <option data-countrycode="LK" value="Sri Lanka">
                            Sri Lanka
                          </option>
                          <option data-countrycode="SH" value="St. Helena">
                            St. Helena
                          </option>
                          <option data-countrycode="KN" value="St. Kitts">
                            St. Kitts
                          </option>
                          <option data-countrycode="SC" value="St. Lucia">
                            St. Lucia
                          </option>
                          <option data-countrycode="SD" value="Sudan">
                            Sudan
                          </option>
                          <option data-countrycode="SR" value="Suriname">
                            Suriname
                          </option>
                          <option data-countrycode="SZ" value="Swaziland">
                            Swaziland
                          </option>
                          <option data-countrycode="SE" value="Sweden">
                            Sweden
                          </option>
                          <option data-countrycode="CH" value="Switzerland">
                            Switzerland
                          </option>
                          <option data-countrycode="SI" value="Syria">
                            Syria
                          </option>
                          <option data-countrycode="TW" value="Taiwan">
                            Taiwan
                          </option>
                          <option data-countrycode="TJ" value="Tajikstan">
                            Tajikstan
                          </option>
                          <option data-countrycode="TH" value="Thailand">
                            Thailand
                          </option>
                          <option data-countrycode="TG" value="Togo">
                            Togo
                          </option>
                          <option data-countrycode="TO" value="Tonga">
                            Tonga
                          </option>
                          <option
                            data-countrycode="TT"
                            value="Trinidad &amp; Tobago"
                          >
                            Trinidad & Tobago
                          </option>
                          <option data-countrycode="TN" value="Tunisia">
                            Tunisia
                          </option>
                          <option data-countrycode="TR" value="Turkey">
                            Turkey
                          </option>
                          <option data-countrycode="TM" value="Turkmenistan">
                            Turkmenistan
                          </option>
                          <option
                            data-countrycode="TC"
                            value="Turks &amp; Caicos Islands"
                          >
                            Turks & Caicos Islands
                          </option>
                          <option data-countrycode="TV" value="Tuvalu">
                            Tuvalu
                          </option>
                          <option data-countrycode="UG" value="Uganda">
                            Uganda
                          </option>
                          <option data-countrycode="UA" value="Ukraine">
                            Ukraine
                          </option>
                          <option
                            data-countrycode="AE"
                            value="United Arab Emirates"
                          >
                            United Arab Emirates
                          </option>
                          <option data-countrycode="UY" value="Uruguay">
                            Uruguay
                          </option>
                          <option data-countrycode="UZ" value="Uzbekistan">
                            Uzbekistan
                          </option>
                          <option data-countrycode="VU" value="Vanuatu">
                            Vanuatu
                          </option>
                          <option data-countrycode="VA" value="Vatican City">
                            Vatican City
                          </option>
                          <option data-countrycode="VE" value="Venezuela">
                            Venezuela
                          </option>
                          <option data-countrycode="VN" value="Vietnam">
                            Vietnam
                          </option>
                          <option
                            data-countrycode="VG"
                            value="Virgin Islands - British"
                          >
                            Virgin Islands - British
                          </option>
                          <option
                            data-countrycode="VI"
                            value="Virgin Islands - US"
                          >
                            Virgin Islands - US
                          </option>
                          <option
                            data-countrycode="WF"
                            value="Wallis &amp; Futuna"
                          >
                            Wallis & Futuna
                          </option>
                          <option data-countrycode="YE" value="Yemen (North)">
                            Yemen (North)
                          </option>
                          <option data-countrycode="YE" value="Yemen (South)">
                            Yemen (South)
                          </option>
                          <option data-countrycode="ZM" value="Zambia">
                            Zambia
                          </option>
                          <option data-countrycode="ZW" value="Zimbabwe">
                            Zimbabwe
                          </option>
                        </select>
                        <div className="help-block with-errors" />
                      </div>

                      <div className="form-group col-md-12 mb-4">
                        <textarea
                          name="msg"
                          className="form-control"
                          id="msg"
                          rows={7}
                          placeholder="Project Scope, Description & Reference (optional)"
                          value={projectScope}
                          onChange={(e) => setProjectScope(e.target.value)}
                        />
                        <div className="help-block with-errors" />
                      </div>

                      <div className="form-group col-md-12 mb-4">
                        <input
                          type="file"
                          name="file"
                          className="form-control"
                          placeholder="Any attachment (optional)"
                          onChange={handleFileChange}
                        />
                        <div className="text-danger">{errors.file}</div>{" "}
                      </div>

                      <div className="form-group col-md-12 mb-4">
                        <ReCAPTCHA
                          sitekey="6LfqGJ8gAAAAAOGYrU5mnv6cZxePqeJ_u85A3t5L"
                          onChange={handleRecaptchaChange}
                        />
                        <div className="help-block with-errors" />
                      </div>

                      <p
                        className="form-message"
                        style={{
                          color: messageType === "success" ? "green" : "red",
                        }}
                      >
                        {message}
                      </p>
                      <div className="col-md-12">
                        <button type="submit" className="btn-default">
                          Submit
                        </button>
                        <div id="msgSubmit" className="h3 text-left hidden" />
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Requirement;
