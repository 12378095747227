import React, { useState } from "react";
import "./my.css";
import { Link } from "react-router-dom";

import { Helmet } from "react-helmet";

const Services = () => {
  const [activeTab, setActiveTab] = useState("website");

  // Function to handle tab click
  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  return (
    <div>
      <Helmet>
        <title>Our Services | Comprehensive IT Solutions - Guru Software</title>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, shrink-to-fit=no"
        />
        <meta name="author" content="Guru Software" />
        <meta name="subject" content="IT Services" />
        <meta name="owner" content="Guru Software" />
        <meta name="coverage" content="Global" />
        <meta
          name="Geography"
          content="Guru Software, Ahmedabad, Gujarat, India"
        />
        <meta name="Language" content="English" />
        <meta name="distribution" content="Global" />
        <meta name="audience" content="All" />
        <meta name="revisit-after" content="3 days" />
        <meta name="Robots" content="INDEX,FOLLOW" />
        <meta name="country" content="Global" />
        <meta
          name="description"
          content="Explore Guru Software’s diverse range of IT services, including software development, digital marketing, and more. Discover how our solutions can elevate your business."
        />
        <meta
          property="og:title"
          content="Our Services | Comprehensive IT Solutions - Guru Software"
        />
        <meta
          property="og:description"
          content="Explore Guru Software’s diverse range of IT services, including software development, digital marketing, and more. Discover how our solutions can elevate your business."
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:url"
          content="https://www.gurusoftware.in/Services"
        />
        <meta
          property="og:image"
          content="https://www.gurusoftware.in/images/og.png"
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:description"
          content="Explore Guru Software’s diverse range of IT services, including software development, digital marketing, and more. Discover how our solutions can elevate your business."
        />
        <meta
          name="twitter:title"
          content="Our Services | Comprehensive IT Solutions - Guru Software"
        />
      </Helmet>

      <div>
        {/* Page Header Start */}
        <div className="page-header" style={{ padding: "50px 0" }}>
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                {/* Page Header Box Start */}
                <div className="page-header-box-my page-header-box">
                  <h1 className="text-anime-style-3">Services</h1>
                  <nav className="wow fadeInUp" data-wow-delay="0.25s">
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item">
                        <Link to="/">home</Link>
                      </li>
                      <li
                        className="breadcrumb-item active"
                        aria-current="page"
                      >
                        Services
                      </li>
                    </ol>
                  </nav>
                </div>
                {/* Page Header Box End */}
              </div>
            </div>
          </div>
        </div>
        {/* Page Header End */}

        {/* Projects Page Start */}
        <div className="our-projects">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-md-12">
                {/* Sidebar Our Projects Nav start */}
                <div className="our-projects-nav wow fadeInUp serv">
                  <ul className="list-unstyled">
                    {/* Using row and col classes for proper grid layout */}
                    <div className="row ">
                      {/* Mobile View: Two columns */}
                      <div className="col-6 d-md-none">
                        <Link
                          to="#"
                          className={
                            activeTab === "website" ? "active-btn" : ""
                          }
                          onClick={() => handleTabClick("website")}
                          data-filter=".website"
                        >
                          {" "}
                          <li
                            className={activeTab === "website" ? "active" : ""}
                            style={{ fontSize: "12px", height: "44px "}}

                          >
                            Website development
                          </li>
                        </Link>
                        <Link
                          to="#"
                          className={activeTab === "mobile" ? "active-btn" : ""}
                          onClick={() => handleTabClick("mobile")}
                          data-filter=".mobile"
                        >
                          <li
                            className={activeTab === "mobile" ? "active" : ""}
                            style={{ fontSize: "12px", height: "44px "}}

                          >
                            Mobile app development
                          </li>
                        </Link>
                        </div>
                        <div className="col-6 d-md-none">
                        <Link
                          to="#"
                          className={
                            activeTab === "customized" ? "active-btn" : ""
                          }
                          onClick={() => handleTabClick("customized")}
                          data-filter=".customized"
                        >
                          {" "}
                          <li
                            className={
                              activeTab === "customized" ? "active" : ""

                            }
                            style={{ fontSize: "12px", height: "44px "}}

                          >
                            Customized Software
                          </li>
                        </Link>

                        <Link
                          to="#"
                          className={
                            activeTab === "digital" ? "active-btn" : ""
                          }
                          onClick={() => handleTabClick("digital")}
                          data-filter=".digital"
                        >
                          <li
                            className={activeTab === "digital" ? "active" : ""}
                            style={{ fontSize: "12px", height: "44px "}}

                          >
                            Digital marketing{" "}
                          </li>
                        </Link>
                        </div>
                    




                      <div className="col-lg-12 col-md-6 d-none d-md-block">
                        <Link
                          to="#"
                          className={
                            activeTab === "website" ? "active-btn" : ""
                          }
                          onClick={() => handleTabClick("website")}
                          data-filter=".website"
                        >
                          <li
                            className={activeTab === "website" ? "active" : ""}
                          >
                            Website  development
                          </li>
                        </Link>
                        <Link
                          to="#"
                          className={activeTab === "mobile" ? "active-btn" : ""}
                          onClick={() => handleTabClick("mobile")}
                          data-filter=".mobile"
                        >
                          <li
                            className={activeTab === "mobile" ? "active" : ""}
                          >
                            Mobile app development
                          </li>
                        </Link>

                        <Link
                          to="#"
                          className={
                            activeTab === "customized" ? "active-btn" : ""
                          }
                          onClick={() => handleTabClick("customized")}
                          data-filter=".customized"
                        >
                          <li
                            className={
                              activeTab === "customized" ? "active" : ""
                            }
                          >
                            Customized Software
                          </li>
                        </Link>

                        <Link
                          to="#"
                          className={
                            activeTab === "digital" ? "active-btn" : ""
                          }
                          onClick={() => handleTabClick("digital")}
                          data-filter=".digital"
                        >
                          <li
                            className={activeTab === "digital" ? "active" : ""}
                          >
                            Digital marketing
                          </li>
                        </Link>
                      </div>
                    </div>
                  </ul>
                </div>
                <br></br>
              </div>
              <div className="">
                <div className="col-lg-12 col-md-12">
                  <div className="  ">
                    {activeTab === "website" && (
                      <div className="row ">
                        <div className="col-md-4 project-item-box  ">
                          {/* Works Item Start */}
                          <div className="works-item myitem rowse">
                            <Link to={"/Website_Design"}>
                              <div className="works-image">
                                <figure
                                  className="image-anime"
                                  style={{ textAlign: "center" }}
                                >
                                  <img
                                    src="images/diagram/web_design.webp"
                                    alt=""
                                  />
                                </figure>
                              </div>
                              <div className="works-content">
                                <h2>WEBSITE DESIGN</h2>
                                <p style={{ color: "#9C9FA6" }}>
                                  Our expert designers create visually stunning
                                  websites tailored to your brand, ensuring a
                                  captivating online presence.
                                </p>
                              </div>
                            </Link>
                          </div>
                          {/* Works Item End */}
                        </div>
                        <div className="col-md-4 project-item-box  ">
                          {/* Works Item Start */}
                          <div className="works-item myitem">
                            <Link to={"/Website_Development"}>
                              <div className="works-image">
                                <figure
                                  className="image-anime"
                                  style={{ textAlign: "center" }}
                                >
                                  <img
                                    src="images/diagram/website_development.webp"
                                    alt=""
                                  />
                                </figure>
                              </div>
                              <div className="works-content">
                                <h2>WEBSITE DEVELOPMENT</h2>
                                <p style={{ color: "#9C9FA6" }}>
                                  We bring your web vision to life with
                                  responsive and functional websites that drive
                                  engagement and conversions.
                                </p>
                              </div>
                            </Link>
                          </div>
                          {/* Works Item End */}
                        </div>
                      </div>
                    )}

                    {activeTab === "mobile" && (
                      <div className="row">
                        <div className="col-md-4 project-item-box mobile">
                          {/* Works Item Start */}
                          <div className="works-item myitem">
                            <Link to={"/Mobile_App_Development"}>
                              <div className="works-image">
                                <figure
                                  className="image-anime"
                                  style={{ textAlign: "center" }}
                                >
                                  <img
                                    src="images/diagram/mobile.webp"
                                    salt=""
                                  />
                                </figure>
                              </div>
                              <div className="works-content">
                                <h2>MOBILE APP DEVELOPMENT</h2>
                                <p style={{ color: "#9C9FA6" }}>
                                  We craft customized mobile applications that
                                  engage users and enhance your brand's mobile
                                  presence.
                                </p>
                              </div>
                            </Link>
                          </div>
                          {/* Works Item End */}
                        </div>

                        <div className="col-md-4 project-item-box mobile">
                          {/* Works Item Start */}
                          <div className="works-item myitem">
                            <Link to={"/Hybrid_App_Development"}>
                              <div className="works-image">
                                <figure
                                  className="image-anime"
                                  style={{ textAlign: "center" }}
                                >
                                  <img
                                    src="images/diagram/hybrid.webp"
                                    salt=""
                                  />
                                </figure>
                              </div>
                              <div className="works-content">
                                <h2>HYBRID APP DEVELOPMENT</h2>
                                <p>
                                  Harnessing the power of cross-platform
                                  technology, we create versatile apps that work
                                  seamlessly on various devices.
                                </p>
                              </div>
                            </Link>
                          </div>
                          {/* Works Item End */}
                        </div>

                        <div className="col-md-4 project-item-box mobile">
                          {/* Works Item Start */}
                          <div className="works-item myitem">
                            <Link to={"/Native_App_Development"}>
                              <div className="works-image">
                                <figure
                                  className="image-anime"
                                  style={{ textAlign: "center" }}
                                >
                                  <img
                                    src="images/diagram/Developer%20activity-pana2.webp"
                                    salt=""
                                  />
                                </figure>
                              </div>
                              <div className="works-content">
                                <h2>NATIVE APP DEVELOPMENT</h2>
                                <p>
                                  We specialize in building native apps for iOS
                                  and Android, delivering high performance and a
                                  seamless user experience.
                                </p>
                              </div>
                            </Link>
                          </div>
                          {/* Works Item End */}
                        </div>
                      </div>
                    )}

                    {activeTab === "customized" && (
                      <div className="row">
                        <div className="col-md-4 project-item-box ">
                          {/* Works Item Start */}
                          <div className="works-item myitem">
                            <Link to={"/CRM_Development"}>
                              <div className="works-image">
                                <figure
                                  className="image-anime"
                                  style={{ textAlign: "center" }}
                                >
                                  <img src="images/diagram/crm.png" alt="" />
                                </figure>
                              </div>
                              <div className="works-content">
                                <h2>CRM SOFTWARE DEVELOPMENT</h2>
                                <p>
                                  Streamline customer relationships and boost
                                  productivity with our tailored CRM solutions.
                                </p>
                              </div>
                            </Link>
                          </div>
                          {/* Works Item End */}
                        </div>
                        <div className="col-md-4 project-item-box ">
                          {/* Works Item Start */}
                          <div className="works-item myitem">
                            <Link to={"/Software_Development"}>
                              <div className="works-image">
                                <figure
                                  className="image-anime"
                                  style={{ textAlign: "center" }}
                                >
                                  <img
                                    src="images/diagram/software_development.webp"
                                    alt=""
                                  />
                                </figure>
                              </div>
                              <div className="works-content">
                                <h2>SOFTWARE DEVELOPMENT</h2>
                                <p>
                                  From concept to deployment, we deliver
                                  scalable and innovative software solutions for
                                  your unique needs.
                                </p>
                              </div>
                            </Link>
                          </div>
                          {/* Works Item End */}
                        </div>
                        <div className="col-md-4 project-item-box ">
                          {/* Works Item Start */}
                          <div className="works-item myitem">
                            <Link to={"/Education_Web_Portal_Development"}>
                              <div className="works-image">
                                <figure
                                  className="image-anime"
                                  style={{ textAlign: "center" }}
                                >
                                  <img
                                    src="images/diagram/edu_web_portal.webp"
                                    alt=""
                                  />
                                </figure>
                              </div>
                              <div className="works-content">
                                <h2>EDUCATIONAL WEB PORTAL DEVELOPMENT</h2>
                                <p>
                                  Transform education with our feature-rich web
                                  portals, facilitating interactive learning
                                  experiences.
                                </p>
                              </div>
                            </Link>
                          </div>
                          {/* Works Item End */}
                        </div>
                        <div className="col-md-4 project-item-box ">
                          {/* Works Item Start */}
                          <div className="works-item myitem">
                            <Link to={"/Restaurant_Web_Portal_Development"}>
                              <div className="works-image">
                                <figure
                                  className="image-anime"
                                  style={{ textAlign: "center" }}
                                >
                                  <img
                                    src="images/diagram/restaurant.webp"
                                    alt=""
                                  />
                                </figure>
                              </div>
                              <div className="works-content">
                                <h2>RESTAURANT WEB PORTAL DEVELOPMENT</h2>
                                <p>
                                  Elevate your restaurant's online presence with
                                  user-friendly web portals for menus,
                                  reservations, and more.
                                </p>
                              </div>
                            </Link>
                          </div>
                          {/* Works Item End */}
                        </div>
                        <div className="col-md-4 project-item-box ">
                          {/* Works Item Start */}
                          <div className="works-item myitem">
                            <Link to={"/Health_Care_Portal_Development"}>
                              <div className="works-image">
                                <figure
                                  className="image-anime"
                                  style={{ textAlign: "center" }}
                                >
                                  <img
                                    src="images/diagram/helth.png"
                                    alt=""
                                  />
                                </figure>
                              </div>
                              <div className="works-content">
                                <h2>HEALTH CARE PORTAL DEVELOPMENT</h2>
                                <p>
                                  Enhance patient care and management through
                                  secure and user-friendly healthcare web
                                  portals.
                                </p>
                              </div>
                            </Link>
                          </div>
                          {/* Works Item End */}
                        </div>
                        <div className="col-md-4 project-item-box ">
                          {/* Works Item Start */}
                          <div className="works-item myitem">
                            <Link to={"/Travel_Portal_Development"}>
                              <div className="works-image">
                                <figure
                                  className="image-anime"
                                  style={{ textAlign: "center" }}
                                >
                                  <img
                                    src="images/diagram/travel_web.webp"
                                    alt=""
                                  />
                                </figure>
                              </div>
                              <div className="works-content">
                                <h2>TRAVEL PORTAL DEVELOPMENT</h2>
                                <p>
                                  We create comprehensive travel portals,
                                  offering seamless booking and travel
                                  information services.
                                </p>
                              </div>
                            </Link>
                          </div>
                          {/* Works Item End */}
                        </div>
                        <div className="col-md-4 project-item-box ">
                          {/* Works Item Start */}
                          <div className="works-item myitem">
                            <Link to={"/Real_Estate_Portal_Development"}>
                              <div className="works-image">
                                <figure
                                  className="image-anime"
                                  style={{ textAlign: "center" }}
                                >
                                  <img
                                    src="images/diagram/real-estate.webp"
                                    alt=""
                                  />
                                </figure>
                              </div>
                              <div className="works-content">
                                <h2>REAL ESATE PORTAL DEVELOPMENT</h2>
                                <p>
                                  Attract property buyers and sellers with
                                  intuitive real estate portals showcasing
                                  listings and amenities.
                                </p>
                              </div>
                            </Link>
                          </div>
                          {/* Works Item End */}
                        </div>
                        <div className="col-md-4 project-item-box ">
                          {/* Works Item Start */}
                          <div className="works-item myitem">
                            <Link to={"/Ecommerce_Portal_Development"}>
                              <div className="works-image">
                                <figure
                                  className="image-anime"
                                  style={{ textAlign: "center" }}
                                >
                                  <img
                                    src="images/diagram/e-commerce.webp"
                                    alt=""
                                  />
                                </figure>
                              </div>
                              <div className="works-content">
                                <h2>E-COMMERCE WEBSITE DEVELOPMENT</h2>
                                <p>
                                  Boost sales and customer satisfaction with our
                                  e-commerce websites, designed for seamless
                                  shopping experiences.
                                </p>
                              </div>
                            </Link>
                          </div>
                          {/* Works Item End */}
                        </div>
                      </div>
                    )}

                    {activeTab === "digital" && (
                      <div className="row">
                        <div className="col-md-4 project-item-box ">
                          {/* Works Item Start */}
                          <div className="works-item myitem">
                            <Link to={"/Search_Engine_Optimization"}>
                              <div className="works-image">
                                <figure
                                  className="image-anime"
                                  style={{ textAlign: "center" }}
                                >
                                  <img src="images/diagram/seo.webp" alt="" />
                                </figure>
                              </div>
                              <div className="works-content">
                                <h2>SEO SERVICES</h2>
                                <p>
                                  Elevate your online visibility and organic
                                  traffic with our expert SEO services, tailored
                                  to boost your website's search engine rankings
                                  and drive sustainable growth.
                                </p>
                              </div>
                            </Link>
                          </div>
                        </div>
                        <div className="col-md-4 project-item-box ">
                          {/* Works Item Start */}
                          <div className="works-item myitem">
                            <Link to={"/Digital_Marketing"}>
                              <div className="works-image">
                                <figure
                                  className="image-anime"
                                  style={{ textAlign: "center" }}
                                >
                                  <img
                                    src="images/diagram/digital_marketing.webp"
                                    alt=""
                                  />
                                </figure>
                              </div>
                              <div className="works-content">
                                <h2>DIGITAL MARKETING</h2>
                                <p>
                                  Navigate the digital landscape with
                                  confidence. Our comprehensive digital
                                  marketing strategies harness the power of
                                  online platforms to connect, engage, and
                                  convert your target audience.
                                </p>
                              </div>
                            </Link>
                          </div>
                        </div>
                        <div className="col-md-4 project-item-box ">
                          {/* Works Item Start */}
                          <div className="works-item myitem">
                            <Link to={"/Brand_Consulting"}>
                              <div className="works-image">
                                <figure
                                  className="image-anime"
                                  style={{ textAlign: "center" }}
                                >
                                  <img
                                    src="images/diagram/brand_consultant.webp"
                                    alt=""
                                  />
                                </figure>
                              </div>
                              <div className="works-content">
                                <h2>BRAND CONSULTING</h2>
                                <p>
                                  Uncover your brand's true potential with our
                                  brand consulting services. We provide expert
                                  guidance to define, refine, and amplify your
                                  brand identity, ensuring a lasting impression.
                                </p>
                              </div>
                            </Link>
                          </div>
                        </div>
                        <div className="col-md-4 project-item-box ">
                          {/* Works Item Start */}
                          <div className="works-item myitem">
                            <Link to={"/Search_Engine_Marketing"}>
                              <div className="works-image">
                                <figure
                                  className="image-anime"
                                  style={{ textAlign: "center" }}
                                >
                                  <img
                                    src="images/diagram/search_marketing.webp"
                                    alt=""
                                  />
                                </figure>
                              </div>
                              <div className="works-content">
                                <h2>SEARCH ENGINE MARKETING</h2>
                                <p>
                                  Maximize your online reach and ROI through
                                  effective search engine marketing. Our
                                  strategies combine paid advertising and
                                  organic efforts to dominate search engine
                                  results.
                                </p>
                              </div>
                            </Link>
                          </div>
                        </div>
                        <div className="col-md-4 project-item-box ">
                          {/* Works Item Start */}
                          <div className="works-item myitem">
                            <Link to={"/Social_Media_Marketing"}>
                              <div className="works-image">
                                <figure
                                  className="image-anime"
                                  style={{ textAlign: "center" }}
                                >
                                  <img
                                    src="images/diagram/social_media.webp"
                                    alt=""
                                  />
                                </figure>
                              </div>
                              <div className="works-content">
                                <h2>SOCIAL MEDIA MARKETING</h2>
                                <p>
                                  Harness the influence of social media. Our
                                  social media marketing campaigns are designed
                                  to engage your audience, increase brand
                                  awareness, and drive conversions.
                                </p>
                              </div>
                            </Link>
                          </div>
                        </div>
                        <div className="col-md-4 project-item-box ">
                          {/* Works Item Start */}
                          <div className="works-item myitem">
                            <Link to={"/Pay_Per_Click_Management"}>
                              <div className="works-image">
                                <figure
                                  className="image-anime"
                                  style={{ textAlign: "center" }}
                                >
                                  <img src="images/diagram/pay.webp" alt="" />
                                </figure>
                              </div>
                              <div className="works-content">
                                <h2>PAY PER CLICK MANAGEMENT</h2>
                                <p>
                                  Get immediate results with precision. Our PPC
                                  management services optimize your ad spend,
                                  delivering targeted traffic and measurable
                                  returns on investment.
                                </p>
                              </div>
                            </Link>
                          </div>
                        </div>
                        <div className="col-md-4 project-item-box ">
                          {/* Works Item Start */}
                          <div className="works-item myitem">
                            <Link to={"/Seo_Copywrite"}>
                              <div className="works-image">
                                <figure
                                  className="image-anime"
                                  style={{ textAlign: "center" }}
                                >
                                  <img
                                    src="images/diagram/seo_copy.webp"
                                    alt=""
                                  />
                                </figure>
                              </div>
                              <div className="works-content">
                                <h2>SEO COPYWRITING</h2>
                                <p>
                                  Craft compelling content that ranks and
                                  resonates. Our team creates high-quality
                                  content to enhance your website's performance
                                  in search engines.
                                </p>
                              </div>
                            </Link>
                          </div>
                        </div>
                        <div className="col-md-4 project-item-box ">
                          {/* Works Item Start */}
                          <div className="works-item myitem">
                            <Link to={"/Ui_Ux_Design"}>
                              <div className="works-image">
                                <figure
                                  className="image-anime"
                                  style={{ textAlign: "center" }}
                                >
                                  <img src="images/diagram/ux.webp" alt="" />
                                </figure>
                              </div>
                              <div className="works-content">
                                <h2>UI/UX DESIGN</h2>
                                <p>
                                  Elevate user experiences with intuitive and
                                  visually appealing UI/UX design.We blend
                                  creativity and usability to create digital
                                  interfaces that captivate and convert.
                                </p>
                              </div>
                            </Link>
                          </div>
                        </div>
                        <div className="col-md-4 project-item-box ">
                          {/* Works Item Start */}
                          <div className="works-item myitem">
                            <Link to={"/Logo_Design"}>
                              <div className="works-image">
                                <figure
                                  className="image-anime"
                                  style={{ textAlign: "center" }}
                                >
                                  <img src="images/diagram/logo.webp" alt="" />
                                </figure>
                              </div>
                              <div className="works-content">
                                <h2>LOGO DESIGN</h2>
                                <p>
                                  Make a memorable first impression with a
                                  unique logo. Our logo design services are
                                  tailored to reflect your brand's identity and
                                  leave a lasting mark.
                                </p>
                              </div>
                            </Link>
                          </div>
                        </div>
                        <div className="col-md-4 project-item-box ">
                          {/* Works Item Start */}
                          <div className="works-item myitem">
                            <Link to={"/Broucher_Design"}>
                              <div className="works-image">
                                <figure
                                  className="image-anime"
                                  style={{ textAlign: "center" }}
                                >
                                  <img src="images/diagram/brouc.webp" alt="" />
                                </figure>
                              </div>
                              <div className="works-content">
                                <h2>BROCHURE MARKETING</h2>
                                <p>
                                  Showcase your products or services in style.
                                  Our brochure marketing solutions combine
                                  compelling design and persuasive content to
                                  leave a lasting impact on your audience.
                                </p>
                              </div>
                            </Link>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Services;
