import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import ReCAPTCHA from 'react-google-recaptcha';

const Test = () => {
  // State declarations
  const [firstName, setFirstName] = useState('');
  const [email, setEmail] = useState('');
  const [country, setCountry] = useState('');
  const [countryCode, setCountryCode] = useState('');
  const [contactNumber, setContactNumber] = useState('');
  const [business, setBusiness] = useState('');
  const [checkedItems, setCheckedItems] = useState([]);
  const [activeTab, setActiveTab] = useState('website');
  const [projectName, setProjectName] = useState('');
  const [budget, setBudget] = useState('');
  const [date, setDate] = useState('');
  const [audience, setAudience] = useState('');
  const [file, setFile] = useState(null);
  const [projectScope, setProjectScope] = useState('');
  const [targetCountry, setTargetCountry] = useState('');
  const [recaptchaValue, setRecaptchaValue] = useState(null);

  useEffect(() => {
    console.log('Current values:', {
      firstName,
      email,
      country,
      countryCode,
      contactNumber,
      business, 
      checkedItems,
      projectName,
      budget,
      date,
      audience,
      targetCountry,
      projectScope,
      recaptchaValue
    });
  }, [firstName, email, country, countryCode, contactNumber, business, checkedItems, projectName, budget, date, audience, targetCountry, projectScope, recaptchaValue]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (!recaptchaValue) {
        alert('Please complete the reCAPTCHA');
        return;
      }

      const formData = new FormData();
      formData.append('firstName', firstName);
      formData.append('email', email);
      formData.append('country', country);
      formData.append('countryCode', countryCode);
      formData.append('contactNumber', contactNumber);
      formData.append('business', business);
      formData.append('checkedItems', JSON.stringify(checkedItems));
      formData.append('projectName', projectName);
      formData.append('budget', budget);
      formData.append('date', date);
      formData.append('audience', audience);
      formData.append('targetCountry', targetCountry);
      formData.append('projectScope', projectScope);
      formData.append('recaptchaResponse', recaptchaValue);
      
      if (file) {
        formData.append('file', file);
      }

      const response = await axios.post('https://www.hum.ujn.mybluehostin.me/gurusoftwareapi/upload.php', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });

      console.log('Response:', response);

      if (response.data && response.data.success) {
        console.log('Data submitted successfully:', response.data);
        // Reset form fields
        setFirstName('');
        setEmail('');
        setCountry('');
        setCountryCode('');
        setContactNumber('');
        setBusiness('');
        setCheckedItems([]);
        setProjectName('');
        setBudget('');
        setDate('');
        setAudience('');
        setTargetCountry('');
        setProjectScope('');
        setFile(null);
        setRecaptchaValue(null);
      } else {
        console.error("Submission failed:", response.data ? response.data.message : 'No error message returned');
        alert(`Submission failed: ${response.data ? response.data.message : 'Unknown error'}`);
      }
    } catch (error) {
      console.error("Error submitting data:", error.response ? error.response.data : error.message);
      alert(`Error submitting data: ${error.response ? error.response.data.message : error.message}`);
    }
  };

  const handleCheckboxChange = (e) => {
    const { value } = e.target;
    setCheckedItems((prev) =>
      prev.includes(value) ? prev.filter((item) => item !== value) : [...prev, value]
    );
  };

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    const allowedTypes = ['image/jpeg', 'image/png', 'application/pdf']; 

    if (selectedFile) {
      if (allowedTypes.includes(selectedFile.type)) {
        setFile(selectedFile);
      } else {
        alert('Invalid file type. Please select a valid file.');
      }
    }
  };

  const handleRecaptchaChange = (value) => {
    setRecaptchaValue(value);
  };

  return (
    <div>
      <div className="contact-us">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-12">
              <div className="contact-form-box wow fadeInUp" data-wow-delay="0.5s">
                <h2 className="text-anime-style-3" style={{ perspective: 400, width: "100%", textAlign: "center" }}>
                  We Would Like To Hear From You Anytime
                </h2>
                <br /> <br />
                <div className="contact-form">
                <form onSubmit={handleSubmit}>
                <div className="row">
                      <div className="form-group col-md-6 mb-4">
                        <input
                          type="text"
                          name="fname"
                          className="form-control"
                          id="fname"
                          value={firstName}
                          onChange={(e) => setFirstName(e.target.value)}
                          placeholder="Name/Company Name"
                          required
                        />
                        <div className="help-block with-errors" />
                      </div>
                      <div className="form-group col-md-6 mb-4">
                        <input
                          type="email"
                          name="Email"
                          className="form-control"
                          id="Email"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          placeholder="Email"
                          required
                        />
                        <div className="help-block with-errors" />
                      </div>


               
              



                      <div className="form-group col-md-6 mb-4">
                        <select
                          name="country"
                          id="Choose"
                          className="form-control country"
                          value={country}
                          onChange={(e) => setCountry(e.target.value)}
                          required
                        >
                          <option value="">Select your Country</option>
                          <option value="UK">UK</option>
                          <option value="USA">USA</option>
                          <option value="Algeria">Algeria</option>
                          <option value="Andorra">Andorra</option>
                          <option value="Angola">Angola</option>
                        </select>
                        <div className="help-block with-errors" />
                      </div>
                      

                      
     
                      <div className="form-group col-md-3 mb-4">
                        <input
                          type="text"
                          name="CountryCode"
                          className="form-control"
                          value={countryCode}
                          onChange={(e) => setCountryCode(e.target.value)}
                          placeholder="Country Code"
                          required
                        />
                        <div className="help-block with-errors" />
                      </div>
                      <div className="form-group col-md-3 mb-4">
                        <input
                          type="text"
                          name="ContactNumber"
                          className="form-control"
                          value={contactNumber}
                          onChange={(e) => setContactNumber(e.target.value)}
                          placeholder="Contact Number"
                          required
                        />
                        <div className="help-block with-errors" />
                      </div>

                      <div className="form-group col-md-12 mb-4">
                        <select
                          name="bType"
                          id=""
                          className="form-control myDropDown"
                          onChange={(e) => setBusiness(e.target.value)}
                        >
                                              <option>Type Of Business (optional)</option>

                          <option value="Art & Entertainment">Art & Entertainment</option>
                          <option value="Autos & Vehicles">Autos & Vehicles</option>
                          <option value="Beauty & Fitness">Beauty & Fitness</option>
                          <option value="Books & Literature">Books & Literature</option>
                          <option value="Business & Industrial">Business & Industrial</option>
                          <option value="Computer & Electronics">Computer & Electronics</option>
                          <option value="Finance">Finance</option>
                          <option value="Food & Drink">Food & Drink</option>
                          <option value="Games">Games</option>
                          <option value="Health">Health</option>
                          <option value="Hobbies & Leisure">Hobbies & Leisure</option>
                          <option value="Home & Garden">Home & Garden</option>
                          <option value="Internet & Telecom">Internet & Telecom</option>
                          <option value="Jobs & Education">Jobs & Education</option>
                          <option value="Law & Government">Law & Government</option>
                          <option value="News">News</option>
                          <option value="Marketing & Advertisement">Marketing & Advertisement</option>
                          <option value="Online Communities">Online Communities</option>
                          <option value="People & Society">People & Society</option>
                          <option value="Pets & Animals">Pets & Animals</option>
                          <option value="Real Estate">Real Estate</option>
                          <option value="Science & Technology">Science & Technology</option>
                          <option value="Shopping">Shopping</option>
                          <option value="Sports">Sports</option>
                          <option value="Travels">Travels</option>
                          <option value="Other">Other</option>
                        </select>
                        <div className="help-block with-errors" />
                      </div>  <div className="col-lg-12 col-md-12">
  <h5 className="text-anime-style-3" style={{ paddingLeft: '10px' }}>
    Services Interested In
  </h5>
  <br />
  <div className="our-projects-nav wow fadeInUp serv" data-wow-delay="0.25s">
    <ul className="list-unstyled">
      <div className="row">
        <div className="col-12 d-md-none">
          <li className={activeTab === 'website' ? 'active' : ''}>
            <Link
              to="#"
              className={activeTab === 'website' ? 'active-btn' : ''}
              onClick={() => handleTabClick('website')}
              data-filter=".website"
            >
              Website development
            </Link>
          </li>
          <li className={activeTab === 'mobile' ? 'active' : ''}>
            <Link
              to="#"
              className={activeTab === 'mobile' ? 'active-btn' : ''}
              onClick={() => handleTabClick('mobile')}
              data-filter=".mobile"
            >
              Mobile app development
            </Link>
          </li>
          <li className={activeTab === 'customized' ? 'active' : ''}>
            <Link
              to="#"
              className={activeTab === 'customized' ? 'active-btn' : ''}
              onClick={() => handleTabClick('customized')}
              data-filter=".customized"
            >
              Customized Software
            </Link>
          </li>
          <li className={activeTab === 'digital' ? 'active' : ''}>
            <Link
              to="#"
              className={activeTab === 'digital' ? 'active-btn' : ''}
              onClick={() => handleTabClick('digital')}
              data-filter=".digital"
            >
              Digital marketing
            </Link>
          </li>
        </div>
        <div className="col-lg-12 d-none d-md-block">
          <li className={activeTab === 'website' ? 'active' : ''}>
            <Link
              to="#"
              className={activeTab === 'website' ? 'active-btn' : ''}
              onClick={() => handleTabClick('website')}
              data-filter=".website"
            >
              Website development
            </Link>
          </li>
          <li className={activeTab === 'mobile' ? 'active' : ''}>
            <Link
              to="#"
              className={activeTab === 'mobile' ? 'active-btn' : ''}
              onClick={() => handleTabClick('mobile')}
              data-filter=".mobile"
            >
              Mobile app development
            </Link>
          </li>
          <li className={activeTab === 'customized' ? 'active' : ''}>
            <Link
              to="#"
              className={activeTab === 'customized' ? 'active-btn' : ''}
              onClick={() => handleTabClick('customized')}
              data-filter=".customized"
            >
              Customized Software
            </Link>
          </li>
          <li className={activeTab === 'digital' ? 'active' : ''}>
            <Link
              to="#"
              className={activeTab === 'digital' ? 'active-btn' : ''}
              onClick={() => handleTabClick('digital')}
              data-filter=".digital"
            >
              Digital marketing
            </Link>
          </li>
        </div>
      </div>
    </ul>
  </div>
</div>

<div className="col-lg-12 col-md-12">
  <div className="row project-item-boxes" style={{ height: 'auto' }}>
    {activeTab === 'website' && (
      <div className="col-md-12 col-lg-4 project-item-box website">
        <div className="works-item" style={{ width: '100%', height: 'auto' }}>
          <fieldset>
            <input
              type="checkbox"
              id="digitalMarketing"
              name="website"
              value="Website Design"
              onChange={handleCheckboxChange}
            />
            <label htmlFor="digitalMarketing">WEBSITE DESIGN</label>
            <br />
            <input
              type="checkbox"
              id="websiteDesign"
              name="website"
              value="Website Development"
              onChange={handleCheckboxChange}
            />
            <label htmlFor="websiteDesign">WEBSITE DEVELOPMENT</label>
            <br />
          </fieldset>
        </div>
      </div>
    )}
    {activeTab === 'mobile' && (
      <div className="col-md-12 col-lg-4 project-item-box mobile">
        <div className="works-item" style={{ width: '100%', height: 'auto' }}>
          <fieldset>
            <input
              type="checkbox"
              id="mobileApp"
              name="mobile"
              value="Mobile App Development"
              onChange={handleCheckboxChange}
            />
            <label htmlFor="mobileApp">MOBILE APP DEVELOPMENT</label>
            <br />
            <input
              type="checkbox"
              id="hybridApp"
              name="mobile"
              value="Hybrid App Development"
              onChange={handleCheckboxChange}
            />
            <label htmlFor="hybridApp">HYBRID APP DEVELOPMENT</label>
            <br />
            <input
              type="checkbox"
              id="nativeApp"
              name="mobile"
              value="Native App Development"
              onChange={handleCheckboxChange}
            />
            <label htmlFor="nativeApp">NATIVE APP DEVELOPMENT</label>
            <br />
          </fieldset>
        </div>
      </div>
    )}
    {activeTab === 'customized' && (
      <div className="col-md-12 project-item-box customized">
        <div className="works-item" style={{ width: '100%', height: 'auto' }}>
          <fieldset>
            <div className="row">
              <div className="col-md-4">
                <input
                  type="checkbox"
                  id="crmDevelopment"
                  name="customized"
                  value="CRM Software Development"
                  onChange={handleCheckboxChange}
                />
                <label htmlFor="crmDevelopment">CRM SOFTWARE DEVELOPMENT</label>
                <br />
                <input
                  type="checkbox"
                  id="digitalMarketingCustom"
                  name="customized"
                  value="Digital Marketing"
                  onChange={handleCheckboxChange}
                />
                <label htmlFor="digitalMarketingCustom">DIGITAL MARKETING</label>
                <br />
                <input
                  type="checkbox"
                  id="restaurantPortal"
                  name="customized"
                  value="Restaurant Web Portal Development"
                  onChange={handleCheckboxChange}
                />
                <label htmlFor="restaurantPortal">RESTAURANT WEB PORTAL DEVELOPMENT</label>
                <br />
              </div>
              <div className="col-md-4">
                <input
                  type="checkbox"
                  id="inventorySystem"
                  name="customized"
                  value="Inventory Management System"
                  onChange={handleCheckboxChange}
                />
                <label htmlFor="inventorySystem">INVENTORY MANAGEMENT SYSTEM</label>
                <br />
                <input
                  type="checkbox"
                  id="salesPortal"
                  name="customized"
                  value="Sales Portal Development"
                  onChange={handleCheckboxChange}
                />
                <label htmlFor="salesPortal">SALES PORTAL DEVELOPMENT</label>
                <br />
                <input
                  type="checkbox"
                  id="erpSystem"
                  name="customized"
                  value="ERP System Development"
                  onChange={handleCheckboxChange}
                />
                <label htmlFor="erpSystem">ERP SYSTEM DEVELOPMENT</label>
                <br />
              </div>
              <div className="col-md-4">
                <input
                  type="checkbox"
                  id="customCRM"
                  name="customized"
                  value="Custom CRM"
                  onChange={handleCheckboxChange}
                />
                <label htmlFor="customCRM">CUSTOM CRM</label>
                <br />
                <input
                  type="checkbox"
                  id="otherCustom"
                  name="customized"
                  value="Other Customized Software"
                  onChange={handleCheckboxChange}
                />
                <label htmlFor="otherCustom">OTHER CUSTOMIZED SOFTWARE</label>
                <br />
              </div>
            </div>
          </fieldset>
        </div>
      </div>
    )}
    {activeTab === 'digital' && (
      <div className="col-md-12 project-item-box digital">
        <div className="works-item" style={{ width: '100%', height: 'auto' }}>
          <fieldset>
            <input
              type="checkbox"
              id="seo"
              name="digital"
              value="SEO Services"
              onChange={handleCheckboxChange}
            />
            <label htmlFor="seo">SEO SERVICES</label>
            <br />
            <input
              type="checkbox"
              id="contentMarketing"
              name="digital"
              value="Content Marketing"
              onChange={handleCheckboxChange}
            />
            <label htmlFor="contentMarketing">CONTENT MARKETING</label>
            <br />
            <input
              type="checkbox"
              id="socialMedia"
              name="digital"
              value="Social Media Management"
              onChange={handleCheckboxChange}
            />
            <label htmlFor="socialMedia">SOCIAL MEDIA MANAGEMENT</label>
            <br />
          </fieldset>
        </div>
      </div>
    )}
  </div>
</div>






<br />
                      <br />
                      <br />
                      <br />
                      <br />
                 <div className="form-group col-md-6 mb-4">
                  <input
                    type="text"
                    name="fname"
                    className="form-control"
                    id="fname"
                    placeholder="Project Name (optional)"
                    required=""
                    onChange={(e) => setProjectName(e.target.value)}

                  />
                  <div className="help-block with-errors" />
                </div>
                <div className="form-group col-md-6 mb-4">
                  <input
                    type="text"
                    name="Budget"
                    className="form-control"
                    id="Budget"
                    onChange={(e) => setBudget(e.target.value)}

                    placeholder="Budget in $ (optional)"
                    required=""
                  />
                  <div className="help-block with-errors" />
                </div>


                <div className="form-group col-md-4 mb-4">
                  <input
                    type="date"
                    name="fname"
                    className="form-control"
                    // id="fname"
                    placeholder="Launch Date (optional)"
                    required=""
                    onChange={(e) => setDate(e.target.value)}


                  />
                  <div className="help-block with-errors" />
                </div>
                <div className="form-group col-md-4 mb-4">
                  <input
                    type="text"
                    name="Audience"
                    className="form-control"
                    id="Audience"
                    placeholder="Target Audience (optional)"
                    required=""
                    onChange={(e) => setAudience(e.target.value)}

                  />
                  <div className="help-block with-errors" />
                </div>


 
                <div className="form-group col-md-4 mb-4">
                  <select name="tCountry"                
                    onChange={(e) => setTargetCountry(e.target.value)}
 className="form-control target">
                    <option>Target Country (optional)</option>
                    <option data-countrycode="MV" value="Maldives">Maldives</option>
  <option data-countrycode="ML" value="Mali">Mali</option>
  <option data-countrycode="MT" value="Malta">Malta</option>
  <option data-countrycode="MH" value="Marshall Islands">Marshall Islands</option>
  <option data-countrycode="MQ" value="Martinique">Martinique</option>
  <option data-countrycode="MR" value="Mauritania">Mauritania</option>

                  
                  </select>
                  <div className="help-block with-errors" />
                </div>



                <div className="form-group col-md-12 mb-4">
        <textarea
          name="msg"
          className="form-control"
          id="msg"
          rows={7}
          placeholder="Project Scope, Description & Reference (optional)"
          value={projectScope}
          onChange={(e) => setProjectScope(e.target.value)}
        />
        <div className="help-block with-errors" />
      </div>

      <div className="form-group col-md-12 mb-4">
        <input
          type="file"
          name="file"
          className="form-control"
          id="file"
          placeholder="Any attachment (optional)"
          onChange={handleFileChange}
        />
        <div className="help-block with-errors" />
      </div>

      <div className="form-group col-md-12 mb-4">
                        <ReCAPTCHA
                          sitekey="6LfqGJ8gAAAAAOGYrU5mnv6cZxePqeJ_u85A3t5L"
                          onChange={handleRecaptchaChange}
                        />
                      </div>


      <div className="col-md-12">
        <button type="submit" 
        className="btn-default"
        >
          Submit
        </button>
        <div id="msgSubmit" className="h3 text-left hidden" />
      </div>
                    </div>
                  </form>
                </div>
                {/* Contact Form End */}

               
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Test;



