

import React from 'react'
import { Link } from 'react-router-dom'; 


const Portfolio_Single = () => {
  return (
    <div>
      <>
      <div className="page-header page-header-my  " style={{padding: '50px 0 '}}>
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                {/* Page Header Box Start */}
                <div className="page-header-box-my page-header-box">
                  <h1 className="text-anime-style-3">Project Details</h1>
                  <nav className="wow fadeInUp" data-wow-delay="0.25s">
                    <ol className="breadcrumb">
                      <li className="breadcrumb-item"><Link to="/">home</Link></li>
                      <li className="breadcrumb-item active" aria-current="page">Project Details</li>
                    </ol>
                  </nav>
                </div>
                {/* Page Header Box End */}
              </div>
            </div>
          </div>
        </div>
  <div className="page-service-single">
    <div className="container">
      <div className="row">
        <div className="col-lg-8">
          {/* Service Content Start */}
          <div className="service-single-content">
            {/* Service Featured Image Start */}
            <div className="service-featured-image" style={{ display: 'flex', justifyContent: 'center' }}>
  <img
    src="images/remo1.webp"
    style={{ height: 500, width: 'auto' }}
    alt=""
  />
</div>

          
            <div className="service-entry">
              <p className="wow fadeInUp" data-wow-delay="0.25s">
                Guru Software embarks on a transformative journey with a project
                designed to redefine elegance and excellence in the jewelry
                industry. Anchored in Nikol, Ahmedabad, Gujarat, the project
                introduces Manglik Gold, a distinguished jewelry store crafted
                to perfection by Guru Software. With a meticulous blend of
                tradition and innovation, Manglik Gold promises an unparalleled
                experience, offering a diverse collection of timeless treasures
                meticulously curated to reflect the unique style and grace of
                its clientele.
              </p>
              <p className="wow fadeInUp">
                Nestled within Nikol, Manglik Gold's showroom emerges as a
                captivating haven where luxury converges with affordability.
                Guru Software's expertise shines through in every facet of the
                project, from the intricate craftsmanship of each piece to the
                seamless fusion of technology and artistry. With a steadfast
                commitment to customer satisfaction, Manglik Gold, brought to
                life by Guru Software, aspires to forge enduring relationships
                with its patrons, cementing its status as the go-to destination
                for all jewelry needs.
              </p>
            </div>
          </div>
        </div>
        <div className="col-lg-4">
          <div className="service-sidebar">
            <div className="services-list-box wow fadeInUp">
              <div className="icon-box">
                <img src="images/icon-service-list.webp" alt="" />
              </div>
              <h3>Project Details</h3>
              <ul>
                <li>React.js Frontend Development</li>
                <li>Node.js Backend Development</li>
                <li>Project Duration: 1 month</li>
                <li>WordPress Support &amp; Maintenance</li>
                <li>Content Migration</li>
                <li>Plugin Installation and Customization</li>
                <li>Fast Load Time Optimization</li>
                <li>Easy Back-End Admin Panel Implementation</li>
                <li>Over 1,000 Completed Projects</li>
              </ul>
              <Link 
                to="https://manglikgold.com/"
                target="_blank"
                className="btn-default"
              >
                View Project
              </Link>
              <Link  to="/Requirement" className="btn-default">
                Enquiry
              </Link>
            </div>
            {/* Service List Box End */}
          </div>
        </div>
      </div>
    </div>
  </div>
  {/* Single Service Page End */}
</>

    </div>
  )
}

export default Portfolio_Single
