import Footer from "./pages/Footer";
import Header from "./pages/Header";
import Home from "./pages/Home";
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'; // 
import Services from "./pages/Services";
import About from "./pages/About";
import Contact from "./pages/Contact";
import Website_Design from "./pages/Website_Design";
import Website_Development from "./pages/Website_Development";
import Mobile_App_Development from "./pages/Mobile_App_Development";
import Hybrid_App_Development from "./pages/Hybrid_App_Development";
import Native_App_Development from "./pages/Native_App_Development";
import CRM_Development from "./pages/CRM_Development";
import Software_Development from "./pages/Software_Development";
import Education_Web_Portal_Development from "./pages/Education_Web_Portal_Development";
import Restaurant_Web_Portal_Development from "./pages/Restaurant_Web_Portal_Development";
import Health_Care_Portal_Development from "./pages/Health_Care_Portal_Development";
import Travel_Portal_Development from "./pages/Travel_Portal_Development";
import Real_Estate_Portal_Development from "./pages/Real_Estate_Portal_Development";
import Ecommerce_Portal_Development from "./pages/Ecommerce_Portal_Development";
import Search_Engine_Optimization from "./pages/Search_Engine_Optimization";
import Digital_Marketing from "./pages/Digital_Marketing";
import Brand_Consulting from "./pages/Brand_Consulting";
import Search_Engine_Marketing from "./pages/Search_Engine_Marketing";
import Social_Media_Marketing from "./pages/Social_Media_Marketing";
import Pay_Per_Click_Management from "./pages/Pay_Per_Click_Management";
import Seo_Copywrite from "./pages/Seo_Copywrite";
import Ui_Ux_Design from "./pages/Ui_Ux_Design";
import Logo_Design from "./pages/Logo_Design";
import Broucher_Design from "./pages/Broucher_Design";
import Jewelery_Software from "./pages/Jewelery_Software";
import Hr_And_Pay_Roll_Management_Software from "./pages/Hr_And_Pay_Roll_Management_Software";
import Inventory_Management_Software from "./pages/Inventory_Management_Software";
import CRM_Software from "./pages/CRM_Software";
import Hospital_Management_Software from "./pages/Hospital_Management_Software";
import School_Management_Software from "./pages/School_Management_Software";
import Portfolio from "./pages/Portfolio";
import Requirement from "./pages/Requirement";
import ScrollToTop from "./pages/ScrollToTop";
import Portfolio_Single from "./pages/Portfolio_Single";
import Test from "./pages/Test";
import Test1 from "./pages/Test1";
import Blog from "./pages/Blog";
import The_Importance_Of_Cybersecurity_In_Modern_Software_Development from "./pages/The_Importance_Of_Cybersecurity_In_Modern_Software_Development";
import Embracing_Agile_Accelerating_Software_Development_And_Delivery from "./pages/Embracing_Agile_Accelerating_Software_Development_And_Delivery";
import The_Power_Of_AI_And_Machine_Learning_in_Software_Applications from "./pages/The_Power_Of_AI_And_Machine_Learning_in_Software_Applications";
import Essential_Steps_for_Successful_Website_Audit from "./pages/Essential_Steps_for_Successful_Website_Audit";
import Harnessing_the_Power_of_AI_for_Business_Intelligence from "./pages/Harnessing_the_Power_of_AI_for_Business_Intelligence";
import Role_of_AI_in_Healthcare from "./pages/Role_of_AI_in_Healthcare";
import Leveraging_AI_in_IT_Services from "./pages/Leveraging_AI_in_IT_Services";
import Importance_of_Effective_Website_Development from "./pages/Importance_of_Effective_Website_Development";
import Understanding_Website_Development from "./pages/Understanding_Website_Development";
import The_Evolution_of_Web_Design_Trends_in_2024 from "./pages/The_Evolution_of_Web_Design_Trends_in_2024";
import Social_Media_Marketing_for_Business_Growth from "./pages/Social_Media_Marketing_for_Business_Growth";
import Benefits_of_Ecommerce_for_Businesses from "./pages/Benefits_of_Ecommerce_for_Businesses";
import The_Benefits_of_Customized_Software_for_Businesses from "./pages/The_Benefits_of_Customized_Software_for_Businesses";
import Quantum_Computing_Revolutionizing_Technology_in_2024 from "./pages/Quantum_Computing_Revolutionizing_Technology_in_2024";
import Future_of_IT_Jobs from "./pages/Future_of_IT_Jobs";
import The_Impact_of_Blockchain_Technology_on_Financial_Services from "./pages/The_Impact_of_Blockchain_Technology_on_Financial_Services";
import Emerging_Geospatial_Trends_2024 from "./pages/Emerging_Geospatial_Trends_2024";
import Future_of_Mobile_App_Development from "./pages/Future_of_Mobile_App_Development";
import Benefits_of_Hiring_a_Full_Stack_Developer from "./pages/Benefits_of_Hiring_a_Full_Stack_Developer";
import The_Power_of_SEO_Driving_Online_Success from "./pages/The_Power_of_SEO_Driving_Online_Success";
import { HelmetProvider } from 'react-helmet-async';

function App() {
  return (
   <div>
    <HelmetProvider>

    <Router>
        <Header />
        <ScrollToTop></ScrollToTop>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/Services" element={<Services />} />
          <Route exact path="/About_Us" element={<About />} />

          <Route exact path="/Portfolio" element={<Portfolio />} />

          <Route exact path="/Website_Design" element={<Website_Design />} />
          <Route exact path="/Website_Development" element={<Website_Development />} />
          <Route exact path="/Hybrid_App_Development" element={<Hybrid_App_Development />} />
          <Route exact path="/Native_App_Development" element={<Native_App_Development />} />
          <Route exact path="/Mobile_App_Development" element={<Mobile_App_Development />} />
          <Route exact path="/CRM_Development" element={<CRM_Development />} />
          <Route exact path="/Software_Development" element={<Software_Development />} />
          <Route exact path="/Education_Web_Portal_Development" element={<Education_Web_Portal_Development />} />
          <Route exact path="/Restaurant_Web_Portal_Development" element={<Restaurant_Web_Portal_Development />} />
          <Route exact path="/Health_Care_Portal_Development" element={<Health_Care_Portal_Development />} />
          <Route exact path="/Travel_Portal_Development" element={<Travel_Portal_Development />} />
          <Route exact path="/Real_Estate_Portal_Development" element={<Real_Estate_Portal_Development />} />
          <Route exact path="/Ecommerce_Portal_Development" element={<Ecommerce_Portal_Development />} />
          <Route exact path="/Search_Engine_Optimization" element={<Search_Engine_Optimization />} />
          <Route exact path="/Digital_Marketing" element={<Digital_Marketing />} />
          <Route exact path="/Search_Engine_Marketing" element={<Search_Engine_Marketing />} />
          <Route exact path="/Social_Media_Marketing" element={<Social_Media_Marketing />} />
          <Route exact path="/Brand_Consulting" element={<Brand_Consulting />} />
          <Route exact path="/Seo_Copywrite" element={<Seo_Copywrite />} />

          <Route exact path="/Ui_Ux_Design" element={<Ui_Ux_Design />} />
          <Route exact path="/Pay_Per_Click_Management" element={<Pay_Per_Click_Management />} />
          <Route exact path="/Logo_Design" element={<Logo_Design />} />
          <Route exact path="/Broucher_Design" element={<Broucher_Design />} />
          <Route exact path="/Jewelery_Software" element={<Jewelery_Software />} />

          <Route exact path="/Hr_And_Pay_Roll_Management_Software" element={<Hr_And_Pay_Roll_Management_Software />} />

          <Route exact path="/Inventory_Management_Software" element={<Inventory_Management_Software />} />
          <Route exact path="/CRM_Software" element={<CRM_Software />} />
          <Route exact path="/School_Management_Software" element={<School_Management_Software />} />

          <Route exact path="/Hospital_Management_Software" element={<Hospital_Management_Software />} />
          <Route exact path="/Contact_Us" element={<Contact />} />

          <Route exact path="/Requirement" element={<Requirement />} />

          <Route exact path="/Portfolio_Single" element={<Portfolio_Single />} />

          <Route exact path="/Blog" element={<Blog />} />
          <Route exact path="/The_Importance_Of_Cybersecurity_In_Modern_Software_Development" element={<The_Importance_Of_Cybersecurity_In_Modern_Software_Development />} />

          <Route exact path="/Embracing_Agile_Accelerating_Software_Development_And_Delivery" element={<Embracing_Agile_Accelerating_Software_Development_And_Delivery />} />
          <Route exact path="/The_Power_Of_AI_And_Machine_Learning_in_Software_Applications" element={<The_Power_Of_AI_And_Machine_Learning_in_Software_Applications />} />

          <Route exact path="/Essential_Steps_for_Successful_Website_Audit" element={<Essential_Steps_for_Successful_Website_Audit />} />

          <Route exact path="/Harnessing_the_Power_of_AI_for_Business_Intelligence" element={<Harnessing_the_Power_of_AI_for_Business_Intelligence />} />
          <Route exact path="/Role_of_AI_in_Healthcare" element={<Role_of_AI_in_Healthcare />} />
          <Route exact path="/Leveraging_AI_in_IT_Services" element={<Leveraging_AI_in_IT_Services/>} />
          <Route exact path="/Understanding_Website_Development" element={<Understanding_Website_Development />} />
          <Route exact path="/Importance_of_Effective_Website_Development" element={<Importance_of_Effective_Website_Development/>} />
          <Route exact path="/The_Evolution_of_Web_Design_Trends_in_2024" element={<The_Evolution_of_Web_Design_Trends_in_2024 />} />
          <Route exact path="/Social_Media_Marketing_for_Business_Growth" element={<Social_Media_Marketing_for_Business_Growth />} />
          <Route exact path="/Benefits_of_Ecommerce_for_Businesses" element={<Benefits_of_Ecommerce_for_Businesses />} />
          <Route exact path="/The_Benefits_of_Customized_Software_for_Businesses" element={<The_Benefits_of_Customized_Software_for_Businesses />} />
          <Route exact path="/Quantum_Computing_Revolutionizing_Technology_in_2024" element={<Quantum_Computing_Revolutionizing_Technology_in_2024 />} />
          <Route exact path="/Future_of_IT_Jobs" element={<Future_of_IT_Jobs />} />
          <Route exact path="/Emerging_Geospatial_Trends_2024" element={<Emerging_Geospatial_Trends_2024 />} />
          <Route exact path="/Future_of_Mobile_App_Development" element={<Future_of_Mobile_App_Development />} />
          <Route exact path="/Benefits_of_Hiring_a_Full_Stack_Developer" element={<Benefits_of_Hiring_a_Full_Stack_Developer />} />
          <Route exact path="/The_Power_of_SEO_Driving_Online_Success" element={<The_Power_of_SEO_Driving_Online_Success />} />

          <Route exact path="/The_Impact_of_Blockchain_Technology_on_Financial_Services" element={<The_Impact_of_Blockchain_Technology_on_Financial_Services />} />
          <Route exact path="/Test" element={<Test />} />
          <Route exact path="/Test1" element={<Test1 />} />

        </Routes>
        <Footer />
      </Router>
      </HelmetProvider>

   </div>
  );
}

export default App;
